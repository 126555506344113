import { querystring } from '~/helpers/string'

import Api, { IResponsePaginate, Pagination } from '~/services/Api'

import { IClienteDto, IResponseCliente, CustomerFilterType, IResponseBuscaCliente } from './customer.dto'

export type { CustomerFilterType, IClienteDto, IResponseCliente }
export type PaginateCustomersParams = Pagination<IClienteDto> & CustomerFilterType
/**
 * GET /clientes/
 */
export async function paginateCustomers(pagination: PaginateCustomersParams = {}): Promise<IResponsePaginate<IClienteDto>> {
  const paginate = querystring(pagination)
  const query = paginate ? `?${paginate}` : ''
  const paginated = await Api.getDefault<IResponsePaginate<IClienteDto>>(`/clientes${query}`)

  return {
    success: paginated.success,
    ...paginated?.data
  }
}

/**
 * GET /cliente/:customerId
 */
export async function getCustomer(customerId: number): Promise<IResponseCliente> {
  const response = await Api.getDefault<IClienteDto>(`/clientes/${customerId}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

/**
 * - PATCH /cliente/${id}
 * - POST /cliente/
 */
export async function storeCustomer({ id, ...payload }: Partial<IClienteDto>): Promise<IResponseCliente> {
  if (id) {
    const edited = await Api.patchDefault<IClienteDto>(`/clientes/${id}`, payload)
    return {
      success: !!edited?.success,
      customerId: edited?.data?.id,
      data: edited?.data,
      message: edited?.error?.message
    }
  }

  const data = await Api.postDefault<IClienteDto>(`/clientes`, payload)
  return {
    success: !!data?.success,
    customerId: data?.data?.id,
    data: data?.data,
    message: data?.error?.message
  }
}

/**
 * GET /clientes/buscar?nif=12121&search=any
 */
export async function findCustomer(params: CustomerFilterType): Promise<IResponseBuscaCliente> {
  const query = `?${querystring(params)}`
  const response = await Api.getDefault<IClienteDto[]>(`/clientes/busca${query}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}
