import styled from 'styled-components'

export const ShopListContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.l}px;
  outline: none;
`
export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  border-radius: ${({ theme }) => theme.rounded}px;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  border: 0;
  outline: none;
`
