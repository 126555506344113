import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Card, Grid, CardContent, CardHeader } from '@mui/material'

import { FormUserRoles } from '~/components/_features/users/FormUserRoles'
import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { FormUser } from '@feat/users/FormUser'

interface PageUserParams {
  userId?: string
}

export const PageUserStore: React.FC = () => {
  const { userId } = useParams<PageUserParams>()
  const { goBack } = useHistory()
  const id = parseInt(userId, 10) || 0

  const pageTitle = id ? 'Alterar' : 'Incluir'

  const handleFormBack = () => {
    goBack()
  }
  return (
    <PageContainer>
      <PageTitle back spotlight={pageTitle} title="usuário" />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Informações do usuário" />
            <CardContent>
              <FormUser id={id} onBack={handleFormBack} />
            </CardContent>
          </Card>
        </Grid>
        {id ? (
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Permissões do usuário" />
              <CardContent>
                <FormUserRoles id={id} />
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </PageContainer>
  )
}
