import React from 'react'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { PaginateShops } from '@feat/shops/PaginateShops'

export const PageShops: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle spotlight="Lojas" title="cadastradas" />
      <PaginateShops />
    </PageContainer>
  )
}
