import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'

import { ApplicationState } from '~/store'
import { AuthState, fetchAuthUserByToken, loadFailure, setAuth } from '~/store/reducers/auth'

/**
 * Verifica autenticação com auth0.
 * - Deve ser usado apenas no componente `~/components/_layout/MainLayout/Authorized`
 */
export function useLayoutAuthorization() {
  const dispatch = useDispatch()
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  const clearAuth = useCallback(() => {
    dispatch(loadFailure(null))
  }, [dispatch])

  const requestToken = useCallback(async () => {
    const token = await getAccessTokenSilently()
    if (token) {
      dispatch(setAuth({ token }))
      dispatch(fetchAuthUserByToken(token))
    }
  }, [getAccessTokenSilently, dispatch])

  return { isLoading, isAuthenticated, requestToken, clearAuth }
}

/**
 * Obtem informações do usuário autenticado
 */
export function useAppAuth() {
  const dispatch = useDispatch()
  const loading = useSelector<ApplicationState, boolean>(state => !!state?.auth?.loading)
  const token = useSelector<ApplicationState, AuthState['token']>(state => state?.auth?.token)
  const permissions = useSelector<ApplicationState, AuthState['permissions']>(state => state?.auth?.permissions)
  const user = useSelector<ApplicationState, AuthState['user']>(state => state?.auth?.user)
  const error = useSelector<ApplicationState, AuthState['error']>(state => state?.auth?.error)

  const { logout, user: auth0User } = useAuth0()

  const updateAuth = useCallback(
    (data: Partial<AuthState>) => {
      dispatch(setAuth({ ...data }))
    },
    [dispatch]
  )

  const logoutUser = useCallback(
    (options?: LogoutOptions) => {
      dispatch(loadFailure(null))
      // logout({ returnTo: `${window.location.origin}/login` }) // erro no auth0
      logout(options)
    },
    [dispatch, logout]
  )

  const signed = useMemo(() => !!(!loading && token), [loading, token])

  const mergedUserUser = useMemo(() => {
    return { ...user, picture: auth0User?.picture }
  }, [auth0User, user])

  return { signed, logoutUser, updateAuth, loading, token, permissions, user: mergedUserUser, error }
}

/**
 * Selector isolado para checar carregamento de autenticação
 */
export function useAppSigned() {
  const { replace } = useHistory()
  const loading = useSelector<ApplicationState, boolean>(state => !!state?.auth?.loading)
  const token = useSelector<ApplicationState, string>(state => state?.auth?.token)
  const error = useSelector<ApplicationState, AuthState['error']>(state => state?.auth?.error)

  const signed = useMemo(() => {
    return !!(!loading && token)
  }, [loading, token])

  useEffect(() => {
    if (error && error.message) {
      replace('/logout')
    }
  }, [error, replace])

  return { loading, signed, error }
}
