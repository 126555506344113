import React, { useMemo } from 'react'

import { AccountCircle, Logout } from '@mui/icons-material'
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material'

import { useAppAuth } from '~/hooks/useAppAuth'
import { useAppShop } from '~/hooks/useAppShop'

import { ShopItemType, ShopUserItem } from './ShopUserItem'

export const Profile: React.FC = () => {
  const { setShopSelected, currentShop } = useAppShop()
  const { user, logoutUser, signed, permissions } = useAppAuth()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleLogout = () => logoutUser()

  const handleShopClick = () => {
    handleClose()
    setShopSelected(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const shop: ShopItemType = useMemo(() => {
    if (currentShop) {
      const roles = permissions.filter(p => p.shopId === currentShop.id).map(r => r.role)
      return { ...currentShop, roles }
    }
  }, [currentShop, permissions])

  return (
    <>
      <IconButton edge="end" color="inherit" aria-label="open drawer" onClick={handleClick}>
        <AccountCircle />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItem divider dense>
            <ListItemAvatar>
              <Avatar src={user?.picture}>
                <AccountCircle fontSize="small" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user?.name} secondary={user?.email} />
          </ListItem>
          {shop ? <ShopUserItem shop={shop} onClick={handleShopClick} /> : null}
          {signed ? (
            <ListItemButton dense onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Sair do sistema" />
            </ListItemButton>
          ) : null}
        </List>
      </Popover>
    </>
  )
}
