import React from 'react'
import { useParams } from 'react-router-dom'

import { validate } from 'uuid'

interface PageBudgetReportParams {
  budgetId?: string
}

export const PageBudgetReport: React.FC = () => {
  const { budgetId } = useParams<PageBudgetReportParams>()
  const id = validate(budgetId) ? budgetId : parseInt(budgetId, 10) || 0

  return (
    <div style={{ height: '100vh' }}>
      <h2>RELATORIO {id}</h2>
      <br />
    </div>
  )
}
