import React, { useCallback, useState } from 'react'

import { useCustomerFormData, FormDataType, useCustomerAdresses } from '~/hooks/customerHooks'

import { SaveAddressManagerHandler, CallbackAddressParams } from '~/components/AddressManager/Provider'
import { ModalForm } from '~/components/ModalForm'

import { ModalTabs, ModalTabsProps } from './ModalTabs'

export type ModalCustomerProps = ModalTabsProps & {
  customerId?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const ModalCustomer: React.FC<ModalCustomerProps> = ({ defaultTab = 0, type = 'view', customerId, onCancel, onSuccess }) => {
  const { submitData: sendCustomer } = useCustomerFormData()
  const { submitAddressBulk } = useCustomerAdresses({ customerId })
  const [adresses, setAdresses] = useState<CallbackAddressParams>()

  const renderTitle = useCallback(() => {
    if (type === 'view') return 'Visualizar cliente'
    return `${customerId ? 'Alterar' : 'Incluir'} cliente`
  }, [type, customerId])

  const onAddressSave: SaveAddressManagerHandler = useCallback(params => {
    setAdresses(params)
  }, [])

  const onSubmitCustomerData = useCallback(
    async (formData: FormDataType) => {
      const payload = { ...formData, id: customerId }
      const idCliente = await sendCustomer(payload)
      if (idCliente) {
        const resAddr = await submitAddressBulk({ toCreate: adresses?.create, toDelete: adresses?.delete, toEdit: adresses?.edit }, idCliente)
        if (resAddr && onSuccess) onSuccess()
      }
    },
    [sendCustomer, customerId, onSuccess, submitAddressBulk, adresses]
  )

  //
  return (
    <ModalForm key={`${customerId || 0}`} title={renderTitle()}>
      <ModalTabs
        customerId={customerId}
        type={type}
        defaultTab={defaultTab}
        onSubmitCustomer={onSubmitCustomerData}
        onSubmitAdresses={onAddressSave}
        onCancel={onCancel}
      />
    </ModalForm>
  )
}
