import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import Switch from '@mui/material/Switch'
import styled from 'styled-components'

import { storeUser } from '~/services/Api/users'
import { IUsuarioDto } from '~/services/Api/users/user.dto'

import { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 48px;
  border: 0;
`

type Props = ICustomCellProps<IUsuarioDto> & {}

export const ActiveCell: React.FC<Props> = ({ record }) => {
  const refStarted = useRef(0)
  const [checked, setChecked] = useState(!!record?.bloqueado)

  const updateUser = useCallback(
    async (_e: any, bloqueado: boolean) => {
      refStarted.current = 0
      setChecked(bloqueado)
      await storeUser({ id: record.id, bloqueado: !!bloqueado })
    },
    [record]
  )

  useEffect(() => {
    if (!refStarted.current) {
      refStarted.current += 1
    } else if (refStarted.current > 0 && record?.bloqueado !== checked) {
      setChecked(!!record?.bloqueado)
    }
  }, [record, checked])

  return (
    <Content>
      <Switch size="small" color="error" checked={checked} onChange={updateUser} />
    </Content>
  )
}

export const ActiveCellMemo = memo(ActiveCell) as typeof ActiveCell
