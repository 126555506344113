import { querystring } from '~/helpers/string'

import Api, { ApiV1, IResponsePaginate, Pagination } from '~/services/Api'
import { IProdutoDto, IResponseProduct, IResponseSearchProduct, ProductFilterType } from '~/services/Api/products/products.dto'

export type { IProdutoDto, ProductFilterType, IResponseProduct }
/**
 * GET produtos/busca/LU
 */
export async function getProducts(nome: string) {
  const resultTable = (await ApiV1.getDefault(`/produtos/busca/${nome}`)) as any
  return resultTable && resultTable?.data
}

export async function findProduct(search?: string): Promise<IResponseSearchProduct> {
  const response = await Api.getDefault<IProdutoDto[]>(`/produtos/busca/${search}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

export type PaginateProductsParams = Pagination<IProdutoDto> & ProductFilterType
/** GET `/lojas` */
export async function paginateProducts(pagination: PaginateProductsParams = {}): Promise<IResponsePaginate<IProdutoDto>> {
  const paginate = querystring(pagination)
  const query = paginate ? `?${paginate}` : ''
  const paginated = await Api.getDefault<IResponsePaginate<IProdutoDto>>(`/produtos${query}`)

  return {
    success: paginated.success,
    ...paginated?.data
  }
}

/** GET `/produtos/:productId` */
export async function getProduct(productId: number, shopId?: number): Promise<IResponseProduct> {
  const query = shopId ? `?${querystring({ shopId })}` : ''
  const response = await Api.getDefault<IProdutoDto>(`/produtos/${productId}${query}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

/** GET `/produtos/:productId/related` */
export async function getRelatedProducts(productId: number): Promise<IResponseSearchProduct> {
  const response = await Api.getDefault<IProdutoDto[]>(`/produtos/${productId}/related`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}
