import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@mui/material'

import { LoginButton } from '~/components/_auth/LoginButton'
import { LogoutButton } from '~/components/_auth/LogoutButton'
import { SignupButton } from '~/components/_auth/SignupButton'
import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

export const PageSignIn: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  if (isAuthenticated) return <Redirect to={'/'} />

  return (
    <PageContainer>
      <PageTitle spotlight="Login" title="do sistema" center />
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        <SignupButton />
        {isAuthenticated ? <LogoutButton /> : <LoginButton />}
      </Stack>
    </PageContainer>
  )
}
