/**
 * FIXME: Incompleto
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Stack, Button, Grid, Divider, FormControlLabel, Switch } from '@mui/material'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { object, string } from 'yup'

import { validateFormData } from '~/helpers/validation'
import { useIsMounted } from '~/hooks/useIsMounted'

import { getProduct, IProdutoDto } from '~/services/Api/products'

import { CircleLoading } from '~/components/CircleLoading'
import { InputSelect } from '~/components/UnForm/InputSelect'
import { InputText, InputTextProps } from '~/components/UnForm/InputText'

type FormDataType = Partial<IProdutoDto> & {}

type Props = {
  id?: number
  onBack?: () => void
  onCancel?: () => void
  onSuccess?: () => void
  initialData?: FormDataType
}

export const FormProduct: React.FC<Props> = ({ onCancel, onSuccess, onBack, initialData = {}, id }) => {
  const isMounted = useIsMounted()
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState(false)
  const [actived, setActived] = useState(false)
  const [formData, setFormData] = useState<FormDataType>(initialData)

  const fetchData = useCallback(async () => {
    if (id) {
      const response = await getProduct(id)
      if (isMounted.current) {
        setLoading(false)
        if (response.success) {
          setFormData({ ...response.data })
        }
        return !!response?.success
      }
    }
  }, [id, isMounted])

  const handleSubmit = useCallback(
    async data => {
      const invalidData = await validateFormData(productSchema, data, formRef.current)
      if (!invalidData) {
        // const success = await submitData(data)
        if (onSuccess) onSuccess()
      }
    },
    [onSuccess]
  )

  const handleSwitchChange = (_event: any, checked?: boolean) => {
    setActived(!!checked)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const commonProps = useMemo(() => ({ disabled: !!loading, fullWidth: true, variant: 'outlined', autoComplete: 'off' } as InputTextProps), [loading])

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={formData} key={`form-product-${formData.id}`}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputText name="name" type="text" label="Nome do produto" required {...commonProps} />
        </Grid>
        <Grid item xs={6}>
          <InputText name="internalCode" type="text" label="Código interno" {...commonProps} />
        </Grid>
        <Grid item xs={6}>
          <InputText name="externalCode" type="text" label="Código fabricante" {...commonProps} />
        </Grid>
        <Grid item xs={6}>
          <InputSelect name="categoryId" />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={!!actived} onChange={handleSwitchChange} name="actived" color="primary" />}
            label="Desativar/Ativar acesso ao produto"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <br />
          <Stack spacing={2} direction="row">
            {onCancel ? (
              <Button variant="outlined" onClick={onCancel}>
                Cancelar
              </Button>
            ) : null}
            {onBack ? (
              <Button variant="outlined" onClick={onBack}>
                Voltar
              </Button>
            ) : null}

            <Button variant="contained" type="submit" disabled={!!loading}>
              Salvar
            </Button>
          </Stack>
        </Grid>
        {loading ? <CircleLoading light /> : null}
      </Grid>
    </Form>
  )
}

const productSchema = object().shape({
  email: string().email('E-mail inválido'),
  primeiroNome: string().required('Primeiro nome é obrigatório'),
  sobreNome: string().required('Sobrenome é obrigatório')
})
