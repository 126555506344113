import pkg from '../../package.json'
export const dev = process.env.NODE_ENV !== 'production'

const virtualHost = `${process.env.PUBLIC_URL}` || ''
const vhost = typeof virtualHost === 'undefined' || virtualHost === 'undefined' ? '' : virtualHost
export const host = dev ? 'localhost:3333' : vhost || ''

export const imageBase = dev ? `http://${host}` : `${host ? `https://${host}` : ''}`

export const apiBaseURL = dev ? `http://localhost:3333` : process.env.REACT_APP_SERVER_URL // 'http://3.229.100.14:3000'
export const apiBaseURLV1 = apiBaseURL //temp

export const appName = pkg.name
export const appVersion = pkg.version

export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE
