import React from 'react'

import { ReportContainer, Page, ReportGlobalStyle } from './styles'

export const ReportLayout: React.FC = ({ children }) => {
  return (
    <>
      <ReportGlobalStyle />
      <ReportContainer>
        <Page>{children}</Page>
      </ReportContainer>
    </>
  )
}
