import React from 'react'

import { Typography } from '@mui/material'

import type { IAddressItem } from '../Provider'

export const AddressDetail: React.FC<IAddressItem> = ({ localidade, endereco, porta, created, deleted, edited }) => {
  const textPorta = porta ? `, ${porta}` : ''
  const renderText = () => {
    if (!deleted && (created || edited)) {
      return (
        <Typography sx={{ display: 'inline', fontSize: 10 }} component="span" variant="body2" color="primary">
          {`${created ? ' novo' : ' alterado'}`}
        </Typography>
      )
    }
    return null
  }
  return (
    <>
      <Typography sx={{ display: 'inline', textDecoration: deleted ? 'line-through' : 'none' }} component="span" variant="body2">
        {localidade}
      </Typography>
      {` - ${endereco || '--'}${textPorta}`}
      {renderText()}
    </>
  )
}
