import React from 'react'

import { Card, Divider } from '@mui/material'

import { PageTitle } from '~/components/PageTitle'

import { ModalFormContainer, ModalCarContent } from './styles'

type Props = {
  title?: string
  minHeight?: number
}
export const ModalForm: React.FC<Props> = ({ children, title, minHeight }) => {
  return (
    <ModalFormContainer>
      <Card>
        {title ? <PageTitle spotlight={title} horizontalSpaced /> : null}
        <Divider />

        <ModalCarContent minHeight={minHeight}>{children}</ModalCarContent>
      </Card>
    </ModalFormContainer>
  )
}
