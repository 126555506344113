import React, { useState } from 'react'

import { createContext } from 'use-context-selector'

import { IEnderecoPostalDto } from '~/services/Api/ctt/ctt.dto'

export type ItemId = string | number

export interface IAddressItem extends IEnderecoPostalDto {
  id?: ItemId
  deleted?: boolean
  edited?: boolean
  created?: boolean
}

export interface IAddressManagerContext {
  height: number
  list: IAddressItem[]
  setList: React.Dispatch<React.SetStateAction<IAddressItem[]>>
  onSave: SaveAddressManagerHandler
  onUndo: () => void
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  editing: Partial<IAddressItem> | null
  setEditing: React.Dispatch<React.SetStateAction<IAddressItem | null>>
}

export const AddressManagerContext = createContext({} as IAddressManagerContext)

export type CallbackAddressParams = {
  create: IEnderecoPostalDto[]
  edit?: IEnderecoPostalDto[]
  delete: IEnderecoPostalDto[]
}

export type SaveAddressManagerHandler = (params: CallbackAddressParams) => void

type Props = {
  initialList?: IAddressItem[]
  onSave: SaveAddressManagerHandler
  onUndo: () => void
  height?: number
}
export const AddressManagerProvider: React.FC<Props> = ({ children, initialList = [], onSave = () => {}, onUndo = () => {}, height = 300 }) => {
  const [step, setStep] = useState<number>(1)
  const [list, setList] = useState<IAddressItem[]>(initialList)
  const [editing, setEditing] = useState<Partial<IAddressItem> | null>(null)

  return (
    <AddressManagerContext.Provider value={{ list, setList, step, setStep, onSave, onUndo, editing, setEditing, height }}>
      {children}
    </AddressManagerContext.Provider>
  )
}
