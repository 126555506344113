import React, { useCallback, useEffect, useState } from 'react'

import { Button, Card } from '@mui/material'
import Fade from '@mui/material/Fade'

import { tostifyErros } from '~/helpers/toastfy'
import { useAppShop } from '~/hooks/useAppShop'
import { useIsMounted } from '~/hooks/useIsMounted'

import { getProduct, getRelatedProducts, IProdutoDto } from '~/services/Api/products'

import { useBudgetItems } from '~/components/_features/budgets/BudgetProvider'
import { CardTitle } from '~/components/CardTitle'
import { CircleLoading } from '~/components/CircleLoading'
import { SpacedContainer } from '~/components/styled'

import { ProductCounter } from '../../products/ProductCounter'
import { ProductImages } from './ProductImages'
import { ProductInfo } from './ProductInfo'
import { ProductRelated } from './ProductRelated'
import { FlexContainer, FlexItem } from './styles'

type Props = {
  productId?: number
}
export const ProductDetails: React.FC<Props> = ({ productId }) => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IProdutoDto>()
  const [related, setRelated] = useState<IProdutoDto[]>()
  const [quantity, setQuantity] = useState(1)
  const { addItem } = useBudgetItems()
  const { shopSelected } = useAppShop()

  const handleQuantityChange = useCallback((value: number) => {
    if (value) setQuantity(value)
  }, [])

  const handleAdd = useCallback(() => {
    const { id, ...rest } = data
    addItem({ ...rest, productId: productId || id, quantity })
  }, [addItem, quantity, productId, data])

  const fetchData = useCallback(async () => {
    if (!productId) return null
    setLoading(true)
    const response = await getProduct(productId, shopSelected)
    if (isMounted.current) {
      if (response?.success) {
        setData(response?.data)
        const productsRelated = await getRelatedProducts(productId)
        if (productsRelated?.success) setRelated(productsRelated?.data)
      } else {
        tostifyErros(response?.message)
      }
      setLoading(false)
    }
  }, [productId, isMounted, shopSelected])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Card>
      <CardTitle title="Informações do produto" divider />
      <SpacedContainer>
        <Fade in={!!data}>
          <div>
            <ProductImages images={data?.images} />
            <ProductInfo {...data} />
            <ProductRelated productId={data?.id} measureId={data?.measureId} tissueId={data?.tissueId} price={data?.price} relatedList={related} />
            <FlexContainer justify="flex-start" align="center">
              <FlexItem>
                <ProductCounter onChange={handleQuantityChange} />
              </FlexItem>
              <FlexItem>
                <Button type="button" variant="contained" color="primary" onClick={handleAdd} disabled={!!(quantity <= 0)}>
                  ADICIONAR
                </Button>
              </FlexItem>
            </FlexContainer>
          </div>
        </Fade>
        {loading ? <CircleLoading light /> : null}
      </SpacedContainer>
    </Card>
  )
}
