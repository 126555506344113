import React, { useCallback, useEffect } from 'react'

import { TextField, Stack, Button, Grid, Divider } from '@mui/material'

import { useSupplierFormData, FormDataType } from '~/hooks/supplierHooks'

import { CircleLoading } from '~/components/CircleLoading'

type EventChangeType = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>

type Props = {
  id?: number
  onCancel?: () => void
  onSuccess?: () => void
  initialData?: FormDataType
}

export const FormSupplier: React.FC<Props> = ({ onCancel, onSuccess, initialData, id }) => {
  const { formData, loading, request, setFormData, submitData } = useSupplierFormData({ initialData })

  const handleSubmit = useCallback(async () => {
    const success = await submitData()
    if (success && onSuccess) onSuccess()
  }, [onSuccess, submitData])

  const handleTextChange = (name: string) => {
    return (event: EventChangeType) => {
      const text = event?.target?.value || ''
      setFormData(old => ({ ...old, [name]: text }))
    }
  }

  useEffect(() => {
    if (id && !formData?.id) {
      request(id)
    }
  }, [id, formData, request])

  return (
    <React.Fragment key={`form-${formData?.id}`}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            autoComplete="off"
            label="Nome do fornecedor"
            name="nome"
            onChange={handleTextChange('nome')}
            defaultValue={formData?.nome}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            autoComplete="off"
            label="e-mail"
            name="email"
            onChange={handleTextChange('email')}
            defaultValue={formData?.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            autoComplete="off"
            label="Telefone"
            name="telefone"
            onChange={handleTextChange('telefone')}
            defaultValue={formData?.telefone}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <br />
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSubmit} disabled={!!loading}>
              Salvar
            </Button>
          </Stack>
        </Grid>
        {loading ? <CircleLoading light /> : null}
      </Grid>
    </React.Fragment>
  )
}
