import React from 'react'

import { Paper, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import styles from './styles/PagePayment'

export const PagePayment: React.FC = () => {
  const classes = styles()
  return (
    <PageContainer>
      <PageTitle spotlight="Pagamento" title="inserir" />
      <Paper elevation={0} variant="outlined" className={classes.paperTableProdutos}>
        <Box className={classes.titlePaperProdutosArea}>
          <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700} className={classes.titlePaperProdutos}>
            Pedido do Cliente X
          </Typography>
        </Box>
        <Table stickyHeader aria-label="table">
          <TableHead>
            <TableRow style={{ borderBottomColor: '#1b0047', borderBlockStyle: 'solid', borderBottomWidth: 1 }}>
              {['Produto', 'Tamanho', 'Quantidade', 'Valor Unitário', 'Entrega'].map((column, index) => (
                <TableCell key={index}>
                  <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700}>
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {produtos?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>{row.produto['nomeComercial']}</TableCell>
                  <TableCell>{row.produto['medida']}</TableCell>
                  <TableCell>{row.quantidade}</TableCell>
                  <TableCell>{row.produto['precoVenda']}</TableCell>
                  <TableCell>{row.entrega}</TableCell>
                </TableRow>
              )
            })} */}
          </TableBody>
        </Table>
      </Paper>
    </PageContainer>
  )
}
