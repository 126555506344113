import '~/assets/css/App.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-toastify/dist/ReactToastify.css'

import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter, Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ptBr from 'date-fns/locale/pt-BR'
import { PersistGate } from 'redux-persist/integration/react'

import { history } from '~/services/history'

import { Auth0ProviderWithHistory } from '~/components/_auth/Auth0ProviderWithHistory'
import { AppThemeProvider } from '~/components/AppTheme'

import { Routes } from './routes'
import { store, persistor } from './store'

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <AppThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBr}>
            <BrowserRouter>
              <Router history={history}>
                <Auth0ProviderWithHistory>
                  <Routes />
                </Auth0ProviderWithHistory>
              </Router>
            </BrowserRouter>
            <ToastContainer />
          </LocalizationProvider>
        </AppThemeProvider>
      </PersistGate>
    </ReduxProvider>
  )
}

export default App
