import React from 'react'

import { DeleteForever, AttachMoney } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { euroPrice } from '~/helpers'

import { IBudgetItem } from '~/store/reducers/budget'

import { useBudgetItems } from '~/components/_features/budgets/BudgetProvider'
import { ProductCounter } from '~/components/_features/products/ProductCounter'
import { Text } from '~/components/styled'

import { InputDelivery } from './InputDelivery'
import { ItemRow, RowCell, CellLabel, CellContent, CellActions } from './styles'

type Props = IBudgetItem & {}
export const Row: React.FC<Props> = ({ id, displayName, name, measure, quantity, price, productId, delivery }) => {
  const { removeItem, updateItem } = useBudgetItems()

  const handleDelete = () => {
    removeItem({ productId, id })
  }

  const handleQuantityChange = (q: number) => {
    updateItem(id, { quantity: q })
  }

  const total = quantity * price
  return (
    <ItemRow>
      <RowCell>
        <CellLabel>Produto</CellLabel>
        <CellContent>
          {displayName}
          <br />
          <Text size={14} align="left">
            {name}
          </Text>
        </CellContent>
      </RowCell>
      <RowCell>
        <CellLabel>Tamanho</CellLabel>
        <CellContent>{measure?.name}</CellContent>
      </RowCell>
      <RowCell>
        <CellLabel>Quantidade</CellLabel>
        <CellContent>
          <ProductCounter key={`${quantity}`} quantity={quantity} themeColor="text" onChange={handleQuantityChange} />
        </CellContent>
      </RowCell>
      <RowCell>
        <CellLabel>Valor</CellLabel>
        <CellContent align="right">
          {euroPrice(price)}
          <br />
          <Text size={10} align="right" height={10}>
            {euroPrice(total)}
          </Text>
        </CellContent>
      </RowCell>
      <RowCell>
        <CellLabel>Entrega</CellLabel>
        <CellContent>
          <InputDelivery itemId={id} delivery={delivery} />
        </CellContent>
      </RowCell>
      <RowCell>
        <CellActions>
          <IconButton color="primary" aria-label="remover item" component="span" size="small" onClick={handleDelete}>
            <DeleteForever />
          </IconButton>
          <IconButton color="primary" aria-label="upload picture" component="span" size="small" disabled>
            <AttachMoney />
          </IconButton>
        </CellActions>
      </RowCell>
    </ItemRow>
  )
}
