import React from 'react'

import styled from 'styled-components'

import { VariantColorsTypes } from '~/components/AppTheme/types'
import { useAppTheme } from '~/components/AppTheme/useAppTheme'
import { MarginProps } from '~/styles/commonProps'

const Content = styled.div<{ widthLimit?: number; bgColor?: string; shadow?: boolean } & MarginProps>`
  position: relative;
  width: ${({ widthLimit }) => (widthLimit ? `${widthLimit}px` : '100%')};
  max-width: 100%;
  border-radius: ${({ theme }) => theme.rounded}px;
  background-color: ${({ bgColor = 'transparent' }) => bgColor};
  padding-right: ${({ horizontalSpaced, theme }) => (horizontalSpaced ? theme.spacing.l : 0)}px;
  padding-left: ${({ horizontalSpaced, theme }) => (horizontalSpaced ? theme.spacing.l : 0)}px;
  padding-top: ${({ verticalSpaced, theme }) => (verticalSpaced ? theme.spacing.l : 0)}px;
  padding-bottom: ${({ verticalSpaced, theme }) => (verticalSpaced ? theme.spacing.l : 0)}px;
  box-shadow: ${({ shadow }) => `1px 1px 3px rgba(0, 0, 0, ${shadow ? 0.5 : 0})`};
`

type Props = MarginProps & {
  widthLimit?: number
  themeColor?: VariantColorsTypes
  shadow?: boolean
}
export const CardTable: React.FC<Props> = ({ children, widthLimit, themeColor, shadow, ...rest }) => {
  const { theme } = useAppTheme()
  return (
    <Content widthLimit={widthLimit} bgColor={themeColor ? theme.colors[themeColor] : null} shadow={!!shadow} {...rest}>
      {children}
    </Content>
  )
}
