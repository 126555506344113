import React from 'react'

import { Card } from '@mui/material'

import { useBudgetItems } from '~/components/_features/budgets/BudgetProvider'
import { CardTitle } from '~/components/CardTitle'
import { SpacedContainer } from '~/components/styled'

import { Row } from './Row'
// import { RowHeader } from './RowHeader'

// import { useBudget } from '@feat/budgets/BudgetProvider'

type Props = {}
export const ProductItems: React.FC<Props> = () => {
  const { items } = useBudgetItems()
  return (
    <Card>
      <CardTitle title="Produtos do pedido" divider />
      <SpacedContainer>
        {/* <RowHeader /> */}
        {items.map(product => {
          const key = `i-${product.id}`
          return <Row key={key} {...product} />
        })}
      </SpacedContainer>
    </Card>
  )
}
