import React, { useCallback, useState } from 'react'

// import { useAppShop } from '~/hooks/useAppShop'
import { useIsMounted } from '~/hooks/useIsMounted'

import { IShopStock, paginateShopStock } from '~/services/Api/shops/stock'

import { CardTable } from '~/components/CardTable'
import { CircleLoading } from '~/components/CircleLoading'
import { CustomTable } from '~/components/CustomTable'

import { columns } from './columns'
import { TableTools } from './TableTools'

const pageSize = 10

type Props = {
  shopId?: number
}
export const PaginateStockShop: React.FC<Props> = ({ shopId }) => {
  const isMounted = useIsMounted()
  //   const [formOpen, setFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState<IShopStock[]>([])
  const [total, setTotal] = useState(1)
  //   const { currentShop } = useAppShop()

  const fetchData = useCallback(
    async (pagination = {}) => {
      setLoading(true)
      const response = await paginateShopStock(shopId, { ...pagination, size: pageSize })
      if (isMounted.current) {
        setLoading(false)
        if (response && response.success) {
          setRecords(response?.data || [])
          setTotal(response?.total || 0)
        }
      }
    },
    [isMounted, shopId]
  )

  return (
    <CardTable widthLimit={900} shadow themeColor="white">
      <CustomTable columns={columns} pageSize={pageSize} total={total} records={records} onPagination={fetchData}>
        <TableTools />
      </CustomTable>
      {loading ? <CircleLoading light /> : null}
    </CardTable>
  )
}
