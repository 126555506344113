import React, { useRef, useEffect, useState, useCallback } from 'react'

import { Switch, SwitchProps } from '@mui/material'
import { useField } from '@unform/core'

import { propertyWarning } from './debug'

export type InputSwitchProps = Omit<SwitchProps, 'name'> & { name: string }

export const InputSwitch: React.FC<InputSwitchProps> = ({ name, ...restProps }) => {
  const inputRef = useRef(null)
  if (!name) if (!name) propertyWarning('InputSwitch', 'name')
  const { fieldName, defaultValue, registerField } = useField(name)
  const [checked, setChecked] = useState(!!defaultValue)

  const getV = useCallback(() => (checked ? '1' : '0'), [checked])

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'checked'
      })
    }
  }, [fieldName, registerField, getV])

  return <Switch {...restProps} name={name} checked={!!checked} onChange={(_e: any, chk: boolean) => setChecked(chk)} inputRef={inputRef} />
}
