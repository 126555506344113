import React, { memo } from 'react'

import styled from 'styled-components'

import { euroPrice } from '~/helpers'

import type { IProdutoDto } from '~/services/Api/products/products.dto'

import type { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: flex;
  height: 18px;
  flex-direction: column;
  justify-content: center;
`

type Props = ICustomCellProps<IProdutoDto> & {}

const Cell: React.FC<Props> = ({ record }) => {
  return <Content>{euroPrice(record.price)}</Content>
}

export const PriceCell = memo(Cell) as typeof Cell
