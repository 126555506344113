import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ILojaDto } from '~/services/Api/shops/shops.dto'

export interface ShopState {
  readonly shopSelected: number | null
  readonly userShops: Pick<ILojaDto, 'id' | 'name'>[]
}

const initialState: ShopState = {
  shopSelected: null,
  userShops: []
}

export const slice = createSlice({
  name: '@shop',
  initialState,
  reducers: {
    setShop: (state, { payload }: PayloadAction<Partial<ShopState>>) => {
      Object.keys(payload).forEach(k => {
        state[k] = payload[k]
      })
    }
  }
})

export const { setShop } = slice.actions
export default slice.reducer
