import React from 'react'

import { Add, FilterList, Refresh } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { useCustomTable } from '~/components/CustomTable'

type Props = {
  loading?: boolean
  onAdd?: () => void
}

export const TableTools: React.FC<Props> = ({ loading, onAdd }) => {
  const { emitFetch } = useCustomTable()

  const handleAdd = () => onAdd && onAdd()

  return (
    <>
      {onAdd && (
        <IconButton size="large" color="primary" onClick={handleAdd} disabled={!!loading}>
          <Add />
        </IconButton>
      )}
      <IconButton size="large" color="primary" onClick={emitFetch} disabled={!!loading}>
        <Refresh />
      </IconButton>
      <IconButton size="large" color="primary" disabled>
        <FilterList />
      </IconButton>
    </>
  )
}
