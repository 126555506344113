import React, { useCallback, useMemo, useRef } from 'react'

import { Stack, Button, Grid, Divider, FormControlLabel } from '@mui/material'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { object, string } from 'yup'

import { validateFormData } from '~/helpers/validation'
import { FormDataType } from '~/hooks/customerHooks'

import { CircleLoading } from '~/components/CircleLoading'
import { InputSwitch } from '~/components/UnForm/InputSwitch'
import { InputText, InputTextProps } from '~/components/UnForm/InputText'

type Props = {
  onCancel?: () => void
  onSubmit?: (_formData: FormDataType) => Promise<void> | void
  initialData?: FormDataType
  loading?: boolean
}

export const FormCustomer: React.FC<Props> = ({ onCancel, onSubmit, initialData, loading }) => {
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async (data: FormDataType) => {
      const invalidData = await validateFormData(customerSchema, data, formRef.current)
      if (!invalidData) {
        if (onSubmit) onSubmit(data)
      }
    },
    [onSubmit]
  )

  const commonProps = useMemo(() => ({ disabled: !!loading, fullWidth: true, variant: 'outlined', autoComplete: 'off' } as InputTextProps), [loading])

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData} key={`form-key-${initialData?.id}`}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputText name="nome" type="text" label="Nome" required {...commonProps} />
        </Grid>
        <Grid item xs={12}>
          <InputText name="email" label="e-mail" type="text" {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText name="nif" label="NIF" type="text" {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText name="telefone" label="Telefone" type="text" {...commonProps} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            key={`form-key-${initialData?.id}-${loading}`}
            control={<InputSwitch name="ativo" color="primary" />}
            label="Ativar/Desativar cliente"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <br />
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" disabled={loading}>
              Salvar
            </Button>
          </Stack>
        </Grid>
        {loading ? <CircleLoading light /> : null}
      </Grid>
    </Form>
  )
}

const customerSchema = object({
  email: string().email('E-mail inválido'),
  nif: string().required('O campo NIF é obrigatório')
})
