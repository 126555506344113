import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import type { Action, Middleware } from 'redux'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'

import { persistConfig, rootReducer } from '~/store/reducers'

const persistedReducer = persistReducer(persistConfig, rootReducer)
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]

const middlewares: Middleware[] = []

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions, warnAfter: 500 },
      immutableCheck: { warnAfter: 500 } // corrige console.warn (default = 32ms)
    }).concat(...middlewares)
})

const persistor = persistStore(store)
export { store, persistor }

// types
export type IAppStore = typeof store
export type ApplicationState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, ApplicationState, null, Action<string>>
export type AppDispatchType = typeof store.dispatch
