import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useIsMounted } from '~/hooks/useIsMounted'

import { getAllDistricts } from '~/services/Api/ctt'
import { IDistritoDto } from '~/services/Api/ctt/ctt.dto'

export type SelectDistrictHandler = (id: string, district?: IDistritoDto) => void

export type SelectDistrictProps = {
  onSelect?: SelectDistrictHandler
  defaultValue?: string
  unselectText?: string
  required?: boolean
  emitOnLoad?: boolean
}

export const SelectDistrict: React.FC<SelectDistrictProps> = ({ onSelect, defaultValue = '', unselectText, required, emitOnLoad }) => {
  const refFetch = useRef('')
  const isMounted = useIsMounted()
  const [selected, setSelected] = useState<string>(defaultValue)
  const [options, setOptions] = useState<IDistritoDto[]>([])
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback(
    ({ target }: SelectChangeEvent) => {
      const newValue = target?.value || ''
      const item = options?.find(f => f.codigoDistrito === newValue)
      setSelected(newValue)
      if (onSelect) onSelect(newValue, item)
    },
    [onSelect, options]
  )
  const emiLoad = useCallback(() => {
    if (emitOnLoad && !loading && options.length) {
      if (refFetch.current !== defaultValue) {
        refFetch.current = defaultValue
        const found = options.find(({ codigoDistrito }) => codigoDistrito === defaultValue)
        if (found) onSelect(found.codigoDistrito, found)
      }
    }
  }, [defaultValue, emitOnLoad, onSelect, options, loading])

  const fetchData = useCallback(async () => {
    setLoading(true)
    const response = await getAllDistricts()
    if (isMounted.current) {
      setLoading(false)
      if (response && response.success) {
        setOptions(response.data)
      }
    }
  }, [isMounted])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    emiLoad()
  }, [emiLoad])

  const selectProps = useMemo(() => {
    return {
      key: `select-${loading ? 'loading' : defaultValue}`,
      required: !!required,
      value: !loading && options.length ? selected : '',
      onChange: !loading ? handleChange : null
    }
  }, [loading, defaultValue, handleChange, options, selected, required])

  return (
    <FormControl fullWidth>
      <InputLabel id="select-district-label">Distrito</InputLabel>
      <Select
        id="select-district"
        labelId="select-district-label"
        label="Distrito 1"
        fullWidth
        {...selectProps}
        MenuProps={{
          PaperProps: { style: { maxHeight: 250 } }
        }}
      >
        {loading ? (
          <MenuItem value="">
            <em>carregando</em>
          </MenuItem>
        ) : (
          unselectText && (
            <MenuItem value="">
              <em>{loading ? 'aguarde' : unselectText}</em>
            </MenuItem>
          )
        )}

        {options.map(item => {
          return (
            <MenuItem key={`option-district-${item.codigoDistrito}`} value={item.codigoDistrito}>
              {item.designacaoDistrito}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
