import React, { useEffect } from 'react'

import { Grid } from '@mui/material'

import { useSupplierFormData } from '~/hooks/supplierHooks'

type Props = {
  id?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const ViewSupplier: React.FC<Props> = ({ id }) => {
  const { formData, request } = useSupplierFormData()

  useEffect(() => {
    if (id && !formData?.id) {
      request(id)
    }
  }, [id, formData, request])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <p>MOSTRAR DADOS DO FORNECDOR</p>
      </Grid>
      <Grid item xs={12}>
        {JSON.stringify(formData)}
      </Grid>
    </Grid>
  )
}
