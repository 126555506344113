import React, { useRef, useMemo, useCallback, useEffect } from 'react'

import { Input } from '@mui/icons-material'
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material'

import { useCttSearch } from '~/hooks/cttHooks'

import { CircleLoading } from '~/components/CircleLoading'
import { useRollColumn } from '~/components/RollColumn'
import { ClearAdornment, SearchAdornment } from '~/components/UnForm/Adornments'

import { AdressesList } from './AdressesList'
import { SelectItemHandler, useAddressEdit } from './hooks'
import { ItemColumn } from './ItemColumn'
import type { IAddressItem } from './Provider'
import { ListAddressContainer } from './styles'

export const SearchAddress: React.FC = () => {
  const codRef = useRef<HTMLInputElement>(null)
  const { goToColumn } = useRollColumn()
  const { searchPostalCode, clearSearchPostal, foundAdresses, searching } = useCttSearch()
  const [editing, setEditing] = useAddressEdit()

  const handleSearch = useCallback(async () => {
    searchPostalCode(codRef?.current?.value)
  }, [searchPostalCode])

  const handleManualAddrClick = () => setEditing({} as IAddressItem)
  const handleSelectItem: SelectItemHandler = selectedItem => setEditing(selectedItem)

  const handleBack = useCallback(() => {
    clearSearchPostal()
    goToColumn(1)
  }, [clearSearchPostal, goToColumn])

  const imputAdornment = useMemo(() => {
    const endAdornment = foundAdresses?.searchText ? <ClearAdornment onClick={clearSearchPostal} /> : <SearchAdornment onClick={handleSearch} />
    return {
      InputProps: { endAdornment }
    }
  }, [handleSearch, clearSearchPostal, foundAdresses])

  useEffect(() => {
    if (editing) goToColumn(3)
  }, [editing, goToColumn])

  const renderMessage = (notFound: boolean) => {
    return (
      <Typography sx={{ display: 'block', textAlign: 'center' }} component="span" variant="body2" color={notFound ? 'error' : 'default'}>
        {notFound ? 'Código postal não encontrado' : 'Pesquisar código postal'}
      </Typography>
    )
  }

  const [showFound, notFound] = useMemo(() => {
    return [
      !!(foundAdresses?.searchText && foundAdresses?.data.length),
      !!(foundAdresses?.searchText && !foundAdresses?.data.length)
      // ...
    ]
  }, [foundAdresses])

  return (
    <ItemColumn>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField inputRef={codRef} required name="search" label="Código postal" fullWidth {...imputAdornment} />
        </Grid>
        <Grid item xs={12}>
          <ListAddressContainer>
            {showFound ? (
              <AdressesList list={foundAdresses?.data} onSelect={handleSelectItem} />
            ) : (
              <>
                {renderMessage(notFound)}
                <Typography sx={{ display: 'block', textAlign: 'center' }} component="p" variant="body2" color="primary">
                  <Button size="small" startIcon={<Input />} onClick={handleManualAddrClick}>
                    Inserir manualmente
                  </Button>
                </Typography>
              </>
            )}
          </ListAddressContainer>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <br />
          <Stack spacing={1} direction="row">
            <Button variant="outlined" onClick={handleBack}>
              Voltar
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {searching && <CircleLoading light />}
    </ItemColumn>
  )
}
