import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { responseDto, responseError } from '~/services/Api/BaseApi/utils'
import type { IUserPermissaoDto } from '~/services/Api/users/permissions/permission.dto'
import type { IUsuarioDto } from '~/services/Api/users/user.dto'
import { setShop } from '~/store/reducers/shop'

import { apiBaseURL } from '~/config'

export const AuthActionPrefix = '@auth'

export interface IAuthError {
  status?: number
  message?: string
}

export interface IUserAuthenticated {
  id: number
  name: string
  email: string
  picture?: string
}
export interface AuthState {
  readonly loading: boolean
  readonly token?: string | null
  readonly refreshToken?: string | null
  readonly error?: IAuthError | null
  readonly user?: IUserAuthenticated | null
  readonly permissions: IUserPermissaoDto[]
}

const initialState: AuthState = {
  loading: false,
  token: null,
  refreshToken: null,
  error: null,
  user: null,
  permissions: []
}

export const slice = createSlice({
  name: AuthActionPrefix,
  initialState,
  reducers: {
    setAuthLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = !!payload
    },
    setAuth: (state, { payload }: PayloadAction<Partial<AuthState>>) => {
      state.error = null
      Object.keys(payload).forEach(k => {
        state[k] = payload[k]
      })
    },
    loadFailure: (state, { payload }: PayloadAction<AuthState['error']>) => {
      state.token = null
      state.refreshToken = null
      state.error = payload || null
      state.loading = false
    },
    setTokens: (state, { payload }: PayloadAction<Pick<AuthState, 'token' | 'refreshToken'>>) => {
      state.error = null
      state.loading = false
      state.token = payload?.token
      state.refreshToken = payload?.refreshToken
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAuthUserByToken.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchAuthUserByToken.fulfilled, (state, { payload }) => {
      state.loading = false
      state.error = null
      if (payload) {
        const { permissions = [], ...me } = payload
        state.permissions = permissions || []
        state.user = {
          id: me?.id,
          email: me?.email,
          name: me?.primeiroNome
        }
      }
    })
    builder.addCase(fetchAuthUserByToken.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export const { setAuth, loadFailure, setTokens, setAuthLoading } = slice.actions
export default slice.reducer

// thunks
export const fetchAuthUserByToken = createAsyncThunk(
  `${AuthActionPrefix}/fetchAuthUserByToken`,
  async (token: string, thunkAPI): Promise<IUsuarioDto> => {
    let Api = axios.create({ baseURL: apiBaseURL, headers: { Authorization: `Bearer ${token}` } })
    Api.interceptors.response.use(responseDto, responseError)

    const response = await Api.get('/usuarios/me')
    const result = response && response?.data

    Api = null // ?? libera instância na memória

    if (!result?.success) {
      throw thunkAPI.rejectWithValue({
        status: result?.data?.status,
        message: result?.data?.error?.message || result?.data?.error
      })
    } else {
      const data = result?.data as IUsuarioDto
      const userShops = data?.permissions?.map(p => p?.shop)?.filter(f => !!f) || []
      thunkAPI.dispatch(setShop({ userShops }))
      return data
    }
  }
)
