import React, { useCallback, useMemo, memo } from 'react'

import { Inbox, DateRange } from '@mui/icons-material'

import { euroPrice } from '~/helpers'

import { IProdutoDto } from '~/services/Api/products'

import { Span } from '~/components/styled'

import { SelectItemContainer, Name, Cell } from './styles'

export type HandlerItemOptionClick = (productId?: number) => void
type Props = Partial<IProdutoDto> & {
  liProps?: React.HTMLAttributes<HTMLLIElement>
  onClick?: HandlerItemOptionClick
}

const Item: React.FC<Props> = ({ id, displayName, name, price, stocks, liProps, onClick }) => {
  const handleClick: React.MouseEventHandler<HTMLLIElement> = useCallback(
    e => {
      if (onClick) onClick(id)
      if (liProps.onClick) liProps.onClick(e)
    },
    [onClick, id, liProps]
  )

  const hasStock = useMemo(() => {
    return stocks && stocks.reduce((acc, item) => (acc += item?.quantity || 0), 0)
  }, [stocks])

  return (
    <SelectItemContainer {...liProps} key={`option-${id}`} onClick={handleClick}>
      <Name>
        <Span>{displayName}</Span>
        <Span size={14}>{name}</Span>
      </Name>
      <Cell>
        <Span size={14}>{euroPrice(price)}</Span>
      </Cell>
      <Cell>{hasStock ? <Inbox fontSize="small" /> : <DateRange fontSize="small" />}</Cell>
    </SelectItemContainer>
  )
}

export const ItemOption = memo(Item) as typeof Item
