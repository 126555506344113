import { FormatUnderlinedOutlined, GamesOutlined } from '@mui/icons-material'

import { IMenuItem } from '../NavItem'

export const inventoryGroups: IMenuItem[] = [
  {
    id: 'inventory',
    title: 'Gestão do Armazem',
    caption: 'Entrada e Saida de Produtos',
    type: 'group',
    icon: FormatUnderlinedOutlined,
    items: [
      {
        id: 'inventory-basic',
        title: 'Armazem',
        type: 'collapse',
        icon: GamesOutlined,
        items: [
          {
            id: 'inventory-entires',
            title: 'Entrada',
            type: 'item',
            url: '/basic/accordion'
          },
          {
            id: 'inventory-out',
            title: 'Saida',
            type: 'item',
            url: '/basic/avatar'
          },
          {
            id: 'inventory-delivery',
            title: 'Entregas',
            type: 'item',
            url: '/basic/badges'
          }
        ]
      }
    ]
  }
]
