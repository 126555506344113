import { useCallback } from 'react'

import { useContext, useContextSelector } from 'use-context-selector'
import { v4 as uuid } from 'uuid'

import { AddressManagerContext, IAddressItem, IAddressManagerContext } from './Provider'

export type AddItemHandler = (data: IAddressItem) => void
export type RemoveItemHandler = (deletedItem: IAddressItem, draft?: boolean) => void
export type SelectItemHandler = (selectedItem: IAddressItem) => void

type FilterState = (old: IAddressItem[]) => IAddressItem[]
export function useAddressManager() {
  return useContext(AddressManagerContext)
}

export function useAddressManagerItems() {
  const [list, setList] = useContextSelector(AddressManagerContext, context => [context.list, context.setList])
  const onSave = useContextSelector(AddressManagerContext, context => context.onSave)
  const onUndo = useContextSelector(AddressManagerContext, context => context.onUndo)

  const add: AddItemHandler = useCallback(
    (data: IAddressItem) => {
      const id = data?.id || uuid()
      const newItem = { ...data, id, created: !!(typeof id === 'string') } as IAddressItem
      setList(old => [...old, newItem])
    },
    [setList]
  )

  const restore = useCallback(
    (itemId: string | number) => {
      setList(old => old.map(f => (f?.id === itemId ? { ...f, deleted: false } : f)))
    },
    [setList]
  )

  const reset = useCallback(() => {
    setList(old => old.filter(f => !!(typeof f?.id === 'number')).map(f => ({ ...f, deleted: false })))
    if (onUndo) onUndo()
  }, [setList, onUndo])

  const remove: RemoveItemHandler = useCallback(
    (deletedItem, draft) => {
      if (!!deletedItem?.deleted) {
        restore(deletedItem?.id)
      } else {
        const mark: FilterState = old => old.map(f => (!!(f?.id === deletedItem?.id) ? { ...f, deleted: true } : f))
        const filter: FilterState = old => old.filter(f => f.id !== deletedItem?.id)
        setList(old => (draft ? mark(old) : filter(old)))
      }
    },
    [setList, restore]
  )

  return { add, remove, restore, reset, onSave, list }
}

export function useAddressEdit(): [IAddressManagerContext['editing'], IAddressManagerContext['setEditing']] {
  return useContextSelector(AddressManagerContext, context => [context.editing, context.setEditing])
}

export function useAddressStep(): [IAddressManagerContext['step'], IAddressManagerContext['setStep']] {
  return useContextSelector(AddressManagerContext, context => [context.step, context.setStep])
}

export function useAddressLayout(): [IAddressManagerContext['height']] {
  return useContextSelector(AddressManagerContext, context => [context.height])
}
