import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from '~/store'
import { ShopState, setShop } from '~/store/reducers/shop'

export function useAppShop() {
  const shopSelected = useSelector<ApplicationState, ShopState['shopSelected']>(state => state.shop?.shopSelected)
  const userShops = useSelector<ApplicationState, ShopState['userShops']>(state => state.shop?.userShops)
  const dispatch = useDispatch()

  const setShopSelected = useCallback(
    (shopId?: number) => {
      dispatch(setShop({ shopSelected: shopId }))
    },
    [dispatch]
  )

  const currentShop = useMemo(() => {
    return userShops.find(f => f.id === shopSelected)
  }, [userShops, shopSelected])

  return { shopSelected, userShops, setShopSelected, currentShop }
}
