import React from 'react'
import { useParams } from 'react-router-dom'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { BudgetPanel } from '@feat/budgets/BudgetPanel'
import { BudgetProvider } from '@feat/budgets/BudgetProvider'

interface PageProductParams {
  budgetId?: string
  customerId?: string
}

export const PageBudgetStore: React.FC = () => {
  const { budgetId } = useParams<PageProductParams>()
  const id = parseInt(budgetId, 10) || 0

  const pageTitle = id ? 'Alterar' : 'Novo'

  return (
    <PageContainer>
      <PageTitle spotlight={pageTitle} title="orçamento" />
      <BudgetProvider>
        <BudgetPanel budgetId={id} />
      </BudgetProvider>
    </PageContainer>
  )
}
