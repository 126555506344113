import { makeStyles } from '@mui/styles'

const styles = makeStyles(() => ({
  addRemoveItemArea: {
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'center'
  },
  searchItem: {
    width: 900,
    marginBottom: 40
  },
  boxTopArea: {
    display: 'flex',
    flexDirection: 'row'
  },
  itemsBox: {
    width: '110%',
    marginLeft: 20
  },
  paperProdutosArea: {
    padding: 10
  },
  topItemsArea: {
    borderBottomWidth: 1,
    borderBottomColor: '#f9f9f9',
    borderBottomStyle: 'solid',
    padding: 0
  },
  imagesItemArea: {
    backgroundColor: '#f9f9f9',
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
    padding: 10
  },
  marginTopFive: {
    marginTop: 5
  },
  itemsArea: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0
  },
  selectForm: {
    width: 75
  },
  alignCenterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10
  },
  paperTableProdutos: {
    marginTop: 20
  },
  titlePaperProdutosArea: {
    backgroundColor: '#f9f9f9',
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5
  },
  titlePaperProdutos: {
    marginLeft: 25
  },
  tableTopArea: {
    borderBottomColor: '#1b0047',
    borderBlockStyle: 'solid',
    borderBottomWidth: 1
  },
  finalArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  paparFinalArea: {
    marginTop: 20,
    width: '48.5%'
  },
  totalArea: {
    paddingLeft: 50,
    paddingTop: 25,
    paddingRight: 50,
    paddingBottom: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  totalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  quantidadeTableArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonFotterArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonFotter: {
    marginLeft: 'auto',
    marginTop: 20
  }
}))

export default styles
