import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { Stack, Button, Grid, Divider } from '@mui/material'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { object, string } from 'yup'

import { validateFormData } from '~/helpers/validation'
import { useShopFormData, FormDataType } from '~/hooks/shopHooks'

import { CircleLoading } from '~/components/CircleLoading'
import { InputText, InputTextProps } from '~/components/UnForm/InputText'

type Props = {
  id?: number
  onCancel?: () => void
  initialData?: FormDataType
  onSubmit?: (data: FormDataType) => void
  onSuccess?: () => void
}

export const FormShop: React.FC<Props> = ({ onCancel, onSubmit, initialData, id }) => {
  const formRef = useRef<FormHandles>(null)
  const { formData, loading, request } = useShopFormData({ initialData })

  const handleSubmit = useCallback(
    async (data: FormDataType) => {
      const invalidData = await validateFormData(formSchema, data, formRef.current)
      if (!invalidData) {
        if (onSubmit) onSubmit(data)
      }
    },
    [onSubmit]
  )

  useEffect(() => {
    if (id && !formData?.id) {
      request(id)
    }
  }, [id, formData, request])

  const commonProps = useMemo(() => ({ disabled: !!loading, fullWidth: true, variant: 'outlined', autoComplete: 'off' } as InputTextProps), [loading])

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={{ ...formData }} key={`form-store-${formData?.id}`}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputText name="name" type="text" label="Nome da loja" required {...commonProps} />
        </Grid>
        <Grid item xs={12}>
          <InputText name="email" label="e-mail" type="text" {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText name="phone" label="Telefone" type="text" {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText name="nif" label="NIF" type="text" {...commonProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText name="nib" label="NIB" type="text" {...commonProps} />
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <br />
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" disabled={!!loading}>
              Salvar
            </Button>
          </Stack>
        </Grid>
        {loading ? <CircleLoading light /> : null}
      </Grid>
    </Form>
  )
}

const formSchema = object().shape({
  email: string().email('E-mail inválido'),
  nif: string().required('O campo NIF é obrigatório'),
  nib: string()
})
