import { wait } from '~/helpers'
import { compareValues } from '~/helpers/array'

import { IResponsePaginate, Pagination } from '~/services/Api'

interface IMockFilter<T = any> {
  field: keyof T
  value: any
}
interface MockDataType extends Record<string, any> {
  id?: number | string
}

export class MockData<M extends MockDataType = any> {
  constructor(private data: M[] = [], private delay = 2000) {}

  private generateId(): string | number {
    if (!this.data.length) return 1

    let newId = 0
    let candidate = this.data.length
    while (newId <= 0) {
      newId = this.data.find(f => f.id === candidate) ? 0 : candidate
      candidate += 1
    }
    return newId
  }

  async get(filter?: IMockFilter<M>): Promise<M[]> {
    await wait(this.delay)
    if (filter) {
      const { field, value } = filter
      return this.data.filter(f => field && f[field] === value)
    }
    return this.data
  }

  async paginate(pagination: Pagination<M> = {}): Promise<IResponsePaginate<M>> {
    const { size = 25, page = 1, orderby, order } = pagination
    const totalPages = Math.ceil(this.data.length / size)

    let p = page
    if (p <= 0) p = 1
    if (p > totalPages) p = totalPages

    const start = p * size - size
    const end = size * p
    const data = this.data.sort(compareValues(orderby as string, order)).slice(start, end)

    await wait(this.delay)

    return {
      success: true,
      total: this.data.length,
      page: p,
      pages: totalPages,
      data
    }
  }

  async add(payload: M): Promise<M> {
    await wait(this.delay)
    const newEl: M = { ...payload }
    if (!newEl?.id) newEl.id = this.generateId()
    this.data.push(newEl)
    return this.data.find(f => f.id === newEl.id)
  }

  async remove(payloadId: number): Promise<void> {
    await wait(this.delay)
    this.data = this.data.filter(({ id }) => id !== payloadId)
  }

  async findOne(payloadId: number | string): Promise<M> {
    await wait(this.delay)
    return this.data.find(({ id }) => id === payloadId)
  }

  async update(payloadId: number | string, data: Partial<M> = {}): Promise<M> {
    await wait(this.delay)
    this.data = this.data.map(m => {
      return m.id === payloadId ? { ...m, ...data } : m
    })
    return this.data.find(f => f.id === payloadId)
  }
}
