import { useState } from 'react'
import { useAsync } from 'react-async-hook'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import useConstant from 'use-constant'

type DebounceOptions = {
  delay?: number
  minTextLength?: number
}
type Fun = (...args: any[]) => any
export function useDebouncedSearch(searchFunction: Fun, options: DebounceOptions = {}) {
  const { delay = 500, minTextLength = 0 } = options
  const [inputText, setInputText] = useState('')

  const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(searchFunction, delay))

  const searchResults = useAsync(async () => {
    if (inputText?.length <= minTextLength) {
      return []
    } else {
      return debouncedSearchFunction(inputText)
    }
  }, [debouncedSearchFunction, inputText])

  return {
    inputText,
    setInputText,
    searchResults
  }
}
