import React, { useCallback, useState } from 'react'

import { useAppSigned } from '~/hooks/useAppAuth'

import { Authorized } from '~/components/_layout/MainLayout/Authorized'
import { useAppTheme } from '~/components/AppTheme/useAppTheme'
import { CircleLoading } from '~/components/CircleLoading'
import { ModalSelectShop } from '~/components/ModalShop'

import { Content } from './Content'
import { Header } from './Header'
import { Sidebar } from './Sidebar'
import { MainLayoutContainer, AppbarStyled } from './styles'

export const MainLayout: React.FC = ({ children }) => {
  const { theme } = useAppTheme()
  const { loading, signed } = useAppSigned()

  // const signed = true
  const [sidebarWidth, setSideBarWidth] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight] = useState(0)

  const onSidebarResize = useCallback(w => setSideBarWidth(w), [])
  const onHeaderResize = useCallback(h => setHeaderHeight(h), [])

  return (
    <Authorized>
      {loading ? (
        <CircleLoading description="a carregar permissões" color={theme.colors.secondary} light />
      ) : (
        <>
          {signed ? (
            <MainLayoutContainer>
              <Sidebar onResize={onSidebarResize} headerHeight={headerHeight} />
              <Content headerHeight={headerHeight} sidebarWidth={sidebarWidth} footerHeight={footerHeight}>
                {children}
              </Content>
              <AppbarStyled position="fixed">
                <Header onResize={onHeaderResize} />
              </AppbarStyled>
              <ModalSelectShop />
            </MainLayoutContainer>
          ) : (
            <CircleLoading description="Usuário não autenticado" color={theme.colors.contrast} light />
          )}
        </>
      )}
    </Authorized>
  )
}
