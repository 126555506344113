import styled from 'styled-components'

export const Select = styled.select`
  width: 100%;
  max-width: 100%;
  min-width: 82px;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.s}px;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.rounded}px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.text};
`
