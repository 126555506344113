import { combineReducers } from 'redux'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

import { appName, appVersion } from '~/config'

import app from './app'
import auth from './auth'
import shop from './shop'
import theme from './theme'

export const rootReducer = combineReducers({
  app,
  auth,
  theme,
  shop
})

/** Apenas para SSR */
function createNoopStorage() {
  return {
    getItem(_key: string) {
      return Promise.resolve(null)
    },
    setItem(_key: string, value: any) {
      return Promise.resolve(value)
    },
    removeItem(_key: string) {
      return Promise.resolve()
    }
  }
}

function createStorage() {
  const isServer = typeof window === 'undefined'
  const storage = isServer ? createNoopStorage() : createWebStorage('local')
  return storage
}

export const persistConfig = {
  key: `store-${appName}-${appVersion}`,
  storage: createStorage(),
  whitelist: ['auth', 'shop', 'app']
}
