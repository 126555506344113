import React from 'react'

import { useBudgetCustomer } from '~/components/_features/budgets/BudgetProvider'
import { SpacedContainer } from '~/components/styled'

import { ModalTabs } from '@feat/customers/ModalCustomer/ModalTabs'

type Props = {
  onFormCancel?: () => void
}

export const CustomerStore: React.FC<Props> = ({ onFormCancel }) => {
  const { customerId } = useBudgetCustomer()

  return (
    <SpacedContainer>
      <ModalTabs type="form" customerId={customerId} onCancel={onFormCancel} />
    </SpacedContainer>
  )
}
