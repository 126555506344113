import React, { memo, useMemo } from 'react'

import styled from 'styled-components'

import { IShopStock } from '~/services/Api/shops/stock'

import { useAppTheme } from '~/components/AppTheme/useAppTheme'
import type { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  flex-direction: column;
  justify-content: center;
  border: 0;
  span {
    color: ${({ color = 'inherit' }) => color};
    font-weight: bold;
  }
`

type Props = ICustomCellProps<IShopStock> & {}

export const Cell: React.FC<Props> = ({ record }) => {
  const { theme } = useAppTheme()
  const { stocks } = record

  const [quantity, color] = useMemo(() => {
    const q = stocks.filter(f => f.shopId === 1).reduce((total, f) => (total += f.quantity || 0), 0)

    return [q, q <= 0 ? theme.colors.errors : theme.colors.text]
  }, [stocks, theme])

  return (
    <Content color={color}>
      <span>{!stocks.length ? '0' : quantity}</span>
    </Content>
  )
}

export const StockCentralCell = memo(Cell) as typeof Cell
