import type { ILojaDto } from '~/services/Api/shops/shops.dto'

import type { IColumnTable } from '~/components/CustomTable'

import { ActionsCellMemo } from './ActionsCell'
import { ContactCellMemo } from './ContactCell'

export const columns: IColumnTable<ILojaDto>[] = [
  { name: 'name', label: 'Nome' },
  { name: 'email', label: 'Contacto', Cell: ContactCellMemo },
  { label: '', Cell: ActionsCellMemo, width: 110 }
]
