import React from 'react'
import { useHistory } from 'react-router-dom'

import { Auth0Provider, AppState } from '@auth0/auth0-react'

import { auth0Audience, auth0ClientId, auth0Domain } from '~/config'

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = (appState: AppState) => {
    // console.log('onRedirectCallback appState?.returnTo', appState?.returnTo, window.location.pathname)
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      audience={auth0Audience}
      onRedirectCallback={onRedirectCallback}
      scope="openid read:current_user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  )
}
