import styled, { css, createGlobalStyle } from 'styled-components'

export const Page = styled.div`
  max-width: 210mm;
  border: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5mm;
`

export const ReportContainer = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  /* background-color: ${({ theme }) => theme.colors.border}; */
`

const pageCss = css`
  html,
  body {
    background-color: ${({ theme }) => theme.colors.border};
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    size: A4;
    html,
    body {
      width: 210mm;
      /* height: 297mm; */
      background-color: transparent;
    }
    ${Page} {
      border: 1px dashed #000;
      box-shadow: none;
    }
  }
`

export const ReportGlobalStyle = createGlobalStyle`
  ${pageCss}
`
