import React from 'react'

import { RollColumn, Column } from '~/components/RollColumn'

import { EditingAddress } from './EditingAddress'
import { useAddressStep } from './hooks'
import { RegisteredAdresses } from './RegisteredAdresses'
import { SearchAddress } from './SearchAddress'
import { AddressManagerContainer } from './styles'

export const Steps: React.FC = () => {
  const [step, setStep] = useAddressStep()
  const handleColumnChange = (column: number) => setStep(column)

  return (
    <AddressManagerContainer>
      <RollColumn onColumnChange={handleColumnChange}>
        <Column>
          <RegisteredAdresses />
        </Column>
        <Column>{step === 2 && <SearchAddress />}</Column>
        <Column>{step === 3 && <EditingAddress />}</Column>
      </RollColumn>
    </AddressManagerContainer>
  )
}
