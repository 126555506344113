import { makeStyles } from '@mui/styles'

const styles = makeStyles(() => ({
  paperTableProdutos: {
    marginTop: 20
  },
  titlePaperProdutosArea: {
    backgroundColor: '#f9f9f9',
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5
  },
  titlePaperProdutos: {
    marginLeft: 25
  }
}))

export default styles
