import React, { useCallback, useEffect, useState } from 'react'

import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useIsMounted } from '~/hooks/useIsMounted'

import { getPermissions } from '~/services/Api/users/permissions'
import type { IPermissaoOptionDto } from '~/services/Api/users/permissions/permission.dto'

type Props = {
  onChange?: (id?: string) => void
}

export const SelectPapel: React.FC<Props> = ({ onChange }) => {
  const isMounted = useIsMounted()
  const [papel, setPapel] = useState('')
  const [options, setOptions] = useState<IPermissaoOptionDto[]>([])
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback(
    ({ target }: SelectChangeEvent) => {
      const newValue = target?.value || ''
      setPapel(newValue)
      if (onChange) onChange(newValue || undefined)
    },
    [onChange]
  )

  const fetchData = useCallback(async () => {
    setLoading(true)
    const response = await getPermissions()
    if (isMounted.current) {
      setLoading(false)
      if (response && response.success) {
        setOptions(response.data)
      }
    }
  }, [isMounted])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <FormControl fullWidth>
      <InputLabel id="select-papel-label">Papel</InputLabel>
      <Select labelId="select-papel-label" id="select-papel" value={papel} label="Papel" onChange={handleChange} fullWidth>
        <MenuItem value="">
          <em>{loading ? 'aguarde' : 'Nenhuma'}</em>
        </MenuItem>
        {options.map(item => {
          return (
            <MenuItem key={`option-papel-${item.role}`} value={item.role}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
