import React, { useMemo, useCallback } from 'react'

import { AddCircle } from '@mui/icons-material'
import { Box, Button, Divider, Grid, IconButton, Stack, Toolbar } from '@mui/material'

import { itemToAddressDto } from '~/components/AddressManager/utils'
import { useRollColumn } from '~/components/RollColumn'

import { AdressesList } from './AdressesList'
import { useAddressManagerItems, RemoveItemHandler } from './hooks'
import { ItemColumn } from './ItemColumn'

type Props = {
  onReset?: () => void
}

export const RegisteredAdresses: React.FC<Props> = ({ onReset }) => {
  const { list, remove, reset, onSave } = useAddressManagerItems()
  const { goToColumn } = useRollColumn()

  const [deletedList, createdList, editedList] = useMemo(() => {
    return [
      list.filter(f => !!(!f.created && f.deleted)), //deleted
      list.filter(f => !!(f.created && !f.deleted)), // created
      list.filter(f => !!(f.edited && !f.deleted)) // edited
    ]
  }, [list])

  const handleCancel = useCallback(() => {
    reset()
    if (onReset) onReset()
  }, [reset, onReset])

  const handleSave = useCallback(() => {
    onSave({
      create: createdList.map(itemToAddressDto),
      delete: deletedList.map(itemToAddressDto),
      edit: editedList.map(itemToAddressDto)
    })
  }, [onSave, createdList, deletedList, editedList])

  const handleAdd = useCallback(() => goToColumn(2), [goToColumn])
  const handleDelete: RemoveItemHandler = useCallback(item => remove(item, true), [remove])

  const canSave = !!(deletedList?.length || createdList?.length)

  return (
    <ItemColumn>
      <Toolbar variant="dense">
        <span>Endereços cadastrados</span>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleAdd} color="secondary">
          <AddCircle />
        </IconButton>
      </Toolbar>
      <AdressesList list={list} onDelete={handleDelete} />
      <Grid item xs={12}>
        {!list?.length && <Divider />}
        <br />
        <Stack spacing={2} direction="row">
          <Button variant="outlined" type="button" onClick={handleCancel} disabled={!canSave}>
            Desfazer
          </Button>
          <Button variant="contained" type="button" onClick={handleSave} disabled={!canSave}>
            Confirma
          </Button>
        </Stack>
      </Grid>
    </ItemColumn>
  )
}
