import React, { useCallback } from 'react'

import { Card, Modal, CardHeader, CardContent, CardActions, Button, Divider, List, ListItemButton, ListItemText } from '@mui/material'

import { useAppAuth } from '~/hooks/useAppAuth'
import { useAppShop } from '~/hooks/useAppShop'

import { useAppTheme } from '~/components/AppTheme/useAppTheme'
import { ModalContainer, ShopListContainer } from '~/components/ModalShop/styles'

export const ModalSelectShop: React.FC = () => {
  const { shopSelected, userShops, setShopSelected } = useAppShop()
  const { logoutUser, user } = useAppAuth()
  const { theme } = useAppTheme()

  const handleShopClick = useCallback(
    (shipId?: number) => {
      setShopSelected(shipId || null)
    },
    [setShopSelected]
  )

  return (
    <>
      <Modal open={!shopSelected}>
        <ModalContainer>
          <ShopListContainer>
            <Card>
              <CardHeader
                color="primary"
                title="Selecione a loja"
                component="span"
                titleTypographyProps={{ color: theme.colors.primary }}
                subheader={user?.email}
                subheaderTypographyProps={{ fontSize: 10 }}
              />
              <CardContent>
                <List component="nav">
                  {userShops.map((shop, i) => {
                    const key = `${shop.id}-${i}`
                    return (
                      <ListItemButton divider key={key} dense alignItems="flex-start" onClick={() => handleShopClick(shop?.id)} sx={{ height: 56 }}>
                        <ListItemText primary={shop?.name} secondary={`Clique para utilizar a loja ${shop?.name}`} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </CardContent>
              <Divider />
              <CardActions onClick={() => logoutUser()}>
                <Button size="small">SAIR DO SISTEMA</Button>
              </CardActions>
            </Card>
          </ShopListContainer>
        </ModalContainer>
      </Modal>
    </>
  )
}
