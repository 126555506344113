import React, { memo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { ArrowForward } from '@mui/icons-material'
import { ListItem, ListItemText } from '@mui/material'

import { useAppShop } from '~/hooks/useAppShop'

import { useAppTheme } from '~/components/AppTheme/useAppTheme'

import { ItemIcon, ItemTitle, ItemDescription } from '../styles'

export interface IMenuItem {
  id: string
  title: string
  type: 'group' | 'collapse' | 'item'
  caption?: string
  icon?: typeof ArrowForward
  url?: string
  items?: IMenuItem[]
  disabled?: boolean
}

type Props = IMenuItem & { level?: number }

export const NavItem: React.FC<Props> = ({ id, level, icon: Icon, title, url, caption, disabled }) => {
  const match = useRouteMatch(url)
  const { theme } = useAppTheme()
  const { currentShop } = useAppShop()

  const textCaption = currentShop && !caption && id === 'inventory-shop' ? currentShop.name : caption

  const itemIcon = Icon ? <Icon color="inherit" /> : <ArrowForward color="inherit" fontSize="small" />

  const actived = !!(match && match.isExact)
  const textColor = actived ? theme.colors.primary : theme.colors.text

  return (
    <ListItem disabled={!!disabled} component={Link} to={url} button style={{ paddingLeft: level * 10 + 'px' }}>
      <ItemIcon textColor={textColor} textSize={Icon ? 32 : 14}>
        {itemIcon}
      </ItemIcon>
      <ListItemText
        primary={<ItemTitle textColor={textColor}>{title}</ItemTitle>}
        secondary={textCaption && <ItemDescription textColor={textColor}>{textCaption}</ItemDescription>}
      />
    </ListItem>
  )
}

export const NavItemMemo = memo(NavItem)
