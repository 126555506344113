import React from 'react'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { PaginateSupplier } from '@feat/suppliers/PaginateSupplier'

export const PageSuppliers: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle spotlight="Fornecedores" title="cadastrados" />
      <PaginateSupplier />
    </PageContainer>
  )
}
