import styled from 'styled-components'

export const AddressManagerContainer = styled.div`
  display: block;
  position: relative;
  padding-top: ${({ theme }) => theme.spacing.m}px;
`
export const ItemColumnContainer = styled.div<{ heightLimit: number }>`
  display: block;
  position: relative;
  border: 0;
  padding: ${({ theme }) => theme.spacing.s}px;
  padding-top: ${({ theme }) => theme.spacing.m}px;
  margin: 0 auto;
  /* border: 1px dashed #f0f; */
  min-height: ${({ heightLimit }) => heightLimit || 0}px;
`

export const ListAddressContainer = styled.div<{ listHeight?: number }>`
  height: ${({ listHeight = 220 }) => listHeight}px;
`
