import React from 'react'

import { IProdutoDto } from '~/services/Api/products'

import { Text } from '~/components/styled'

import { FlexContainer, FlexItem } from '../styles'

type Props = IProdutoDto & {}
export const ProductInfo: React.FC<Props> = ({ displayName, name, stocks = [] }) => {
  const totalCentral = stocks.filter(f => f.shopId === 1).reduce((total, f) => (total += f.quantity || 0), 0)
  const totalShop = stocks.filter(f => f.shopId !== 1).reduce((total, f) => (total += f.quantity || 0), 0)

  return (
    <FlexContainer>
      <FlexItem>
        <Text color="primary">{displayName || '--'}</Text>
        <Text>{name || '--'}</Text>
      </FlexItem>
      <FlexItem>
        <Text>
          Estoque loja: <strong>{totalShop}</strong>
        </Text>
        <Text>
          Estoque central: <strong>{totalCentral}</strong>
        </Text>
      </FlexItem>
    </FlexContainer>
  )
}
