import React from 'react'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { PaginateCustomers } from '@feat/customers/PaginateCustomer'

export const PageCustomers: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle spotlight="Clientes" title="cadastrados" />
      <PaginateCustomers />
    </PageContainer>
  )
}
