import React, { useState } from 'react'

import { Select } from './styles'

export type SimpleOption = {
  id: number
  label: string
}

export type HandleSimpleSelected = (selected?: SimpleOption['id']) => void

export type SelectSimpleProps = {
  name: string
  options: SimpleOption[]
  onSelect?: HandleSimpleSelected
  defaultValue?: SimpleOption['id']
}

export const SelectSimple: React.FC<SelectSimpleProps> = ({ name, options, onSelect, defaultValue }) => {
  const [selected, setSelected] = useState<number>(defaultValue)

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const value = parseInt(event?.target?.value, 10) || 0
    if (value) {
      setSelected(value)
      if (onSelect && typeof onSelect === 'function') onSelect(value)
    }
  }

  return (
    <Select onChange={handleChange} value={selected || ''} name={name} id={`select-simple-${name}`}>
      {options.map(({ id, label }) => {
        const key = `option-${id}-${label}`
        return (
          <option key={key} value={id}>
            {label}
          </option>
        )
      })}
    </Select>
  )
}
