import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'

import { FormControl, InputLabel, FormHelperText } from '@mui/material'
import Select, { SelectProps } from '@mui/material/Select'
import { useField } from '@unform/core'

import { propertyWarning } from './debug'

function getOptionsCollectionArray(options: HTMLOptionsCollection): HTMLOptionElement[] {
  const arr: HTMLOptionElement[] = []
  for (let i = 0; i < options.length; i += 1) {
    arr.push(options[i])
  }
  return arr
}

function isValidValue(value: string | number | boolean): boolean {
  return typeof value !== 'undefined' && value !== ''
}

export const InputSelect: React.FC<SelectProps> = ({
  name,
  label,
  style,
  className,
  defaultValue,
  children,
  native,
  onChange,
  value: valueProp,
  multiple,
  variant,
  fullWidth,
  ...restProps
}) => {
  if (!name) propertyWarning('InputSelect', 'name')

  const { fieldName, registerField, defaultValue: defaultFieldValue, error } = useField(name)

  const inputRef = useRef(null)
  const defaultInputValue = useMemo(() => {
    if (multiple) {
      return defaultFieldValue || defaultValue || []
    }
    return defaultFieldValue || defaultValue || ''
  }, [defaultFieldValue, defaultValue, multiple])
  const [inputValue, setInputValue] = useState(defaultInputValue)

  const _handleChange = useCallback(
    e => {
      const el = e.target
      let value: number | number[] | string | string[]

      if (native && multiple) {
        value = getOptionsCollectionArray(el.options)
          .filter(opt => opt.selected)
          .map(opt => opt.value)
      } else {
        value = el.value
      }

      if (valueProp === undefined && onChange === undefined) {
        setInputValue(() => value)
      }

      if (valueProp === undefined && typeof onChange === 'function') {
        setInputValue(() => value)
        onChange(e, null)
      }

      if (valueProp !== undefined && typeof onChange === 'function') {
        onChange(e, null)
      }
    },
    [valueProp, onChange, setInputValue, multiple, native]
  )

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue() {
          return valueProp || inputValue
        },
        setValue(_, newValue: string | string[] | number[]) {
          _handleChange({
            target: { value: newValue }
          })
        }
      })
    }
  }, [fieldName, registerField, _handleChange, native, valueProp, inputValue])

  const baseSelectProps: SelectProps = useMemo(
    () => ({
      value: !valueProp ? inputValue : valueProp,
      inputProps: {
        ...restProps.inputProps,
        ref: inputRef
      },
      defaultValue: defaultInputValue || inputValue,
      onChange: _handleChange,
      name,
      multiple,
      label,
      ...restProps
    }),
    [inputValue, defaultInputValue, name, restProps, _handleChange, valueProp, multiple, label]
  )

  const shrink = useMemo<boolean>(() => {
    if (native) return true
    if (multiple) return !!(valueProp || inputValue).length
    return isValidValue(valueProp as any) || !!isValidValue(inputValue)
  }, [native, multiple, inputValue, valueProp])

  return (
    <FormControl style={{ ...style }} fullWidth={fullWidth} className={className} error={!!error} variant={variant}>
      {!!label && (
        <InputLabel shrink={shrink} {...{ 'data-testid': 'select-label' }}>
          {label}
        </InputLabel>
      )}

      <Select {...baseSelectProps} native={native} fullWidth={fullWidth}>
        {children}
      </Select>

      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export const InputSelectMemo = memo(InputSelect)
