import styled from 'styled-components'

import { VariantColorsTypes } from '~/components/AppTheme/types'

export const TissueButton = styled.button<{ bgColor: string; selected?: boolean; themeColor?: VariantColorsTypes }>`
  background-color: ${({ bgColor }) => bgColor};
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.rounded}px;
  border-color: ${({ themeColor = 'primary', selected, theme }) => (selected ? theme.colors[themeColor] : theme.colors.border)};
  cursor: pointer;
`

export const TissueContainer = styled.div<{ size?: number }>`
  display: flex;
  justify-content: flex-start;

  gap: 1px;

  ${TissueButton} {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }
`
