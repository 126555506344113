import React from 'react'

import { Search, Clear } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'

type SearchAdornmentProps = {
  onClick?: () => void
}

export const SearchAdornment: React.FC<SearchAdornmentProps> = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton edge="end" color="primary" onClick={onClick}>
        <Search />
      </IconButton>
    </InputAdornment>
  )
}

export const ClearAdornment: React.FC<SearchAdornmentProps> = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton edge="end" color="primary" onClick={onClick}>
        <Clear />
      </IconButton>
    </InputAdornment>
  )
}
