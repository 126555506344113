import React from 'react'
import { Route } from 'react-router-dom'

import { IAppRoute } from './types'

type PublicRouteProps = IAppRoute & {}

export const PublicRoute: React.FC<PublicRouteProps> = ({ path, exact, Layout, Page, ...rest }) => {
  return (
    <Route
      path={path}
      exact={!!exact}
      component={(props: any) => (
        <Layout {...props}>
          <Page {...props} {...rest} />
        </Layout>
      )}
    />
  )
}
