import React, { useCallback } from 'react'

import { Star } from '@mui/icons-material'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import { IClienteDto } from '~/services/Api/customers'

import { useBudgetCustomer } from '~/components/_features/budgets/BudgetProvider'

export type HandlerCustomerItemClick = (id?: number) => void
type Props = IClienteDto & {
  onClick?: HandlerCustomerItemClick
}
export const CustomerItem: React.FC<Props> = ({ id, nome, email, onClick }) => {
  const { setBudgetCustomer, customerId } = useBudgetCustomer()

  const handleSelectCustomer = useCallback(() => {
    setBudgetCustomer(id)
    if (onClick) onClick(id)
  }, [setBudgetCustomer, id, onClick])

  const startColor = customerId === id ? 'secondary' : 'disabled'

  return (
    <ListItem disablePadding onClick={handleSelectCustomer} divider>
      <ListItemButton dense>
        <ListItemIcon>
          <Star color={startColor} />
        </ListItemIcon>
        <ListItemText primary={nome} secondary={email} />
      </ListItemButton>
    </ListItem>
  )
}
