import React, { useCallback, useMemo, useState } from 'react'

import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

export type Option = { id: string | number; title: string } & Record<string, any>
export type SelectHandler = (id: string | number, selected?: Option) => void

export type SelectOptionProps = {
  name: string
  label: string
  unselectText?: string
  options?: Option[]
  value?: string | number
  required?: boolean
  onSelect?: SelectHandler
  maxHeight?: number
}
export const SelectOption: React.FC<SelectOptionProps> = ({
  name,
  label,
  unselectText,
  value,
  options = [],
  required,
  onSelect,
  maxHeight = 250
}) => {
  const [selected, setSelected] = useState<string | number>(value)

  const handleChange = useCallback(
    ({ target }: SelectChangeEvent) => {
      const newValue = `${target?.value || ''}`
      const item = options?.find(f => {
        if (typeof f?.id === 'number') return f.id === parseInt(newValue, 10)
        return f.id === newValue
      })
      setSelected(newValue)
      if (onSelect) onSelect(item.id, item)
    },
    [onSelect, options]
  )

  const selectProps = useMemo(() => {
    const len = options?.length || 0
    return {
      key: `select-${len}-${value}`,
      value: options.length ? selected : '',
      onChange: options.length ? handleChange : null
    }
  }, [value, handleChange, options, selected])

  return (
    <FormControl fullWidth>
      <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
      <Select
        id={`select-${name}`}
        labelId={`select-${name}-label`}
        label={label}
        fullWidth
        required={!!required}
        {...selectProps}
        MenuProps={{ PaperProps: { style: { maxHeight } } }}
      >
        {unselectText && (
          <MenuItem value="">
            <em>{unselectText}</em>
          </MenuItem>
        )}
        {options.map(item => {
          return (
            <MenuItem key={`option-${name}-${item.id}`} value={item.id}>
              {item.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
