import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { darken } from '@mui/system'
import styled from 'styled-components'

import { VariantColorsTypes } from './AppTheme/types'
import { useAppTheme } from './AppTheme/useAppTheme'

const BackContainer = styled.div<{ bgColor: string; textColor?: string }>`
  margin-right: ${({ theme }) => theme.spacing.l}px;
  border: 0;
  button {
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: ${({ textColor = 'currentColor' }) => textColor};
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    &:hover {
      background-color: ${({ bgColor }) => darken(bgColor, 0.5)};
    }
  }
`

const PageTitleContainer = styled.div<{ horizontalSpaced?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 100%;
  /* border: 1px dashed #000; */
  padding: 0;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  padding-top: ${({ theme }) => theme.spacing.l}px;
  padding-left: ${({ horizontalSpaced, theme }) => (horizontalSpaced ? theme.spacing.l : 0)}px;
  padding-right: ${({ horizontalSpaced, theme }) => (horizontalSpaced ? theme.spacing.l : 0)}px;
`

const Title = styled.div<{ color: string; center?: boolean }>`
  flex: 1;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  h2 {
    color: ${({ theme }) => theme.colors.textDark};
    font-weight: normal;
    font-size: 18px;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
  }

  span {
    color: ${({ color }) => color};
  }
`
const Tools = styled.div``

const Description = styled.p`
  display: block;
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textDark};
`

export type PageTitleProps = {
  title?: string
  spotlight?: string
  themeColor?: VariantColorsTypes
  back?: boolean
  description?: string
  horizontalSpaced?: boolean
  center?: boolean
}

export const PageTitle: React.FC<PageTitleProps> = ({
  children,
  title,
  spotlight,
  themeColor = 'primary',
  back,
  description,
  horizontalSpaced,
  center
}) => {
  const { theme, matchingBackgroudText } = useAppTheme()
  const { go } = useHistory()

  const textColor = useMemo(() => matchingBackgroudText(themeColor), [matchingBackgroudText, themeColor])
  const handleBack = useCallback(() => go(-1), [go])

  return (
    <>
      <PageTitleContainer horizontalSpaced={horizontalSpaced}>
        {back ? (
          <BackContainer bgColor={theme.colors[themeColor]} textColor={textColor}>
            <button type="button" onClick={handleBack}>
              <ArrowBack fontSize="medium" />
            </button>
          </BackContainer>
        ) : null}
        <Title color={theme.colors[themeColor]} center={center}>
          <h2>
            {spotlight ? <span>{spotlight} </span> : null}
            {title}
          </h2>
          {description ? <Description>{description}</Description> : null}
        </Title>
        <Tools>{children}</Tools>
      </PageTitleContainer>
    </>
  )
}
