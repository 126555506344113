import React from 'react'

import { Box, Typography } from '@mui/material'

import { PageContainer } from '~/components/styled'

export const PressBudge: React.FC = () => {
  return (
    <PageContainer>
      <Box
        style={{ borderColor: '#ccc', borderRadius: 10, borderWidth: 1, borderStyle: 'solid', padding: 20, backgroundColor: '#fff' }}
        sx={{ width: 900, mb: 4 }}
      >
        <Typography>Teste</Typography>
      </Box>
    </PageContainer>
  )
}
