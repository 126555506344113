import React, { memo } from 'react'

import styled from 'styled-components'

import type { IUsuarioDto } from '~/services/Api/users/user.dto'

import type { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;

  flex-direction: column;
  justify-content: center;
`

type Props = ICustomCellProps<IUsuarioDto> & {}

export const ContactCell: React.FC<Props> = ({ record }) => {
  return (
    <>
      <Content>{record?.email || '--'}</Content>
    </>
  )
}

export const ActiveCellMemo = memo(ContactCell) as typeof ContactCell
