import React from 'react'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { PaginateUsers } from '@feat/users/PaginateUsers'

export const PageUsers: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle spotlight="Usuários" title="cadastrados" />
      <PaginateUsers />
    </PageContainer>
  )
}
