import { querystring } from '~/helpers/string'

import type { IResponsePaginate, Pagination } from '~/services/Api'
import Api from '~/services/Api'

import type { ILojaDto, IResponseLoja } from './shops.dto'

/** GET `/lojas` */
export async function paginateShops(pagination: Pagination<ILojaDto> = {}): Promise<IResponsePaginate<ILojaDto>> {
  const paginate = querystring(pagination)
  const query = paginate ? `?${paginate}` : ''
  const paginated = await Api.getDefault<IResponsePaginate<ILojaDto>>(`/lojas${query}`)

  return {
    success: paginated.success,
    ...paginated?.data
  }
}

/** GET `/lojas/:id` */
export async function getShop(id: number): Promise<IResponseLoja> {
  const response = await Api.getDefault<ILojaDto>(`/lojas/${id}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

/**
 * - PATCH `/lojas/:id`
 * - POST `/lojas`
 */
export async function storeShop({ id, ...payload }: Partial<ILojaDto>): Promise<IResponseLoja> {
  if (id) {
    const edited = await Api.patchDefault<ILojaDto>(`/lojas/${id}`, payload)
    return {
      success: !!edited?.success,
      shopId: edited?.data?.id,
      data: edited?.data,
      message: edited?.error?.message
    }
  }

  const data = await Api.postDefault<ILojaDto>(`/lojas`, payload)
  return {
    success: !!data?.success,
    shopId: data?.data?.id,
    data: data?.data,
    message: data?.error?.message
  }
}
