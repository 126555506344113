import React, { useCallback, useEffect, useState } from 'react'

import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useIsMounted } from '~/hooks/useIsMounted'

import { paginateShops } from '~/services/Api/shops'
import { ILojaDto } from '~/services/Api/shops/shops.dto'

export type SelectShopHandler = (shopId?: number, item?: Partial<ILojaDto>) => void
type Props = {
  onChangeShop?: SelectShopHandler
  defaultValue?: number
}

export const SelectShop: React.FC<Props> = ({ defaultValue = 0, onChangeShop }) => {
  const isMounted = useIsMounted()
  const [shop, setShop] = useState(`${defaultValue || ''}`)
  const [options, setOptions] = useState<ILojaDto[]>([])
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback(
    ({ target }: SelectChangeEvent) => {
      const newValue = target?.value || ''
      setShop(newValue)
      if (onChangeShop) {
        const idValue = parseInt(newValue, 10)
        const found = options.find(f => f.id === idValue)
        onChangeShop(idValue || undefined, found)
      }
    },
    [onChangeShop, options]
  )

  const fetchShops = useCallback(async () => {
    setLoading(true)
    const response = await paginateShops({ page: 1, size: 1000, order: 'asc', orderby: 'name' })
    if (isMounted.current) {
      setLoading(false)
      if (response && response.success) {
        setOptions(response.data)
      }
    }
  }, [isMounted])

  useEffect(() => {
    fetchShops()
  }, [fetchShops])

  return (
    <FormControl fullWidth>
      <InputLabel id="select-loja-label">Loja</InputLabel>
      <Select labelId="select-loja-label" id="select-loja" value={shop} label="Loja" onChange={handleChange} fullWidth>
        <MenuItem value="">
          <em>{loading ? 'aguarde' : 'Nenhuma'}</em>
        </MenuItem>
        {options.map(item => {
          return (
            <MenuItem key={`option-${item.id}`} value={item.id}>
              {item?.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
