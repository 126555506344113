import React from 'react'

import { IProdutoDto } from '~/services/Api/products'

type Props = {
  images?: IProdutoDto['images']
}
export const ProductImages: React.FC<Props> = ({ images = [] }) => {
  if (!images?.length) return null
  return (
    <div>
      {images.map(image => {
        return (
          <div key={`${image.id}`}>
            <img src={image.url} alt={image.name} />
          </div>
        )
      })}
    </div>
  )
}
