import React from 'react'

import { createTheme } from '@mui/material'
// import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '~/styles/GlobalStyle'

import { useAppTheme } from './useAppTheme'

export const StyledProvider: React.FC = ({ children }) => {
  const { theme } = useAppTheme()

  const appMuiTheme = createTheme({
    palette: {
      primary: { main: theme.colors.primary },
      secondary: { main: theme.colors.secondary },
      contrastThreshold: 3,
      tonalOffset: 0.2
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={appMuiTheme}>
        {/* <CssBaseline /> */}
        <GlobalStyle />
        {children}
      </MuiThemeProvider>
    </ThemeProvider>
  )
}
