import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useIsMounted } from '~/hooks/useIsMounted'

import { paginateProducts, IProdutoDto } from '~/services/Api/products'

import { CardTable } from '~/components/CardTable'
import { CircleLoading } from '~/components/CircleLoading'
import { CustomTable } from '~/components/CustomTable'

import { columns } from './columns'
import { TableToolsProducts } from './TableToolsProducts'

const pageSize = 20

export const PaginateProducts: React.FC = () => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState<IProdutoDto[]>([])
  const [total, setTotal] = useState(1)
  const { push } = useHistory()

  const fetchData = useCallback(
    async (pagination = {}) => {
      setLoading(true)
      const response = await paginateProducts({ ...pagination, size: pageSize })
      if (isMounted.current) {
        setLoading(false)
        if (response && response.success) {
          setRecords(response?.data || [])
          setTotal(response?.total || 0)
        }
      }
    },
    [isMounted]
  )

  const handleAdd = useCallback(() => {
    push('/produtos/0')
  }, [push])

  return (
    <CardTable widthLimit={900} shadow themeColor="white">
      <CustomTable columns={columns} pageSize={pageSize} total={total} records={records} onPagination={fetchData}>
        <TableToolsProducts onAdd={handleAdd} />
      </CustomTable>
      {loading ? <CircleLoading light /> : null}
    </CardTable>
  )
}
