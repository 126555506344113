import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { tostifyErros } from '~/helpers/toastfy'
import { useIsMounted } from '~/hooks/useIsMounted'

import { getSupplier, storeSupplier } from '~/services/Api/suppliers'
import type { IFornecedorDto } from '~/services/Api/suppliers/supplier.dto'

export type FormDataType = Partial<IFornecedorDto>

type Props = {
  initialData?: FormDataType
}

export function useSupplierFormData(props: Props = {}) {
  const { initialData = {} } = props
  const isMounted = useIsMounted()
  const [formData, setFormData] = useState<FormDataType>(initialData)
  const [loading, setLoading] = useState(false)

  const request = useCallback(
    async (id: number) => {
      setLoading(true)
      const response = await getSupplier(id)
      if (isMounted.current) {
        setLoading(false)
        if (response.success) {
          setFormData({ ...response.data })
        }
      }
    },
    [isMounted]
  )

  const submitData = useCallback(async () => {
    const response = await storeSupplier(formData)
    if (isMounted.current) {
      if (response.success) {
        setFormData({ id: response.id })
        toast.success('Fornecedor cadastrado com sucesso!')
      } else {
        tostifyErros(response.message || 'Houve um erro')
      }
      return !!response.success
    }
    return null
  }, [isMounted, formData, setFormData])

  return { formData, loading, request, setFormData, submitData }
}
