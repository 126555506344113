import { darken } from '@mui/system'
import styled from 'styled-components'

export const NavContainer = styled.nav`
  transition: all ease-in-out 0.2s;
`

export const DrawerLogo = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 100%;
`

export const DrawerLogoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  justify-content: center;
  padding: 0px ${({ theme }) => theme.spacing.l}px;
  img,
  svg {
    display: block;
    margin: 0 auto;
    height: 100%;
    max-height: 44px;
  }
`

export const DrawerContainer = styled.div<{ topMargin?: number }>`
  min-width: 280px;
  display: block;
  padding: 0;
  transition: all ease-in-out 0.2s;
  ${DrawerLogoContainer} {
    max-height: ${({ topMargin = 0 }) => topMargin}px;
    height: ${({ topMargin = 0 }) => topMargin}px;
    background-color: ${({ theme }) => darken(theme.colors.primary, 0.3)};
  }
  @media (max-width: 600px) {
    margin-top: 0;
  }
`
