import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { defaultTheme } from '~/components/AppTheme/themes'
import { IAppTheme } from '~/components/AppTheme/types'

const initialState: IAppTheme = {
  ...defaultTheme
}

export const slice = createSlice({
  name: '@theme',
  initialState,
  reducers: {
    setTheme: (state, { payload }: PayloadAction<Partial<IAppTheme>>) => {
      Object.keys(payload).forEach(k => {
        state[k] = payload[k]
      })
    }
  }
})

export const { setTheme } = slice.actions
export default slice.reducer
