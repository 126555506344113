import React, { useEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import { IconButton, Toolbar } from '@mui/material'

import { round } from '~/helpers'
import { useAppMenu } from '~/hooks/useAppMenu'

import logo from '~/assets/images/logo.png'

import { Profile } from './Profile'
import { HeaderContainer, LogoHeaderContainer } from './styles'

type Props = {
  onResize?: (_width?: number) => void
}
export const Header: React.FC<Props> = ({ onResize }) => {
  const { ref, height } = useResizeDetector({ handleHeight: true, refreshMode: 'debounce', refreshRate: 200 })
  const { menuOpened, toogleMenu } = useAppMenu()

  useEffect(() => {
    if (ref.current && onResize) onResize(round(height, 0))
  }, [onResize, height, ref])

  return (
    <HeaderContainer ref={ref}>
      <Toolbar>
        <LogoHeaderContainer heightLimit={height} opened={menuOpened}>
          <img src={logo} alt="Logo" />
        </LogoHeaderContainer>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toogleMenu}>
          <MenuTwoToneIcon />
        </IconButton>
        <div style={{ flex: 1 }}></div>
        <Profile />
      </Toolbar>
    </HeaderContainer>
  )
}
