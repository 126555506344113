import React, { useCallback, useState } from 'react'

import { Add, FilterList, Refresh } from '@mui/icons-material'
import { IconButton, Toolbar } from '@mui/material'

import { useIsMounted } from '~/hooks/useIsMounted'

import { ProductFilterType } from '~/services/Api/products/products.dto'

import { useCustomTableFilter } from '~/components/CustomTable'
import { SearchBar } from '~/components/SearchBar'

type Props = {
  loading?: boolean
  onAdd?: () => void
}

export const TableToolsProducts: React.FC<Props> = ({ loading, onAdd }) => {
  const isMounted = useIsMounted()
  const [actived, setActived] = useState(false)
  const { emitFetch, clearFilter, setFilter, hasFilter } = useCustomTableFilter<ProductFilterType>()
  const handleAdd = () => onAdd && onAdd()

  const toogleActive = useCallback(() => {
    const n = !actived
    setActived(n)
    if (!n && hasFilter) clearFilter()
  }, [actived, clearFilter, hasFilter])

  const updateFilter = useCallback(
    (data: ProductFilterType) => {
      setFilter({ ...data })
    },
    [setFilter]
  )

  const handleTextChange = useCallback(
    (search?: string) => {
      if (isMounted.current && actived) {
        updateFilter({ search })
      }
    },
    [isMounted, actived, updateFilter]
  )

  return (
    <>
      <Toolbar variant="dense">
        {onAdd && (
          <IconButton size="small" color="primary" onClick={handleAdd} disabled={!!loading}>
            <Add />
          </IconButton>
        )}
        <IconButton size="small" color="primary" onClick={emitFetch} disabled={!!loading}>
          <Refresh />
        </IconButton>
        <IconButton size="small" color="primary" disabled={!!loading} onClick={toogleActive}>
          <FilterList />
        </IconButton>
      </Toolbar>
      {actived ? (
        <>
          <SearchBar spaced onChangeText={handleTextChange} />
        </>
      ) : null}
    </>
  )
}
