import React, { useCallback, useState } from 'react'

import { Button, Card, Stack } from '@mui/material'

import { useIsMounted } from '~/hooks/useIsMounted'

import { findCustomer, IClienteDto } from '~/services/Api/customers'

import { useBudgetCustomer } from '~/components/_features/budgets/BudgetProvider'
import { CardTitle } from '~/components/CardTitle'
import { CircleLoading } from '~/components/CircleLoading'
import { SearchBar } from '~/components/SearchBar'
import { SpacedContainer } from '~/components/styled'

import { CustomerList } from './CustomerList'
import { CustomerStore } from './CustomerStore'

type Props = {
  productId?: number
}
export const SelectCustomer: React.FC<Props> = () => {
  const isMounted = useIsMounted()
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IClienteDto[]>([])
  const [openForm, setOpenForm] = useState(false)
  const { customerId, setBudgetCustomer } = useBudgetCustomer()

  const fetchData = useCallback(
    async (nif: string) => {
      if (!!loading) return null

      setLoading(true)
      const response = await findCustomer({ nif })
      if (isMounted.current) {
        setData(response?.success ? response.data : [])
        setLoading(false)
      }
    },
    [loading, isMounted]
  )

  const updateError = useCallback((err?: boolean) => {
    setHasError(!!err)
    if (err) setData([])
    return !!err
  }, [])

  const validateText = useCallback(
    (text?: string) => {
      if (!text) return updateError(false)
      if (text.length < 2) return !updateError(true)
      return !updateError(false)
    },
    [updateError]
  )

  const handleChangeText = useCallback(
    (text?: string) => {
      if (validateText(text)) fetchData(text)
      else setData([])
    },
    [validateText, fetchData]
  )

  const handleCustomerSelect = (_id?: number) => {
    setData([])
    setOpenForm(true)
  }

  const formClose = useCallback(() => {
    setOpenForm(false)
    setBudgetCustomer(null)
  }, [setBudgetCustomer])

  return (
    <>
      <Card>
        <CardTitle title="Cliente" divider />
        {!openForm ? (
          <SpacedContainer>
            <SearchBar
              key={`key-search-${customerId}`}
              placeholder={'buscar por NIF'}
              hasError={hasError}
              onChangeText={handleChangeText}
              messageError="NIF inválido"
            />
          </SpacedContainer>
        ) : null}
        {data ? <CustomerList list={data} onSelect={handleCustomerSelect} /> : null}

        {openForm ? <CustomerStore onFormCancel={formClose} /> : null}
        {!openForm && !loading ? (
          <Stack direction="row" justifyContent="center" onClick={() => setOpenForm(true)}>
            <Button variant="contained">Adicionar Cliente</Button>
          </Stack>
        ) : null}
        <br />
        {loading ? (
          <SpacedContainer>
            <CircleLoading light />
          </SpacedContainer>
        ) : null}
      </Card>
    </>
  )
}
