import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ICategoriaDto } from '~/services/Api/products/category/category.dto'

export interface AppState {
  readonly loading: boolean
  readonly menuOpened: boolean
  readonly categories: ICategoriaDto[]
}

const initialState: AppState = {
  loading: false,
  menuOpened: false,
  categories: []
}

export const slice = createSlice({
  name: '@app',
  initialState,
  reducers: {
    setApp: (state, { payload }: PayloadAction<Partial<AppState>>) => {
      Object.keys(payload).forEach(k => {
        state[k] = payload[k]
      })
    },
    setMenuOpen: (state, { payload }: PayloadAction<Partial<AppState['menuOpened']>>) => {
      state.menuOpened = !!payload
    }
  }
})

export const { setApp, setMenuOpen } = slice.actions
export default slice.reducer
