import styled from 'styled-components'

import { TextAlign } from '~/styles/commonProps'

export const CellHeader = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 14px;
  font-weight: bold;
`

export const CellLabel = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  max-width: 100%;
  font-size: 12px;
  font-weight: bold;
  border: 0;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.s}px;
`
export const CellContent = styled.div<{ align?: TextAlign }>`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  max-width: 100%;
  border: 0;
  flex: 2;
  text-align: ${({ align = 'left' }) => align};
  padding: ${({ theme }) => theme.spacing.s}px;
`

export const CellActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-flow: column nowrap;
  padding: ${({ theme }) => theme.spacing.s}px;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-end;
    gap: 6px;
  }
`

export const RowCell = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-items: stretch;

  border: 0;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    ${CellLabel} {
      width: auto;
      padding: ${({ theme }) => theme.spacing.m}px;
      background-color: ${({ theme }) => theme.colors.border};
      border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    }
    ${CellContent} {
      width: auto;
      padding: ${({ theme }) => theme.spacing.m}px;
    }
  }
`
export const ItemRow = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => `${theme.rounded}px ${theme.rounded}px 0px 0px`};
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 0px;
    margin-bottom: 20px;
  }
`
