import React, { useMemo, memo } from 'react'

import { Divider, ListItem, ListItemText, Typography } from '@mui/material'

import { IAddressItem } from '../Provider'
import { ActionMemo as Action } from './Action'
import { AddressDetail } from './AddressDetail'

type Callback = (param: Partial<IAddressItem>) => void
type Props = IAddressItem & {
  onSelect?: (param: Partial<IAddressItem>) => void
  onDelete?: (param: Partial<IAddressItem>) => void
  onEdit?: (param: Partial<IAddressItem>) => void
  loading?: boolean
}

const Item: React.FC<Props> = ({ onSelect, onDelete, onEdit, loading, ...props }) => {
  const secondaryAction = useMemo(() => {
    const handleClick = (cb: Callback) => {
      return () => cb && cb(props)
    }
    return (
      <>
        {onSelect ? <Action icon="select" onClick={handleClick(onSelect)} disabled={loading} /> : null}
        {onDelete ? <Action icon={props?.deleted ? 'undo' : 'delete'} onClick={handleClick(onDelete)} disabled={loading} /> : null}
        {onEdit ? <Action icon="edit" onClick={handleClick(onEdit)} disabled={loading} /> : null}
      </>
    )
  }, [onSelect, onDelete, onEdit, props, loading])

  const renderPrimary = (text: string) => {
    return (
      <Typography
        sx={{ display: 'inline', textDecoration: !!props?.deleted ? 'line-through' : 'none' }}
        component="span"
        variant="body2"
        color="primary"
      >
        {text}
      </Typography>
    )
  }

  return (
    <>
      <ListItem dense secondaryAction={secondaryAction}>
        <ListItemText primary={renderPrimary(`${props?.distrito || '--'} ${props?.concelho || '--'}`)} secondary={<AddressDetail {...props} />} />
      </ListItem>
      <Divider />
    </>
  )
}

export const ItemAddress = memo(Item)
