import React from 'react'

import { Grid } from '@mui/material'

import { SearchProduct } from '~/components/_features/budgets/SearchProduct'

import { BudgetActionButtons } from '@feat/budgets/BudgetActionButtons'
import { useBudget, useBudgetItems } from '@feat/budgets/BudgetProvider'
import { ProductDetails } from '@feat/budgets/ProductDetails'
import { ProductItems } from '@feat/budgets/ProductItems'
import { SelectCustomer } from '@feat/budgets/SelectCustomer'
import { TotalBudget } from '@feat/budgets/TotalBudget'

type Props = {
  budgetId?: number
}
export const BudgetPanel: React.FC<Props> = ({}) => {
  const { currentProduct, setCurrentProduct } = useBudget()
  const { items } = useBudgetItems()

  const handleSelect = (id: number) => {
    setCurrentProduct(id)
  }

  return (
    <>
      <Grid spacing={2} container>
        <Grid item xs={12} md={4}>
          <SearchProduct onSelect={handleSelect} />
        </Grid>
        {currentProduct ? (
          <Grid item xs={12} md={6}>
            <ProductDetails productId={currentProduct} />
          </Grid>
        ) : null}
        {items?.length ? (
          <>
            <Grid item xs={12} md={10}>
              <ProductItems />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectCustomer />
            </Grid>
            <Grid item xs={12} md={4}>
              <TotalBudget />
            </Grid>
            <Grid item xs={12} md={8}>
              <BudgetActionButtons />
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  )
}
