export function makeArray<T = unknown>(value: T | T[]): T[] {
  return !Array.isArray(value) ? [value] : value
}

export function compareValues(key: string, order = 'asc') {
  return function innerSort(a: any, b: any) {
    if (!(key in a) || !(key in b)) return 0
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

export function* range(begin: number, end: number, interval = 1) {
  for (let i = begin; i <= end; i += interval) {
    yield i
  }
}

export function rangeArray(init: number, fim: number, interval = 1): number[] {
  return Array.from(range(init, fim, interval))
}
