import React from 'react'

import { List } from '@mui/material'

import { IClienteDto } from '~/services/Api/customers'

import { CustomerItem, HandlerCustomerItemClick } from './CustomerItem'

type Props = {
  list?: IClienteDto[]
  onSelect?: HandlerCustomerItemClick
}
export const CustomerList: React.FC<Props> = ({ list = [], onSelect }) => {
  return (
    <List sx={{ width: '100%' }} aria-label="contacts">
      {list.map(customer => {
        const key = `select-item-${customer.id}`
        return <CustomerItem key={key} {...customer} onClick={onSelect} />
      })}
    </List>
  )
}
