import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material'

import { useDebouncedSearch } from '~/hooks/useDebouncedSearch'
import { useIsMounted } from '~/hooks/useIsMounted'

import { findProduct, IProdutoDto } from '~/services/Api/products'

import { ItemOption, HandlerItemOptionClick } from './ItemOption'

export type { HandlerItemOptionClick }

type HandleChange = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
type RenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: IProdutoDto) => ReactNode
type Props = {
  name?: string
  onSelect?: HandlerItemOptionClick
}

export const SearchProduct: React.FC<Props> = ({ name = 'select-found-product', onSelect }) => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<IProdutoDto[]>([])

  const fetchData = useCallback(
    async (text?: string) => {
      setLoading(true)
      const response = await findProduct(text)
      if (isMounted.current) {
        setLoading(false)
        if (response?.success) {
          const newOptions = response?.data || []
          setOptions([...newOptions])
        }
      }
    },
    [isMounted]
  )

  const { inputText, setInputText } = useDebouncedSearch(fetchData)

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const handleItemClick = useCallback(
    (id: number) => {
      if (onSelect) onSelect(id)
    },
    [onSelect]
  )

  const handleChange = useCallback(
    (event: HandleChange) => {
      const text = event?.target?.value || ''
      setInputText(text)
      if (!text) setOptions([])
    },
    [setInputText]
  )

  const renderInput = ({ InputProps, ...params }: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label="Digite o nome do produto"
        value={inputText}
        onChange={handleChange}
        InputProps={{
          ...InputProps,
          placeholder: 'digite o nome ou código',
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {InputProps.endAdornment}
            </React.Fragment>
          )
        }}
      />
    )
  }

  const renderOption: RenderOption = (props, option) => {
    return <ItemOption key={`option-${option?.id}`} liProps={props} {...option} onClick={handleItemClick} />
  }

  useEffect(() => {
    if (!open) setOptions([])
  }, [open])

  return (
    <>
      <Autocomplete
        id={name}
        noOptionsText="Pesquise por um produto"
        loadingText="buscando..."
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        options={options}
        loading={loading}
        renderInput={renderInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => `#${option?.id} - ${option?.displayName}`}
        renderOption={renderOption}
      />
    </>
  )
}
