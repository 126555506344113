import React from 'react'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { PaginateProducts } from '@feat/products/PaginateProducts'

export const PageProducts: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle spotlight="Produtos" title="cadastrados" />
      <PaginateProducts />
    </PageContainer>
  )
}
