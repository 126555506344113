import styled from 'styled-components'

export const GroupDescription = styled.span<{ textColor?: string }>`
  display: block;
  color: ${({ textColor = 'inherit' }) => textColor};
  font-weight: normal;
  font-size: 12px;
`

export const GroupTitle = styled.p<{ textColor?: string }>`
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.spacing.m}px ${theme.spacing.l}px`};
  display: block;
  color: ${({ textColor = 'inherit' }) => textColor};
  font-weight: bold;
  font-size: 14px;
`
