import { IEnderecoPostalDto } from '~/services/Api/ctt/ctt.dto'

import { IAddressItem } from './Provider'

export function itemToAddressDto(data: IAddressItem): IEnderecoPostalDto {
  const without = ['created', 'deleted', 'edited']
  const result = {}
  Object.keys(data).forEach(key => {
    if (!without.includes(key)) result[key] = data[key]
    if (key === 'id' && typeof data?.id === 'number') result[key] == data.id
  })

  return result as IEnderecoPostalDto
}
