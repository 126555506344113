import { BaseApi } from '~/services/Api/BaseApi'
import { store } from '~/store'

import { apiBaseURL } from '~/config'
import { apiBaseURLV1 } from '~/config'

const Api = new BaseApi(store, { baseURL: apiBaseURL, disableIncFetch: true })
export const ApiV1 = new BaseApi(store, { baseURL: apiBaseURLV1, disableIncFetch: true })

export default Api

export interface ICommonResponse {
  success: boolean
  message?: string | string[]
}
export interface IResponsePaginate<T> extends ICommonResponse {
  pages?: number
  page?: number
  total: number
  data: T[]
}

export type Pagination<T = any> = {
  page?: number
  size?: number
  orderby?: keyof T
  order?: 'desc' | 'asc'
}
