import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useIsMounted } from '~/hooks/useIsMounted'

import { getAllCities } from '~/services/Api/ctt'
import type { IConcelhoDto } from '~/services/Api/ctt/ctt.dto'

export type SelectCityHandler = (id: string, concelho?: IConcelhoDto) => void

export type SelectDistrictProps = {
  districtId: string
  onSelect?: SelectCityHandler
  defaultValue?: string
  unselectText?: string
  required?: boolean
  emitOnLoad?: boolean
}

export const SelectCity: React.FC<SelectDistrictProps> = ({ districtId, onSelect, defaultValue = '', unselectText, required, emitOnLoad }) => {
  const refFetch = useRef('')
  const isMounted = useIsMounted()
  const [selected, setSelected] = useState<string>(defaultValue)
  const [options, setOptions] = useState<IConcelhoDto[]>([])
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback(
    ({ target }: SelectChangeEvent) => {
      const newValue = target?.value || ''
      const item = options?.find(f => f.codigoConcelho === newValue)
      setSelected(newValue)
      if (onSelect) onSelect(item?.codigoConcelho, item)
    },
    [onSelect, options]
  )

  const emitLoad = useCallback(() => {
    if (emitOnLoad && !loading && options.length) {
      if (refFetch.current !== `${districtId}-${defaultValue}`) {
        refFetch.current = `${districtId}-${defaultValue}`
        const found = options.find(({ codigoConcelho }) => codigoConcelho === defaultValue)
        if (found) onSelect(found.codigoConcelho, found)
      }
    }
  }, [options, emitOnLoad, onSelect, districtId, defaultValue, loading])

  const fetchData = useCallback(async () => {
    if (districtId) {
      setLoading(true)
      const response = await getAllCities(districtId)
      if (isMounted.current) {
        setLoading(false)
        if (response && response.success) {
          setOptions(response.data)
        }
      }
    }
  }, [isMounted, districtId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    emitLoad()
  }, [emitLoad])

  const selectProps = useMemo(() => {
    return {
      key: `select-${districtId || ''}-${loading ? 'loading' : defaultValue}`,
      required: !!required,
      value: !loading && options.length && options.find(f => f.codigoConcelho === selected) ? selected : '',
      onChange: !loading ? handleChange : null
    }
  }, [loading, defaultValue, handleChange, options, selected, districtId, required])

  return (
    <FormControl fullWidth>
      <InputLabel id="select-city-label">Concelho</InputLabel>
      <Select
        id="select-city"
        labelId="select-city-label"
        label="Concelho"
        fullWidth
        {...selectProps}
        MenuProps={{
          PaperProps: { style: { maxHeight: 250 } }
        }}
      >
        {loading ? (
          <MenuItem value="">
            <em>carregando</em>
          </MenuItem>
        ) : (
          unselectText && (
            <MenuItem value="">
              <em>{loading ? 'aguarde' : unselectText}</em>
            </MenuItem>
          )
        )}

        {options.map(item => {
          return (
            <MenuItem key={`option-city-${item.codigoConcelho}`} value={item.codigoConcelho}>
              {item.designacaoConcelho}
            </MenuItem>
          )
        })}
        {!loading && !options.length ? <MenuItem value={''}>Nenhuma opção</MenuItem> : null}
      </Select>
    </FormControl>
  )
}
