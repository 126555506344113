import React, { useState } from 'react'

import { Card } from '@mui/material'

import { euroPrice } from '~/helpers'

import { CardTitle } from '~/components/CardTitle'
import { SelectOption, Option, SelectHandler } from '~/components/SelectOption'
import { SpacedContainer, Text } from '~/components/styled'

import { useBudgetItems } from '@feat/budgets/BudgetProvider'

import { TotalContainer, TotalValue } from './styles'

type Props = {}

const optionsMock = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const TotalBudget: React.FC<Props> = () => {
  const { totalItems } = useBudgetItems()
  const [installment, setInstallment] = useState(1)

  const m = optionsMock.map(a => {
    const option: Option = { id: a, title: `${a}x` }
    return { ...option }
  })

  const handleChange: SelectHandler = (id: number) => {
    setInstallment(id)
  }

  return (
    <>
      <Card>
        <CardTitle title="Informações de pagamento" divider />
        <SpacedContainer>
          <TotalContainer>
            <TotalValue>
              <SelectOption label="Parcelas" options={m} name="budgetInstallments" value={1} onSelect={handleChange} />
            </TotalValue>
            <TotalValue align="right">
              <h3>Total</h3>
              <br />
              <Text align="right" size={16}>
                {euroPrice(totalItems)}
              </Text>
              <Text align="right" size={14}>
                {installment}x {euroPrice(totalItems / installment)}
              </Text>
            </TotalValue>
          </TotalContainer>
        </SpacedContainer>
      </Card>
    </>
  )
}
