import React, { useState, useEffect } from 'react'

import { Tab, Tabs } from '@mui/material'

import { FormDataType, useCustomerFormData, useCustomerAdresses } from '~/hooks/customerHooks'

import { FormCustomer } from '~/components/_features/customers/FormCustomer'
import { AddressManager } from '~/components/AddressManager'
import { SaveAddressManagerHandler } from '~/components/AddressManager/Provider'

import { TabPanel } from './TabPanel'

export type ModalTabsProps = {
  customerId?: number
  type?: 'form' | 'view'
  defaultTab?: 0 | 1 | number
  onCancel?: () => void
  onSubmitCustomer?: (data: FormDataType) => void
  onSubmitAdresses?: SaveAddressManagerHandler
}
export const ModalTabs: React.FC<ModalTabsProps> = ({ type = 'view', defaultTab = 0, onCancel, onSubmitCustomer, onSubmitAdresses, customerId }) => {
  const [height, setHeight] = useState<number>(null)
  const [tabIndex, setTabIndex] = useState(defaultTab)

  const { formData, request: requestCustomer, loading } = useCustomerFormData()
  const { requestList, addressList } = useCustomerAdresses({ customerId })

  const handleChangeIndex = (index: number) => setTabIndex(index)

  const handleSaveAddress: SaveAddressManagerHandler = params => {
    setTabIndex(0)
    if (onSubmitAdresses) onSubmitAdresses(params)
  }

  const handleUndoAddress = () => {
    setTabIndex(0)
    if (onSubmitAdresses) onSubmitAdresses({ create: [], delete: [], edit: [] })
  }

  useEffect(() => {
    if (customerId && !formData?.id) {
      requestCustomer(customerId).then(ok => {
        if (ok) requestList()
      })
    }
  }, [customerId, formData, requestCustomer, requestList])

  return (
    <>
      <Tabs onChange={(_e, i) => handleChangeIndex(i)} value={tabIndex} indicatorColor="primary">
        <Tab label="Informações" />
        <Tab label="Morada" disabled={!!(type === 'view')} />
      </Tabs>
      <TabPanel index={tabIndex} value={0} onResize={h => h && setHeight(h)}>
        {type === 'form' ? <FormCustomer onCancel={onCancel} onSubmit={onSubmitCustomer} initialData={formData} loading={loading} /> : null}
        {/* {type === 'view' ? <ViewCustomer onCancel={onCancel} onSuccess={onSuccess} /> : null} */}
      </TabPanel>
      <TabPanel index={tabIndex} value={1}>
        <AddressManager height={height} initialList={addressList} onSave={handleSaveAddress} onUndo={handleUndoAddress} />
      </TabPanel>
    </>
  )
}
