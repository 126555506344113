import { querystring } from '~/helpers/string'

import type { IResponsePaginate, Pagination } from '~/services/Api'
import Api from '~/services/Api'
import { IShopStock } from '~/services/Api/shops/stock/shop-stock.dto'

export type { IShopStock }

/** GET `/loja/:id/estoque` */
export async function paginateShopStock(shopId: number, pagination: Pagination<IShopStock> = {}): Promise<IResponsePaginate<IShopStock>> {
  const paginate = querystring(pagination)
  const query = paginate ? `?${paginate}` : ''
  const paginated = await Api.getDefault<IResponsePaginate<IShopStock>>(`/lojas/${shopId}/estoque${query}`)

  return {
    success: paginated.success,
    ...paginated?.data
  }
}
