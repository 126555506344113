import React, { memo } from 'react'

import { ArrowCircleDown, Delete, Edit, Undo } from '@mui/icons-material'
import { IconButton } from '@mui/material'

type Props = {
  icon: 'delete' | 'edit' | 'select' | 'undo'
  onClick?: () => void
  disabled?: boolean
  deleted?: boolean
}

export const Action: React.FC<Props> = ({ onClick, icon, disabled }) => {
  const renderIcon = () => {
    if (icon === 'delete') return <Delete />
    if (icon === 'select') return <ArrowCircleDown />
    if (icon === 'edit') return <Edit />
    if (icon === 'undo') return <Undo />
    return null
  }
  return (
    <IconButton edge="end" aria-label="selecionar" onClick={() => onClick()} color="secondary" disabled={!!disabled}>
      {renderIcon()}
    </IconButton>
  )
}

export const ActionMemo = memo(Action)
