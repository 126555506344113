import Api from '~/services/Api'
import { IClienteEndereco, IResponseClienteEndereco, IResponseClienteEnderecoList } from '~/services/Api/customers/customer.dto'
// import { MockCustomerAddr } from '~/services/Api/customers/mocks'

/**
 * GET /cliente/:customerId/enderecos
 */
export async function getCustomerAdresses(customerId: number): Promise<IResponseClienteEnderecoList> {
  // const data = await MockCustomerAddr.get({ field: 'idCliente', value: customerId })
  const response = await Api.getDefault<IClienteEndereco[]>(`/clientes/${customerId}/enderecos`)
  return {
    success: !!response?.success,
    data: response.data,
    message: response?.error?.message
  }
}

/**
 * GET /cliente/:customerId/endereco/:addressId
 */
export async function getAddress(customerId: number, addressId: number): Promise<IResponseClienteEndereco> {
  if (customerId && addressId) {
    const response = await Api.getDefault<IClienteEndereco>(`/clientes/${customerId}/enderecos/${addressId}`)
    // const data = await MockCustomerAddr.findOne(addressId)
    return {
      success: !!response?.success,
      data: response.data,
      message: response?.error?.message
    }
  }
  return null
}

/**
 * DELETE /cliente/:customerId/endereco/:addressId
 */
export async function deleteCustomerAddress(customerId: number, addressId: number): Promise<IResponseClienteEndereco> {
  if (customerId && addressId) {
    const response = await Api.deleteDefault<IClienteEndereco>(`/clientes/${customerId}/enderecos/${addressId}`)
    // await MockCustomerAddr.remove(adressId)
    return {
      success: !!response?.success,
      message: response?.error?.message
    }
  }
  return null
}

/**
 * - PATCH /cliente/${customerId}/endereco/:addressId
 */
export async function updateCustomerAddress(
  customerId: number,
  addressId: number,
  payload: Partial<IClienteEndereco>
): Promise<IResponseClienteEndereco> {
  if (customerId && addressId) {
    const response = await Api.patchDefault<IClienteEndereco>(`/cliente/${customerId}/endereco/${addressId}`, payload)
    // const response = await MockCustomerAddr.update(adressId, payload)
    return {
      success: !!response?.success,
      idAddress: response?.data?.id,
      message: response?.error?.message
    }
  }
  return null
}

/**
 * - POST /cliente/${customerId}/endereco/
 */
export async function createCustomerAddress(customerId: number, payload: IClienteEndereco): Promise<IResponseClienteEndereco> {
  if (customerId) {
    const response = await Api.postDefault<IClienteEndereco>(`/clientes/${customerId}/enderecos`, { ...payload, idCliente: customerId })
    // const response = await MockCustomerAddr.add({
    //   ...payload,
    //   idCliente: customerId
    // })
    return {
      success: !!response?.success,
      idAddress: response?.data?.id,
      message: response?.error?.message
    }
  }
  return null
}

export async function storeCustomerAddress(customerId: number, { id, ...payload }: Partial<IClienteEndereco>): Promise<IResponseClienteEndereco> {
  if (customerId) {
    if (id) {
      const edit = await updateCustomerAddress(customerId, id, payload)
      return edit
    }

    const add = await createCustomerAddress(customerId, payload as IClienteEndereco)
    return add
  }
  return null
}

export interface IParamsAddressBulk {
  toCreate?: Omit<IClienteEndereco, 'idCliente'>[]
  toEdit?: Partial<IClienteEndereco>[]
  toDelete?: Partial<IClienteEndereco>[]
}

/**
 * Retorna array de string com erros
 * @function bulkCustomerAddress
 */
export async function bulkCustomerAddress(customerId: number, { toCreate = [], toEdit = [], toDelete = [] }: IParamsAddressBulk): Promise<string[]> {
  const create = toCreate.map(addr => {
    delete addr.id
    return addr
  })

  const storeAction = await Promise.all(
    [...create, ...toEdit].map(async addr => {
      const response = await storeCustomerAddress(customerId, { ...addr, idCliente: customerId })
      if (response?.success) return false
      return response?.message || 'Erro ao salvar endereço'
    })
  )
  const deleteAction = await Promise.all(
    toDelete.map(async addr => {
      const response = await deleteCustomerAddress(customerId, addr.id)
      if (response?.success) return false
      return response?.message || 'Erro ao remover endereço'
    })
  )
  const result = [
    ...(storeAction.filter(err => !!err) as string[]),
    ...(deleteAction.filter(err => !!err) as string[])
    ///
  ]
  return result.length ? result : null
}
