import React, { useCallback } from 'react'

import { List, Typography } from '@mui/material'

import { useAppTheme } from '~/components/AppTheme/useAppTheme'

import { NavCollapse } from './../NavCollapse'
import { NavItemMemo, IMenuItem } from './../NavItem'
import { GroupTitle, GroupDescription } from './styles'

type NavGroupProps = {
  id: string
  title: string
  type: 'group' | 'collapse' | 'item'
  caption?: string
  icon?: React.FC
  url?: string
  items?: IMenuItem[]
}

export const NavGroup: React.FC<NavGroupProps> = ({ items = [], title, caption }) => {
  const { theme } = useAppTheme()

  const renderItems = useCallback(() => {
    return items.map(menu => {
      if (menu.type === 'collapse') return <NavCollapse key={menu.id} level={1} {...menu} />
      if (menu.type === 'item') return <NavItemMemo key={menu.id} level={1} {...menu} />
      return (
        <Typography key={menu.id} variant="h6" color="error" align="center">
          Menu Items Error
        </Typography>
      )
    })
  }, [items])

  return (
    <List
      subheader={
        <GroupTitle textColor={theme.colors.primary}>
          {title}
          {caption && <GroupDescription textColor={theme.colors.text}>{caption}</GroupDescription>}
        </GroupTitle>
      }
    >
      {renderItems()}
    </List>
  )
}

export default NavGroup
