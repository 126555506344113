import React, { memo } from 'react'

import styled from 'styled-components'

import type { IFornecedorDto } from '~/services/Api/suppliers/supplier.dto'

import type { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  /* height: 18px; */
  flex-direction: column;
  justify-content: center;
`

type Props = ICustomCellProps<IFornecedorDto> & {}

export const ContactCell: React.FC<Props> = ({ record }) => {
  return (
    <Content>
      {record?.email || '--'}
      {record?.telefone ? (
        <>
          <br />
          {record.telefone}
        </>
      ) : null}
    </Content>
  )
}

export const ContactCellMemo = memo(ContactCell) as typeof ContactCell
