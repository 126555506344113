import React, { useContext, useCallback, useMemo } from 'react'

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'

import CustomTableContext from './CustomTableContext'
import { GenericObject, IColumnTable, Order } from './types'

interface SortProps {
  columnName: string
  label?: string
}

const SortLabel: React.FC<SortProps> = ({ columnName, label }) => {
  const { order, setOrder } = useContext(CustomTableContext)
  const active = useMemo(() => !!(columnName === order[0]), [columnName, order])

  const handleOrder = useCallback(() => {
    const newOrder = [columnName]
    if (order[0] === columnName) newOrder.push(order[1] === 'asc' ? 'desc' : 'asc')
    else newOrder.push('asc')
    setOrder(newOrder as Order)
  }, [order, setOrder, columnName])

  return (
    <TableSortLabel active={active} direction={active ? order[1] : undefined} onClick={handleOrder}>
      {label}
    </TableSortLabel>
  )
}

interface HeaderProps {
  columns: IColumnTable<GenericObject>[]
}

const Header: React.FC<HeaderProps> = ({ columns }) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map(({ name, label, width, unsortable, align = 'left' }, i) => {
          const disabledSort = !name ? true : !!unsortable
          return (
            <TableCell key={`th-${name}-${i}`} width={width} align={align}>
              {disabledSort ? label : <SortLabel columnName={String(name)} label={label} />}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default Header
