import type { IClienteDto } from '~/services/Api/customers/customer.dto'

import type { IColumnTable } from '~/components/CustomTable'

import { ContactCell } from '@feat/customers/PaginateCustomer/ContactCell'

import { ActionsCellMemo } from './ActionsCell'

export const columns: IColumnTable<IClienteDto>[] = [
  { name: 'nome', label: 'Nome' },
  { name: 'email', label: 'contacto', Cell: ContactCell },
  { label: '', Cell: ActionsCellMemo, width: 110 }
]
