import React from 'react'

import { List } from '@mui/material'

import { RemoveItemHandler, SelectItemHandler } from '~/components/AddressManager/hooks'

import { IAddressItem } from '../Provider'
import { ItemAddress } from './ItemAddress'

type Props = {
  list?: IAddressItem[]
  onDelete?: RemoveItemHandler
  onSelect?: SelectItemHandler
}
export const AdressesList: React.FC<Props> = ({ list, onDelete, onSelect }) => {
  return (
    <List>
      {list.map((item, i) => {
        return <ItemAddress key={`${item?.id || i}`} onDelete={onDelete} onSelect={onSelect} {...item} />
      })}
    </List>
  )
}
