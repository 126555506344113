import styled from 'styled-components'

export const SearchContainer = styled.div<{ spaced?: boolean }>`
  padding: ${({ theme, spaced }) => (spaced ? theme.spacing.l : 0)}px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacing.m}px;
  transition: all ease-in-out 0.2s;
`

export const SearchWrapper = styled.div<{ hasError?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: ${({ theme }) => theme.spacing.m}px;
  padding-right: ${({ theme }) => theme.spacing.m}px; */
  padding: ${({ theme }) => theme.spacing.m}px;
  /* border-bottom: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.contrast : theme.colors.border)}; */
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.errors : theme.colors.border)};
  border-radius: ${({ theme }) => theme.rounded}px;
`

export const SearchInput = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.s}px;
  input {
    border: 0;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
    padding: ${({ theme }) => `${theme.spacing.s}px ${theme.spacing.m}px`};
    outline: none;
  }
`
export const SearchIcon = styled.div`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.primary};
  button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const TextError = styled.span`
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.errors};
  transition: all ease-in-out 0.2s;
  transform: translateY(-8px);
`
