import type { IUsuarioDto } from '~/services/Api/users/user.dto'

import type { IColumnTable } from '~/components/CustomTable'

import { ActionsCellMemo } from './ActionsCell'
import { ActiveCellMemo } from './ActiveCell'
import { ContactCell } from './ContactCell'

export const columns: IColumnTable<IUsuarioDto>[] = [
  { name: 'primeiroNome', label: 'Nome' },
  { name: 'email', label: 'Contacto', Cell: ContactCell },
  { name: 'bloqueado', label: 'Bloq.', Cell: ActiveCellMemo, align: 'center', width: 120 },
  { label: '', Cell: ActionsCellMemo, width: 110 }
]
