import { PageLogout } from '~/pages/Logout'
import { PageBudgetReport } from '~/pages/reports/PageBudgetReport'
// import { PageNoFound } from '~/pages/NoFound'
import { PageSignIn } from '~/pages/SignIn'

import { NullLayout } from '../components/_layout/NullLayout'
import type { IAppRoute } from './types'

const publicRouteList: Partial<IAppRoute>[] = [
  { path: '/login', Page: PageSignIn },
  { path: '/logout', Page: PageLogout }
  // { path: '/*', Page: PageNoFound }
  //
]

const publicReportRouteList: Partial<IAppRoute>[] = [
  { path: '/publico/orcamento/:budgetId', Page: PageBudgetReport }
  // { path: '/*', Page: PageNoFound }
  //
]

export const publicRoutes: IAppRoute[] = publicRouteList.map(route => {
  return { ...route, exact: true, Layout: NullLayout } as IAppRoute
})

export const publicReportRoutes: IAppRoute[] = publicReportRouteList.map(route => {
  return { ...route, exact: true, Layout: NullLayout } as IAppRoute
})
