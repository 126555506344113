import { AppBar } from '@mui/material'
import { styled as muiStyled } from '@mui/styles'
import styled from 'styled-components'

export const MainLayoutContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border: 0;
  width: 100%;
  height: 100%;
`

/**
 * The z-index values start at an arbitrary number, high and specific enough to ideally avoid conflicts:
 * - mobile stepper: 1000
 * - speed dial: 1050
 * - app bar: 1100
 * - drawer: 1200
 * - modal: 1300
 * - snackbar: 1400
 * - tooltip: 1500
 */
export const AppbarStyled = muiStyled(AppBar)({
  zIndex: 1201
})
