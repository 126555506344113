import React, { useCallback, useState } from 'react'

import { useIsMounted } from '~/hooks/useIsMounted'

import { paginateCustomers } from '~/services/Api/customers'
import type { IClienteDto } from '~/services/Api/customers/customer.dto'

import { CardTable } from '~/components/CardTable'
import { CircleLoading } from '~/components/CircleLoading'
import { CustomTable } from '~/components/CustomTable'

import { columns } from './columns'
import { TableToolsCustomer } from './TableToolsCustomer'

const pageSize = 20
export const PaginateCustomers: React.FC = () => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState<IClienteDto[]>([])
  const [total, setTotal] = useState(1)

  const fetchData = useCallback(
    async (pagination = {}) => {
      setLoading(true)
      const response = (await paginateCustomers({ ...pagination, size: pageSize })) as any
      if (isMounted.current) {
        setLoading(false)
        if (response && response.success) {
          setRecords(response.data)
          setTotal(response.total || 0)
        }
      }
    },
    [isMounted]
  )

  return (
    <>
      <CardTable widthLimit={900} shadow themeColor="white">
        <CustomTable columns={columns} pageSize={pageSize} total={total} records={records} onPagination={fetchData}>
          <TableToolsCustomer />
        </CustomTable>
        {loading ? <CircleLoading light /> : null}
      </CardTable>
    </>
  )
}
