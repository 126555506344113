import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { Stack, Button, Grid, Divider, FormControlLabel, Switch } from '@mui/material'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { object, string } from 'yup'

import { validateFormData } from '~/helpers/validation'
import { useUserFormData, FormDataType } from '~/hooks/userHooks'

import { CircleLoading } from '~/components/CircleLoading'
import { InputText, InputTextProps } from '~/components/UnForm/InputText'

type Props = {
  id?: number
  onBack?: () => void
  onCancel?: () => void
  onSuccess?: () => void
  initialData?: FormDataType
}

export const FormUser: React.FC<Props> = ({ onCancel, onSuccess, onBack, initialData, id }) => {
  const formRef = useRef<FormHandles>(null)
  const { formData, loading, request, setFormData, submitData } = useUserFormData({ initialData })

  const handleSubmit = useCallback(
    async data => {
      const invalidData = await validateFormData(userSchema, data, formRef.current)
      if (!invalidData) {
        const success = await submitData(data)
        if (success && onSuccess) onSuccess()
      }
    },
    [onSuccess, submitData]
  )

  const handleSwitchChange = (_event: any, checked?: boolean) => {
    setFormData(old => ({ ...old, bloqueado: !!checked }))
  }

  useEffect(() => {
    if (id && !formData?.id) request(id)
  }, [id, formData, request])

  const commonProps = useMemo(() => ({ disabled: !!loading, fullWidth: true, variant: 'outlined', autoComplete: 'off' } as InputTextProps), [loading])

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={formData} key={`form-user-${formData?.id}`}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputText name="primeiroNome" type="text" label="Primeiro nome" required {...commonProps} />
        </Grid>
        <Grid item xs={12}>
          <InputText name="sobreNome" type="text" label="Sobrenome" {...commonProps} />
        </Grid>
        <Grid item xs={12}>
          <InputText name="email" type="text" label="e-mail" {...commonProps} />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={!!formData?.bloqueado} onChange={handleSwitchChange} name="bloqueado" color="error" />}
            label="Bloquear/Desbloquear acesso ao usuário"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <br />
          <Stack spacing={2} direction="row">
            {onCancel ? (
              <Button variant="outlined" onClick={onCancel}>
                Cancelar
              </Button>
            ) : null}
            {onBack ? (
              <Button variant="outlined" onClick={onBack}>
                Voltar
              </Button>
            ) : null}

            <Button variant="contained" type="submit" disabled={!!loading}>
              Salvar
            </Button>
          </Stack>
        </Grid>
        {loading ? <CircleLoading light /> : null}
      </Grid>
    </Form>
  )
}

const userSchema = object().shape({
  email: string().email('E-mail inválido'),
  primeiroNome: string().required('Primeiro nome é obrigatório'),
  sobreNome: string().required('Sobrenome é obrigatório')
})
