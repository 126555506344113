import { NavigationOutlined, HomeOutlined } from '@mui/icons-material'

import type { IMenuItem } from '../NavItem'

export const reportGroups: IMenuItem[] = [
  {
    id: 'navigation',
    title: 'Casa do Sono',
    caption: 'Dashboard & Admin',
    type: 'group',
    icon: NavigationOutlined,
    items: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: HomeOutlined,
        url: '/'
      }
    ]
  }
]
