import React, { useState } from 'react'

import styled from 'styled-components'

import { useBudgetItems } from '~/components/_features/budgets/BudgetProvider'

const Select = styled.select`
  width: 100%;
  max-width: 100%;
  min-width: 82px;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.s}px;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.rounded}px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.text};
`

const options = [
  { id: 1, label: 'Loja' },
  { id: 2, label: 'Estoque' },
  { id: 3, label: 'Fábrica' }
]

type Props = {
  itemId: string | number
  delivery: number
}
export const InputDelivery: React.FC<Props> = ({ itemId, delivery = 1 }) => {
  const { updateItem } = useBudgetItems()
  const [selected, setSelected] = useState(delivery)

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const value = parseInt(event?.target?.value, 10) || 0
    if (value) {
      setSelected(value)
      updateItem(itemId, { delivery: value })
    }
  }

  return (
    <Select onChange={handleChange} value={selected}>
      {options.map(({ id, label }) => {
        const key = `option-${label}`
        return (
          <option key={key} value={id}>
            {label}
          </option>
        )
      })}
    </Select>
  )
}
