import type { IProdutoDto } from '~/services/Api/products'

import type { IColumnTable } from '~/components/CustomTable'

import { ActionsCellMemo } from './ActionsCell'
import { PriceCell } from './PriceCell'

export const columns: IColumnTable<IProdutoDto>[] = [
  { name: 'name', label: 'Nome' },
  { name: 'price', label: 'Valor', align: 'right', Cell: PriceCell },
  {
    label: '',
    Cell: ActionsCellMemo,
    width: 40
  }
]
