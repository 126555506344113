import React, { useState } from 'react'

import { AddCircle } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { IUserPermissaoDto } from '~/services/Api/users/permissions/permission.dto'

import { SelectPapel } from '~/components/_features/permissions/SelectPapel'
import { SelectShop, SelectShopHandler } from '~/components/_features/shops/SelectShop'

import { SelectBar, SelectBarIcon, SelectBarItem } from './styles'

export type PermissionType = Partial<Omit<IUserPermissaoDto, 'id'>> & { id?: any; deleted?: boolean }

type Props = {
  onAdd?: (data: PermissionType) => void
}
export const AddRole: React.FC<Props> = ({ onAdd }) => {
  const [roleData, setRoleData] = useState<PermissionType>()

  const updateRoleData = (data: PermissionType) => {
    setRoleData(old => ({ ...old, ...data }))
  }

  const handleSelectShop: SelectShopHandler = (shopId, shop) => {
    updateRoleData({ shopId, shop: { id: shop?.id, name: shop?.name } })
  }

  const handleAdd = () => {
    if (onAdd) onAdd({ ...roleData })
  }

  const canAdd = !!(roleData && roleData?.shopId && roleData?.role)

  return (
    <SelectBar>
      <SelectBarItem>
        <SelectShop onChangeShop={handleSelectShop} />
      </SelectBarItem>
      <SelectBarItem>
        <SelectPapel onChange={role => updateRoleData({ role })} />
      </SelectBarItem>
      <SelectBarIcon>
        <IconButton color="primary" disabled={!canAdd} onClick={handleAdd}>
          <AddCircle />
        </IconButton>
      </SelectBarIcon>
    </SelectBar>
  )
}
