import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'

import { EditTwoTone } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

import type { IProdutoDto } from '~/services/Api/products/products.dto'

import { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: inline-block;
  min-width: 40px;
  border: 0;
`

export const ActionsCell: React.FC<ICustomCellProps<IProdutoDto>> = ({ record }) => {
  const { push } = useHistory()

  const handleEdit = () => {
    push(`/produtos/${record.id}`)
  }

  if (!record?.id) return null

  return (
    <Content>
      <IconButton color="secondary" onClick={handleEdit}>
        <EditTwoTone fontSize="small" />
      </IconButton>
    </Content>
  )
}

export const ActionsCellMemo = memo(ActionsCell) as typeof ActionsCell
