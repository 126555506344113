import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { MainLayout } from '~/components/_layout/MainLayout'
import { MinimalLayout } from '~/components/_layout/MinimalLayout'
import { ReportLayout } from '~/components/_layout/ReportLayout'

import { privateRoutes } from './private'
import { PrivateRoute } from './PrivateRoute'
import { publicRoutes, publicReportRoutes } from './public'
import { PublicRoute } from './PublicRoute'

export const Routes: React.FC = () => {
  const privatePaths = privateRoutes.map(r => r.path)
  const publicPaths = publicRoutes.map(r => r.path)
  const publicReportPaths = publicReportRoutes.map(r => r.path)

  return (
    <BrowserRouter>
      <Switch>
        <Route path={privatePaths} exact>
          <MainLayout>
            {privateRoutes.map(route => {
              // console.log('privateRoutes', route.path)
              return <PrivateRoute key={`route-${route.path}`} {...route} />
            })}
          </MainLayout>
        </Route>
      </Switch>
      <Switch>
        <Route path={publicReportPaths} exact>
          <ReportLayout>
            {publicReportRoutes.map(route => {
              // console.log('publicRoutes', route.path)
              return <PublicRoute key={`route-report-${route.path}`} {...route} />
            })}
          </ReportLayout>
        </Route>
      </Switch>
      <Switch>
        <Route path={publicPaths} exact>
          <MinimalLayout>
            {publicRoutes.map(route => {
              // console.log('publicRoutes', route.path)
              return <PublicRoute key={`route-minimal-${route.path}`} {...route} />
            })}
          </MinimalLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
