import React, { useState } from 'react'

import { ArrowForward, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Typography, ListItem, ListItemText, Collapse, List } from '@mui/material'

import { useAppTheme } from '~/components/AppTheme/useAppTheme'

import { NavItem, IMenuItem } from '../NavItem'
import { ItemDescription, ItemTitle, ItemIcon } from '../styles'

type Props = IMenuItem & { level?: number }

export const NavCollapse: React.FC<Props> = ({ id, level, items = [], icon: Icon, caption, title, disabled }) => {
  const { theme } = useAppTheme()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)

  const handleClick = () => {
    setOpen(!open)
    setSelected(!selected ? id : null)
  }

  const menus = items.map(item => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} {...item} level={level + 1} />
      case 'item':
        return <NavItem key={item.id} {...item} level={level + 1} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  const menuIcon = Icon ? <Icon fontSize="medium" /> : <ArrowForward fontSize="small" />

  const actived = !!(selected === id)
  const textColor = actived ? theme.colors.primary : theme.colors.text

  return (
    <>
      <ListItem selected={selected === id} disabled={!!disabled} button onClick={handleClick} style={{ paddingLeft: level * 10 + 'px' }}>
        <ItemIcon textColor={textColor} textSize={32}>
          {menuIcon}
        </ItemIcon>
        <ListItemText
          primary={<ItemTitle textColor={textColor}>{title}</ItemTitle>}
          secondary={caption && <ItemDescription textColor={textColor}>{caption}</ItemDescription>}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </>
  )
}
