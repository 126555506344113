import React, { useState } from 'react'

import { TissueButton, TissueContainer } from './styles'

export interface ITissueOption {
  id: number
  productId?: number
  name?: string
  color: string
}

export type HandleColorClick = (selected?: ITissueOption) => void
type Props = {
  options?: ITissueOption[]
  defaultSelected?: ITissueOption['id']
  onSelect?: HandleColorClick
  size?: number
}

export const TissueSample: React.FC<Props> = ({ options, defaultSelected, onSelect, size = 32 }) => {
  const [selected, setSelected] = useState(defaultSelected)

  const handleClick = (opt: ITissueOption) => {
    setSelected(opt?.id)
    if (onSelect && typeof onSelect === 'function') onSelect(opt)
  }

  return (
    <TissueContainer size={size}>
      {options.map(option => {
        return (
          <TissueButton
            key={`tissue-${option.id}`}
            bgColor={option.color}
            title={option?.name}
            onClick={() => handleClick(option)}
            selected={!!(selected === option?.id)}
          />
        )
      })}
    </TissueContainer>
  )
}
