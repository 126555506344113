import React, { useCallback, useState } from 'react'

import { Add, FilterList, Refresh } from '@mui/icons-material'
import { IconButton, Modal, Toolbar } from '@mui/material'

import { useIsMounted } from '~/hooks/useIsMounted'

import { CustomerFilterType } from '~/services/Api/customers'

import { ModalCustomer } from '~/components/_features/customers/ModalCustomer'
import { useCustomTableFilter } from '~/components/CustomTable'
import { SearchBar } from '~/components/SearchBar'

type Props = {
  loading?: boolean
  onAdd?: () => void
}

export const TableToolsCustomer: React.FC<Props> = ({ loading, onAdd }) => {
  const isMounted = useIsMounted()
  const [actived, setActived] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const { emitFetch, clearFilter, setFilter, hasFilter } = useCustomTableFilter<CustomerFilterType>()

  const handleAdd = () => {
    onAdd && onAdd()
    setFormOpen(true)
  }

  const handleFormClose = () => setFormOpen(false)
  const handleSuccess = () => {
    emitFetch()
    setFormOpen(false)
  }

  const toogleActive = useCallback(() => {
    const n = !actived
    setActived(n)
    if (!n && hasFilter) clearFilter()
  }, [actived, clearFilter, hasFilter])

  const updateFilter = useCallback(
    (data: CustomerFilterType) => {
      setFilter({ ...data })
    },
    [setFilter]
  )

  const handleTextChange = useCallback(
    (search?: string) => {
      if (isMounted.current && actived) {
        updateFilter({ search })
      }
    },
    [isMounted, actived, updateFilter]
  )

  return (
    <>
      <Toolbar variant="dense">
        <IconButton size="large" color="primary" onClick={handleAdd} disabled={!!loading}>
          <Add />
        </IconButton>
        <IconButton size="large" color="primary" onClick={emitFetch} disabled={!!loading}>
          <Refresh />
        </IconButton>
        <IconButton size="large" color="primary" disabled={!!loading} onClick={toogleActive}>
          <FilterList />
        </IconButton>
      </Toolbar>
      <Modal open={formOpen} onClose={handleFormClose} onBackdropClick={handleFormClose}>
        <div>
          <ModalCustomer type="form" onCancel={handleFormClose} onSuccess={handleSuccess} />
        </div>
      </Modal>
      {actived ? (
        <>
          <SearchBar spaced onChangeText={handleTextChange} />
        </>
      ) : null}
    </>
  )
}
