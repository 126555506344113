import styled from 'styled-components'

import { TextAlign } from '~/styles/commonProps'

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
`

export const TotalValue = styled.div<{ align?: TextAlign }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 50%;
  text-align: ${({ align = 'left' }) => align};
  h3 {
    padding: 0;
    margin: 0;
  }
  p {
    padding: 0;
    margin: 0;
  }
`
