import React, { useEffect } from 'react'

import { Grid } from '@mui/material'

import { useUserFormData } from '~/hooks/userHooks'

type Props = {
  id?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const ViewUser: React.FC<Props> = ({ id }) => {
  const { formData, request } = useUserFormData()

  useEffect(() => {
    if (id && !formData?.id) {
      request(id)
    }
  }, [id, formData, request])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        --
      </Grid>
      <Grid item xs={12}>
        {JSON.stringify(formData)}
      </Grid>
    </Grid>
  )
}
