import React, { useCallback, useRef, useState } from 'react'

import { Button, Divider, Grid, Stack } from '@mui/material'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { object, string } from 'yup'

import { validateFormData } from '~/helpers/validation'
import { useIsMounted } from '~/hooks/useIsMounted'

import { IEnderecoPostalDto } from '~/services/Api/ctt/ctt.dto'

import { CircleLoading } from '~/components/CircleLoading'
import { InputText } from '~/components/UnForm/InputText'

import { SelectCity, SelectCityHandler } from './SelectCityOld'
import { SelectDistrict, SelectDistrictHandler } from './SelectDistrictOld'

export type FormData = Partial<IEnderecoPostalDto>
export type FormAddressProps = {
  initialData?: FormData
  onSubmit?: (data: IEnderecoPostalDto) => void
  onCancel?: () => void
  loading?: boolean
}

export const FormAddress: React.FC<FormAddressProps> = ({ initialData, loading, onCancel, onSubmit }) => {
  const formRef = useRef<FormHandles>(null)
  const isMounted = useIsMounted()
  const [payload, setPayload] = useState<FormData>({
    codigoDistrito: initialData?.codigoDistrito || '',
    codigoConcelho: initialData?.codigoConcelho || ''
  })

  const updatePayload = useCallback(
    (data: FormData) => {
      if (isMounted.current) setPayload(old => ({ ...old, ...data }))
    },
    [isMounted]
  )

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const formData = { ...data, ...payload }
      const invalid = await validateFormData(addressSchema, formData, formRef.current)
      if (!invalid && onSubmit) {
        onSubmit(formData as IEnderecoPostalDto)
      }
    },
    [onSubmit, payload]
  )

  const handleDistrictChange: SelectDistrictHandler = (_id, selected) => {
    updatePayload({
      codigoDistrito: selected?.codigoDistrito || '',
      distrito: selected?.designacaoDistrito || ''
    })
  }

  const handleCityChange: SelectCityHandler = (_id, selected) => {
    updatePayload({
      codigoConcelho: selected?.codigoConcelho || '',
      concelho: selected?.designacaoConcelho || ''
    })
  }

  return (
    <Form key={`form-${initialData?.codigoPostal || 0}`} ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <InputText required name="codigoPostal" label="Código postal" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <SelectDistrict emitOnLoad onSelect={handleDistrictChange} defaultValue={payload?.codigoDistrito} />
        </Grid>
        <Grid item xs={12}>
          <SelectCity emitOnLoad districtId={payload?.codigoDistrito} onSelect={handleCityChange} defaultValue={payload?.codigoConcelho} />
        </Grid>
        <Grid item xs={12}>
          <InputText required name="localidade" label="Localidade" fullWidth />
        </Grid>
        <Grid item xs={8}>
          <InputText required name="endereco" label="Endereço" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <InputText required name="porta" label="Porta" fullWidth />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <br />
        <Stack spacing={2} direction="row">
          <Button variant="outlined" onClick={onCancel}>
            Cancelar
          </Button>
          <Button variant="contained" type="submit">
            Salvar
          </Button>
        </Stack>
      </Grid>
      {loading && <CircleLoading light />}
    </Form>
  )
}

const addressSchema = object({
  codigoPostal: string().required('Código postal inválido'),
  codigoDistrito: string().required('Informe o distrito'),
  codigoConcelho: string().required('Informe o concelho'),
  localidade: string().required('Informe a localidade'),
  endereco: string().required('Informe o endereço'),
  porta: string().required('Informe a porta')
  // tipo?: 'comercial' | 'residencial'
})
