import { EnhancedStore } from '@reduxjs/toolkit'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

// import decamelcase from '~/helpers/decamelcase'
import { querystring } from '~/helpers/string'

import { responseDto, responseError } from '~/services/Api/BaseApi/utils'
import { ApplicationState } from '~/store'

import { appName } from '~/config'

import type { ApiConfig, IApiStorageData, ResponseApi } from './types'

export class BaseApi {
  public fetchId: number
  public readonly Api: AxiosInstance
  public readonly appName: string
  private readonly disableIncFetch: boolean
  private readonly enableRefreshToken: boolean

  constructor(public store: EnhancedStore<ApplicationState>, { enableRefreshToken, disableIncFetch, ...options }: ApiConfig) {
    this.Api = Axios.create(options)
    this.appName = appName
    this.enableRefreshToken = !!enableRefreshToken
    this.disableIncFetch = !!disableIncFetch

    this.fetchId = 0

    this.setInterceptorRequest()
    this.setInterceptorResponse()
  }

  private setInterceptorRequest(): void {
    this.Api.interceptors.request.use(config => {
      const token = this.getApiStorageData()?.token
      if (token) config.headers.Authorization = `Bearer ${token}`
      // config.data = decamelcase(config.data)
      return config
    })
  }

  /**
   * Removido implementação de refresh token
   */
  private setInterceptorResponse() {
    this.Api.interceptors.response.use(responseDto, responseError)
  }

  private normalizeUrl(path?: string): string {
    const [base = '', query = ''] = `${path}`.split('?')
    const params: any = querystring(query)
    params.fetchId = this.fetchId
    if (this.disableIncFetch) {
      delete params.fetchId
    }

    const q = querystring(params)
    const result = [base.replace(/^(.*)\/$/, '$1'), Object.keys(q).length && q].filter(f => !!f)
    return result.length > 1 ? result.join('?') : result[0]
  }

  private getApiStorageData(): IApiStorageData {
    const { auth } = this.store.getState()
    return {
      token: auth?.token || '',
      refreshToken: auth?.refreshToken || ''
    }
  }

  async getDefault<T = any>(path?: string, config?: AxiosRequestConfig): Promise<ResponseApi<T>> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.get(url, config)

    return response && response.data
  }

  async postDefault<T = any, P = any>(path: string, payload: P, config?: AxiosRequestConfig): Promise<ResponseApi<T>> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.post(url, payload, config)

    return response && response.data
  }

  async putDefault<T = any, P = any>(path: string, payload: P, config?: AxiosRequestConfig): Promise<ResponseApi<T>> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.put(url, payload, config)

    return response && response.data
  }

  async patchDefault<T = any, P = any>(path: string, payload: P, config?: AxiosRequestConfig): Promise<ResponseApi<T>> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.patch(url, payload, config)
    return response && response.data
  }

  async deleteDefault<T = any>(path: string, config?: AxiosRequestConfig): Promise<ResponseApi<T>> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.delete(url, config)
    return response && response.data
  }
}
