import { AxiosRequestConfig } from 'axios'

import { querystring } from '~/helpers/string'

import Api, { IResponsePaginate, Pagination } from '~/services/Api'
import { IResponseUsuario, IUsuarioDto } from '~/services/Api/users/user.dto'

/** GET `/usuarios` */
export async function paginateUsers(pagination: Pagination<IUsuarioDto> = {}): Promise<IResponsePaginate<IUsuarioDto>> {
  const paginate = querystring(pagination)
  const query = paginate ? `?${paginate}` : ''
  const paginated = await Api.getDefault<IResponsePaginate<IUsuarioDto>>(`/usuarios${query}`)

  return {
    success: paginated.success,
    ...paginated?.data
  }
}

/** GET `/usuarios/:id` */
export async function getUser(userId: number): Promise<IResponseUsuario> {
  const response = await Api.getDefault<IUsuarioDto>(`/usuarios/${userId}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

/**
 * - PATCH `/usuarios/:id`
 * - POST `/usuarios/`
 */
export async function storeUser({ id, ...payload }: Partial<IUsuarioDto>): Promise<IResponseUsuario> {
  if (id) {
    const edited = await Api.patchDefault<IUsuarioDto>(`/usuarios/${id}`, payload)
    return {
      success: !!edited?.success,
      userId: edited?.data?.id,
      data: edited?.data,
      message: edited?.error?.message
    }
  }

  const data = await Api.postDefault<IUsuarioDto>(`/usuarios`, payload)
  return {
    success: !!data?.success,
    userId: data?.data?.id,
    data: data?.data,
    message: data?.error?.message
  }
}

/** GET `/usuarios/me` */
export async function getMe(token?: string): Promise<IResponseUsuario> {
  const config: AxiosRequestConfig = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
  const response = await Api.getDefault<IUsuarioDto>(`/usuarios/me`, config)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}
