import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useLayoutAuthorization } from '~/hooks/useAppAuth'

import { useAppTheme } from '~/components/AppTheme/useAppTheme'
import { CircleLoading } from '~/components/CircleLoading'

export const Authorized: React.FC = ({ children }) => {
  const refAuth = useRef(false)
  const { theme } = useAppTheme()
  const { replace } = useHistory()
  const { isAuthenticated, isLoading, requestToken } = useLayoutAuthorization()

  const redirectUser = useCallback(() => {
    if (!isLoading) replace('/login')
  }, [isLoading, replace])

  const fetchToken = useCallback(async () => {
    if (!refAuth.current) {
      await requestToken()
    }
  }, [requestToken])

  useEffect(() => {
    if (isAuthenticated) fetchToken()
    else redirectUser()
  }, [fetchToken, isAuthenticated, redirectUser])

  return <>{isLoading ? <CircleLoading description="a autenticar" color={theme.colors.secondary} light /> : children}</>
}
