import styled, { css } from 'styled-components'

export const SelectItemContainer = styled.li`
  width: 100%;
  max-width: 100%;
  display: block;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: ${({ theme }) => theme.spacing.l}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  span {
    display: block;
  }
`

const columnCss = css`
  display: flex;
  max-width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
`
export const Name = styled.div`
  flex: 1;
  ${columnCss}
`
export const Cell = styled.div`
  ${columnCss}
  &:last-child {
    /* border: 1px solid #000; */
    /* padding-right: ${({ theme }) => theme.spacing.m}px; */
  }
`
