import { NavigationOutlined, ShoppingCartOutlined, LoyaltyOutlined, AssignmentIndOutlined, EventNote } from '@mui/icons-material'

import type { IMenuItem } from '~/components/MenuList/NavItem'

export const sellerGroups: IMenuItem[] = [
  {
    id: 'application',
    title: 'Vendas',
    caption: 'módulo de vendas e pedidos',
    type: 'group',
    icon: NavigationOutlined,
    items: [
      {
        id: 'purchase',
        title: 'Pedidos',
        type: 'collapse',
        icon: ShoppingCartOutlined,
        items: [
          { id: 'purchase-budget-new', title: 'Criar Orçamento', type: 'item', url: '/orcamentos/0' },
          // { id: 'purchase-budget-create', title: 'Criar Orçamento', type: 'item', url: '/criar-orcamento' },
          { id: 'find-purchase', title: 'Consultar Pedido', type: 'item', url: '/pedidos' },
          { id: 'create-purchase', title: 'Realizar Pedido', type: 'item', url: '/pedidos/edit', caption: 'Teste', disabled: true },
          { id: 'tracker-purchase', title: 'Acompanhar Pedidos', type: 'item', url: '/pedidos/acompanhar', disabled: true },
          { id: 'store-purchase', title: 'Pedidos da Loja', type: 'item', url: '/pedidos/loja', disabled: true }
        ]
      },
      {
        id: 'inventory',
        title: 'Estoque',
        type: 'collapse',
        icon: LoyaltyOutlined,
        items: [
          { id: 'inventory-shop', title: 'Estoque da Loja', type: 'item', url: '/loja/estoque' },
          { id: 'inventory-central', title: 'Estoque Central', type: 'item', url: '/estoque', disabled: true }
        ]
      },
      {
        id: 'clients',
        title: 'Clientes',
        type: 'collapse',
        icon: AssignmentIndOutlined,
        items: [
          { id: 'clients-list', title: 'Consultar Clientes', type: 'item', url: '/clientes' },
          { id: 'clients-purchase', title: 'Pedido do Cliente', type: 'item', url: '/clientes/purchases', disabled: true }
        ]
      },
      ,
      {
        id: 'delivery',
        title: 'Entregas',
        type: 'collapse',
        icon: EventNote,
        items: [{ id: 'account', title: 'Confirma Entrega', type: 'item', url: '/user/account', disabled: true }]
      }
    ]
  }
]
