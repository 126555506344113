import { ForumOutlined, GridOnOutlined } from '@mui/icons-material'

import type { IMenuItem } from '../NavItem'

const adminStoreItems: IMenuItem[] = [
  {
    id: 'admin-store',
    title: 'Loja',
    caption: 'Administração da loja',
    type: 'collapse',
    icon: GridOnOutlined,
    disabled: true,
    items: [
      {
        id: 'admin-store-clients',
        title: 'Pedidos de Clientes',
        caption: 'Acompanhar pedidos de clientes',
        type: 'item',
        url: '/mui-datatables/mui-simple',
        disabled: true
      },
      {
        id: 'admin-store-supplier',
        title: 'Pedido de Fornecedor',
        caption: 'Acompanhar pedidos de fornecedor',
        type: 'item',
        url: '/mui-datatables/mui-column-filters',
        disabled: true
      },
      {
        id: 'admin-store-stock',
        title: 'Estoque Loja',
        type: 'item',
        url: '/mui-datatables/mui-column-options-update',
        disabled: true
      },
      {
        id: 'admin-store-seller',
        title: 'Cadastro de Vendedores',
        type: 'item',
        url: '/mui-datatables/mui-column-options-update1',
        disabled: true
      }
    ]
  }
]

const adminSistemItems: IMenuItem[] = [
  {
    id: 'system',
    title: 'Sistema',
    caption: 'Administração do sistema',
    type: 'collapse',
    icon: GridOnOutlined,
    items: [
      {
        id: 'system-users',
        title: 'Usuários',
        caption: 'Usuários do sistema',
        type: 'item',
        url: '/usuarios'
      },
      {
        id: 'system-shops',
        title: 'Lojas',
        caption: 'Cadastro de lojas',
        type: 'item',
        url: '/lojas'
      },
      {
        id: 'system-products',
        title: 'Produtos',
        caption: 'Cadastro de produtos',
        type: 'item',
        url: '/produtos'
      },
      {
        id: 'system-suppliers',
        title: 'Fornecedores',
        caption: 'Cadastro de fornecedores',
        type: 'item',
        url: '/fornecedores'
      },
      {
        id: 'system-simple',
        title: 'Pedidos de Lojas',
        caption: 'Acompanhar pedidos de loja',
        type: 'item',
        url: '/mui-datatables/mui-simple',
        disabled: true
      },
      {
        id: 'system-column-filters',
        title: 'Pedido Fornecedor',
        caption: 'Acompanhar pedido de fornecedor',
        type: 'item',
        url: '/mui-datatables/mui-column-filters',
        disabled: true
      },
      {
        id: 'system-column-options-update1',
        title: 'Estoque Central',
        type: 'item',
        url: '/mui-datatables/mui-column-options-update',
        disabled: true
      }
    ]
  }
]

export const adminGroups: IMenuItem[] = [
  {
    id: 'admin',
    title: 'Administração',
    caption: 'Recursos de Administradores',
    type: 'group',
    icon: ForumOutlined,
    items: [...adminStoreItems, ...adminSistemItems]
  }
]
