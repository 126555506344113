import React, { useCallback, useEffect } from 'react'

import { useRollColumn } from '~/components/RollColumn'

import { FormAddressProps, FormAddress } from './FormAddress'
import { useAddressEdit, useAddressManagerItems } from './hooks'
import { ItemColumn } from './ItemColumn'
import type { IAddressItem } from './Provider'

export const EditingAddress: React.FC<FormAddressProps> = props => {
  const [editing, setEditing] = useAddressEdit()
  const { add } = useAddressManagerItems()
  const { goToColumn } = useRollColumn()

  const handleCancel = useCallback(() => setEditing(null), [setEditing])

  const handleSubmit = useCallback(
    (formData: IAddressItem) => {
      add(formData)
      setEditing(null)
    },
    [add, setEditing]
  )

  useEffect(() => {
    if (!editing) {
      goToColumn(1)
    }
  }, [goToColumn, editing])

  return (
    <ItemColumn>
      <FormAddress {...props} initialData={editing} onCancel={handleCancel} onSubmit={handleSubmit} />
    </ItemColumn>
  )
}
