import React, { useMemo } from 'react'

import { Button, Stack } from '@mui/material'
import { v4 as uuidV4 } from 'uuid'

import { useBudget } from '@feat/budgets/BudgetProvider'

export const BudgetActionButtons: React.FC = () => {
  const { budget } = useBudget()

  const handlePrintClick = () => {
    const id = budget?.budgetId || uuidV4()
    const origin = window.location.origin
    window.open(`${origin}/publico/orcamento/${id}`, '__blank')
  }

  const validBudget = useMemo(() => {
    const { items, customerId } = budget
    return items.length && customerId
  }, [budget])

  return (
    <Stack spacing={2} direction="row" justifyContent="center">
      {budget?.budgetId ? (
        <Button type="button" variant="outlined" onClick={() => {}}>
          DESCARTAR PEDIDO
        </Button>
      ) : null}

      <Button type="button" variant="contained" onClick={handlePrintClick} disabled={!validBudget}>
        IMPRIMIR
      </Button>
      <Button type="button" variant="contained" disabled={!validBudget}>
        SALVAR
      </Button>
    </Stack>
  )
}
