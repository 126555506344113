import React, { useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useResizeDetector } from 'react-resize-detector'

import { Drawer, useMediaQuery } from '@mui/material'

import { round } from '~/helpers'
import { useAppMenu } from '~/hooks/useAppMenu'

import logo from '~/assets/images/logo.png'
import { useAppTheme } from '~/components/AppTheme/useAppTheme'
import { MenuList } from '~/components/MenuList'

import { DrawerContainer, DrawerLogoContainer, NavContainer } from './styles'

type Props = {
  onResize?: (_width?: number) => void
  headerHeight?: number
}

export const Sidebar: React.FC<Props> = ({ onResize, headerHeight }) => {
  const { ref, width } = useResizeDetector({ handleWidth: true, refreshMode: 'debounce', refreshRate: 200 })
  const { menuOpened, toogleMenu } = useAppMenu()
  const { muiTheme } = useAppTheme()
  const matchUpMd = useMediaQuery(muiTheme.breakpoints.up('md'))

  useEffect(() => {
    if (ref.current && onResize) onResize(round(width, 0))
  }, [onResize, width, ref])

  return (
    <NavContainer>
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={menuOpened}
        onClose={toogleMenu}
        ModalProps={{ keepMounted: true }}
      >
        <PerfectScrollbar>
          <DrawerContainer ref={ref} topMargin={headerHeight}>
            <DrawerLogoContainer>
              <img src={logo} alt="" />
            </DrawerLogoContainer>
            <MenuList />
            <br />
          </DrawerContainer>
        </PerfectScrollbar>
      </Drawer>
    </NavContainer>
  )
}
