import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'

import { EditTwoTone } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

import { IUsuarioDto } from '~/services/Api/users/user.dto'

import type { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: inline-block;
  min-width: 78px;
  border: 0;
`

export const ActionsCell: React.FC<ICustomCellProps<IUsuarioDto>> = ({ record }) => {
  const { push } = useHistory()

  const handleEdit = () => {
    push(`/usuarios/${record.id}`)
  }

  if (!record?.id) return null

  return (
    <Content>
      <IconButton color="secondary" onClick={handleEdit}>
        <EditTwoTone fontSize="small" />
      </IconButton>
    </Content>
  )
}

export const ActionsCellMemo = memo(ActionsCell) as typeof ActionsCell
