import React from 'react'

import styled from 'styled-components'

import { Container } from '~/components/_layout/Container'

const ImageMessageWrapper = styled.div`
  border: 0;
  margin: 0 auto;
  padding: 0;
  display: block;
  svg,
  img {
    margin: 0 auto;
    display: block;
    padding: 0;
  }
  p {
    margin: 0 auto;
    display: block;
    padding: 0;
    text-align: center;
  }
`
type Props = {
  message?: string
}
export const PageNoFound: React.FC<Props> = ({ message = 'Página não encontrada' }) => {
  return (
    <div>
      <Container horizontalSpaced>
        <ImageMessageWrapper>
          <p>{message}</p>
        </ImageMessageWrapper>
      </Container>
    </div>
  )
}
