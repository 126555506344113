import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { tostifyErros } from '~/helpers/toastfy'
import { useIsMounted } from '~/hooks/useIsMounted'

import { IEnderecoPostalDto } from '~/services/Api/ctt/ctt.dto'
import { getCustomer, storeCustomer } from '~/services/Api/customers'
import {
  getCustomerAdresses,
  storeCustomerAddress,
  getAddress,
  deleteCustomerAddress,
  IParamsAddressBulk,
  bulkCustomerAddress
} from '~/services/Api/customers/adresses'
import type { IClienteDto, IClienteEndereco } from '~/services/Api/customers/customer.dto'

export type FormDataType = Partial<IClienteDto>

type Props = {
  initialData?: FormDataType
}

export function useCustomerFormData(props: Props = {}) {
  const { initialData = {} } = props
  const isMounted = useIsMounted()
  const [formData, setFormData] = useState<FormDataType>(initialData)
  const [loading, setLoading] = useState(false)

  const request = useCallback(
    async (id: number) => {
      setLoading(true)
      const response = await getCustomer(id)
      if (isMounted.current) {
        setLoading(false)
        if (response.success) {
          setFormData({ ...response.data })
        }
        return !!response?.success
      }
      return null
    },
    [isMounted]
  )

  const submitData = useCallback(
    async (data?: FormDataType) => {
      setLoading(true)
      const response = await storeCustomer(data || formData)
      if (isMounted.current) {
        setLoading(false)
        if (response.success) {
          setFormData({ id: response.customerId })
          toast.success('Cliente cadastrada com sucesso!')
        } else {
          tostifyErros(response.message || 'Houve um erro')
        }
        return response?.success && response?.customerId
      }
      return null
    },
    [isMounted, formData, setFormData]
  )

  return { formData, loading, request, setFormData, submitData }
}

type UseCustomerAdressesProps = {
  customerId?: number
  initialData?: Partial<IClienteEndereco>
  list?: IEnderecoPostalDto[]
}
export function useCustomerAdresses(props: UseCustomerAdressesProps = {}) {
  const { customerId, initialData, list = [] } = props
  const isMounted = useIsMounted()
  const [formData, setFormData] = useState<Partial<IClienteEndereco>>(initialData)
  const [addressList, setAddressList] = useState<IEnderecoPostalDto[]>(list)
  const [loading, setLoading] = useState(false)

  const requestList = useCallback(
    async (id?: number) => {
      setLoading(true)
      if (id || customerId) {
        const response = await getCustomerAdresses(id || customerId)
        if (isMounted.current) {
          setLoading(false)
          if (response.success) {
            setAddressList(response?.data || [])
            return response?.data || []
          }
        }
      }
      return []
    },
    [isMounted, customerId]
  )

  const requestAddress = useCallback(
    async (id: number) => {
      setLoading(true)
      if (customerId && id) {
        const response = await getAddress(customerId, id)
        if (isMounted.current) {
          setLoading(false)
          if (response.success) {
            setFormData({ ...response.data })
            return response?.data
          }
        }
      }
      return null
    },
    [isMounted, customerId]
  )

  const deleteAddress = useCallback(
    async (id: number) => {
      setLoading(true)
      if (customerId && id) {
        const response = await deleteCustomerAddress(customerId, id)
        if (isMounted.current) {
          setLoading(false)
          return !!response?.success
        }
      }
      return false
    },
    [isMounted, customerId]
  )

  const submitData = useCallback(
    async (data?: Partial<IEnderecoPostalDto>) => {
      setLoading(true)
      const response = await storeCustomerAddress(customerId, data || formData)
      if (isMounted.current) {
        setLoading(false)
        if (response.success && response?.idAddress) {
          setFormData({ id: response.idAddress })
          toast.success('Endereço cadastrado com sucesso!')
        } else {
          tostifyErros(response.message || 'Houve um erro')
        }
        return !!response.success
      }
      return null
    },
    [isMounted, formData, setFormData, customerId]
  )

  const submitAddressBulk = useCallback(
    async (params: IParamsAddressBulk, id?: number) => {
      const bulkErrors = await bulkCustomerAddress(id || customerId, params)
      if (bulkErrors?.length) tostifyErros(bulkErrors)
      return !bulkErrors?.length
    },
    [customerId]
  )

  return { formData, loading, requestList, requestAddress, setFormData, submitData, addressList, deleteAddress, submitAddressBulk }
}
