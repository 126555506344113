import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Modal } from '@mui/material'

import { useIsMounted } from '~/hooks/useIsMounted'

import { paginateUsers } from '~/services/Api/users'
import type { IUsuarioDto } from '~/services/Api/users/user.dto'

import { CardTable } from '~/components/CardTable'
import { CircleLoading } from '~/components/CircleLoading'
import { CustomTable } from '~/components/CustomTable'

import { ModalUser } from '@feat/users/ModalUser'

import { columns } from './columns'
import { TableTools } from './TableTools'

const pageSize = 20
export const PaginateUsers: React.FC = () => {
  const { push } = useHistory()
  const isMounted = useIsMounted()
  const [formOpen, setFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState<IUsuarioDto[]>([])
  const [total, setTotal] = useState(1)

  const fetchData = useCallback(
    async (pagination = {}) => {
      setLoading(true)
      const response = await paginateUsers({ ...pagination, size: pageSize })
      if (isMounted.current) {
        setLoading(false)
        if (response && response.success) {
          setRecords(response.data)
          setTotal(response.total || 0)
        }
      }
    },
    [isMounted]
  )

  const handleFormClose = () => setFormOpen(false)
  const handleFormSuccess = () => {
    setFormOpen(false)
    fetchData()
  }
  const handleAdd = useCallback(() => {
    // setFormOpen(true)
    push('/usuarios/0')
  }, [push])

  return (
    <>
      <CardTable widthLimit={900} shadow themeColor="white">
        <CustomTable columns={columns} pageSize={pageSize} total={total} records={records} onPagination={fetchData}>
          <TableTools onAdd={handleAdd} />
        </CustomTable>
        {loading ? <CircleLoading light /> : null}
      </CardTable>
      <Modal open={formOpen} onClose={handleFormClose}>
        <div>
          <ModalUser type="form" onCancel={handleFormClose} onSuccess={handleFormSuccess} />
        </div>
      </Modal>
    </>
  )
}
