import React, { memo, useCallback, useState } from 'react'

import {
  EditTwoTone
  // VisibilityTwoTone
} from '@mui/icons-material'
import { IconButton, Modal } from '@mui/material'
import styled from 'styled-components'

import type { IClienteDto } from '~/services/Api/customers/customer.dto'

import { ModalCustomer, ModalCustomerProps } from '~/components/_features/customers/ModalCustomer'
import { ICustomCellProps, useCustomTable } from '~/components/CustomTable'

const Content = styled.div`
  display: inline-block;
  min-width: 78px;
  border: 0;
`

export const ActionsCell: React.FC<ICustomCellProps<IClienteDto>> = ({ record }) => {
  const { emitFetch } = useCustomTable()
  const [open, setOpen] = useState(false)
  const [modalType, setModalType] = useState<ModalCustomerProps['type']>('view')

  const onSucces = useCallback(() => {
    setOpen(false)
    if (emitFetch) emitFetch()
  }, [emitFetch])

  const handleCancel = () => setOpen(false)

  const openModal = (type: 'form' | 'view') => {
    return () => {
      setModalType(type)
      setOpen(true)
    }
  }

  if (!record?.id) return null

  return (
    <>
      <Content>
        {/* <IconButton color="primary" onClick={openModal('view')}>
          <VisibilityTwoTone fontSize="small" />
        </IconButton> */}
        <IconButton color="secondary" onClick={openModal('form')}>
          <EditTwoTone fontSize="small" />
        </IconButton>
      </Content>
      <Modal open={open} onClose={handleCancel}>
        <div>
          <ModalCustomer type={modalType} customerId={record?.id} onSuccess={onSucces} onCancel={handleCancel} />
        </div>
      </Modal>
    </>
  )
}

export const ActionsCellMemo = memo(ActionsCell) as typeof ActionsCell
