import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'

type Props = {}
export const LogoutButton: React.FC<Props> = () => {
  const { logout } = useAuth0()

  const setClick = () => {
    logout()
  }

  return (
    <Button onClick={setClick} variant="contained">
      SAIR
    </Button>
  )
}
