import React, { useCallback } from 'react'

import { ModalForm } from '~/components/ModalForm'

import { FormSupplier } from './FormSupplier'
import { ViewSupplier } from './ViewSupplier'

export type ModalSupplierProps = {
  type?: 'form' | 'view'
  supplierId?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const ModalSupplier: React.FC<ModalSupplierProps> = ({ type = 'view', supplierId, onCancel, onSuccess }) => {
  const renderTitle = useCallback(() => {
    if (type === 'view') return 'Visualizar fornecedor'
    return `${supplierId ? 'Alterar' : 'Incluir'} fornecedor`
  }, [type, supplierId])

  return (
    <ModalForm key={`${supplierId || 0}`} title={renderTitle()}>
      {type === 'form' ? <FormSupplier onCancel={onCancel} onSuccess={onSuccess} id={supplierId} /> : null}
      {type === 'view' ? <ViewSupplier onCancel={onCancel} onSuccess={onSuccess} id={supplierId} /> : null}
    </ModalForm>
  )
}
