import React, { useCallback } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import { useAppSigned } from '~/hooks/useAppAuth'

import { CircleLoading } from '~/components/CircleLoading'

import { IAppRoute } from './types'

type PrivateRouteProps = IAppRoute & {}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, exact, Page, ...rest }) => {
  const { loading } = useAppSigned()

  const render = useCallback(
    (_props: RouteComponentProps) => {
      // if (!signed) {
      // logoutUser()
      // return <Redirect to={'/login'} />
      // }
      // console.log('RENDER PAGE', path)
      return <Page {...rest} />
    },
    [Page, rest]
  )

  if (loading) return <CircleLoading description="a autenticar rota" light />

  return <Route path={path} exact={!!exact} render={render} />
}
