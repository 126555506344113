import styled from 'styled-components'

/**
 * The z-index values start at an arbitrary number, high and specific enough to ideally avoid conflicts:
 * - mobile stepper: 1000
 * - speed dial: 1050
 * - app bar: 1100
 * - drawer: 1200
 * - modal: 1300
 * - snackbar: 1400
 * - tooltip: 1500
 */
export const HeaderContainer = styled.div`
  /* z-index: 1000; */
  transition: all ease-in-out 0.2s;
`

export const LogoHeaderContainer = styled.div<{ heightLimit?: number; sidebarWidth?: number; opened?: boolean }>`
  display: block;
  padding: 0px ${({ theme }) => theme.spacing.l}px;
  height: ${({ heightLimit = 0 }) => heightLimit}px;
  max-height: 44px;
  width: auto;
  transition: all ease-in-out 0.2s;
  img,
  svg {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    transition: all ease-in-out 0.2s;
  }
  @media (max-width: 600px) {
    display: none;
    transition: all ease-in-out 0.2s;
  }

  @media (min-width: 600px) {
    /* width: ${({ sidebarWidth }) => (sidebarWidth ? `${sidebarWidth}px` : 'auto')}; */
    width: 280px;
  }
`
