import styled from 'styled-components'

import { FlexAlign, FlexJustify } from '~/styles/commonProps'

export const FlexItem = styled.div``

export const FlexContainer = styled.div<{ justify?: FlexJustify; align?: FlexAlign }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${({ justify = 'space-between' }) => justify};
  align-items: ${({ align = 'flex-start' }) => align};
  align-content: ${({ align = 'flex-start' }) => align};
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  gap: ${({ theme }) => theme.spacing.xl}px;
`
