import React from 'react'

import { useAppShop } from '~/hooks/useAppShop'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'
import { PageNoFound } from '~/pages/NoFound'

import { PaginateStockShop } from '@feat/shops/PaginateStockShop'

export const PageStockShop: React.FC = () => {
  const { currentShop } = useAppShop()

  return (
    <PageContainer>
      {currentShop ? (
        <>
          <PageTitle spotlight="Estoque" title={`de ${currentShop?.name}`} />
          <PaginateStockShop shopId={currentShop?.id} />
        </>
      ) : (
        <PageNoFound message={'Nenhuma loja selecionada'} />
      )}
    </PageContainer>
  )
}
