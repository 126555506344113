import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0()

  const setClick = () => {
    return loginWithRedirect()
  }

  // console.log(`${window.location.origin}/login`)

  return (
    <Button onClick={setClick} variant="outlined">
      Entrar
    </Button>
  )
}
