import styled from 'styled-components'

export const SelectBarItem = styled.div`
  flex: 1;
`
export const SelectBarIcon = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 44px;
`

export const SelectBar = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: ${({ theme }) => theme.spacing.m}px;
  padding-top: ${({ theme }) => theme.spacing.m}px;
`

export const ItemChips = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  align-content: center;
  gap: ${({ theme }) => theme.spacing.l}px;
`

export const ItemShopName = styled.div``

export const ItemPermissionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: ${({ theme }) => theme.spacing.l}px;
  gap: ${({ theme }) => theme.spacing.l}px;
`
