import type { IThemeSpacing, IAppTheme } from './types'

const rounded = 5
const spacing: IThemeSpacing = {
  xs: 2,
  s: 4,
  m: 6,
  l: 10,
  xl: 14
}

export const defaultTheme: IAppTheme = {
  colors: {
    primary: '#0F0846',
    secondary: '#3EAEFF',
    contrast: '#F4CC29',
    text: '#505050',
    white: '#FFFFFF',
    black: '#000000',
    border: '#ccc',
    shadow: '#C3C3C3',
    textDark: '#808080',
    background: '#FAFBFD',
    success: '#66BB6A',
    errors: '#FC544B',
    warning: '#FFA426',
    info: '#3ABAF4'
  },
  spacing,
  rounded,
  borderWidth: 1
}
