import React, { useCallback, useState } from 'react'

import { Tab, Tabs } from '@mui/material'
import styled from 'styled-components'

import { ModalForm } from '~/components/ModalForm'

import { FormUser } from './FormUser'
import { FormUserRoles } from './FormUserRoles'
import { ViewUser } from './ViewUser'

export type ModalUserProps = {
  type?: 'form' | 'view'
  defaultTab?: 0 | 1 | number
  userId?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const ModalUser: React.FC<ModalUserProps> = ({ defaultTab = 0, type = 'view', userId, onCancel, onSuccess }) => {
  const [tabIndex, setTabIndex] = useState(defaultTab)

  const handleChangeIndex = (index: number) => setTabIndex(index)

  const renderTitle = useCallback(() => {
    if (type === 'view') return 'Visualizar usuário'
    return `${userId ? 'Alterar' : 'Incluir'} usuário`
  }, [type, userId])

  return (
    <ModalForm key={`${userId || 0}`} title={renderTitle()}>
      <Tabs onChange={(_e, i) => handleChangeIndex(i)} value={tabIndex} indicatorColor="primary" variant="fullWidth">
        <Tab label="Informações" />
        <Tab label="Permissões" disabled={!userId} />
      </Tabs>
      <TabPanel index={tabIndex} value={0}>
        {type === 'form' ? <FormUser onCancel={onCancel} onSuccess={onSuccess} id={userId} /> : null}
        {type === 'view' ? <ViewUser onCancel={onCancel} onSuccess={onSuccess} id={userId} /> : null}
      </TabPanel>
      <TabPanel index={tabIndex} value={1}>
        <FormUserRoles onCancel={onCancel} id={userId} />
      </TabPanel>
    </ModalForm>
  )
}

const PanelContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.l}px;
  transition: all ease-in-out 0.3s;
`

interface TabPanelProps {
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <PanelContainer role="tabpanel" hidden={value !== index} {...other}>
      {children}
    </PanelContainer>
  )
}
