import { lighten } from '@mui/system'
import styled from 'styled-components'

type Props = {
  textColor?: string
  textSize?: number
}
export const ItemIcon = styled.div<Props>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: ${({ textColor = 'inherit' }) => textColor};
  padding-right: ${({ theme }) => theme.spacing.l}px;

  svg {
    max-width: ${({ textSize = 14 }) => textSize}px;
  }
`

export const ItemTitle = styled.span<Props>`
  margin: 0;
  padding: 0;
  font-size: ${({ textSize = 14 }) => textSize}px;
  font-weight: bold;
  color: ${({ textColor = 'inherit' }) => textColor};
`

export const ItemDescription = styled.span<Props>`
  margin: 0;
  padding: 0;
  font-size: ${({ textSize = 12 }) => textSize}px;
  color: ${({ textColor = 'inherit' }) => lighten(textColor, 0.3)};
`
