import type { IResponsePaginate, Pagination } from '~/services/Api'

import { SupllierMock } from './mocks'
import type { IFornecedorDto, IResponseFornecedor } from './supplier.dto'

export async function paginateSuppliers(pagination: Pagination<IFornecedorDto> = {}): Promise<IResponsePaginate<IFornecedorDto>> {
  const paginated = await SupllierMock.paginate(pagination)
  return paginated
}

export async function getSupplier(customerId: number): Promise<IResponseFornecedor> {
  // return Api.getDefault<IResponseCustomer>(`/customer/${customerId}`)
  const data = await SupllierMock.findOne(customerId)
  return {
    success: true,
    data
  }
}

export async function storeSupplier({ id, ...payload }: Partial<IFornecedorDto>): Promise<IResponseFornecedor> {
  if (id) {
    // return Api.patchDefault(`/customer/${id}`, payload)
    const response = await SupllierMock.update(id, payload)
    return {
      success: true,
      id: response.id
    }
  }

  return {
    success: true,
    data: await SupllierMock.add(payload as IFornecedorDto)
  }
  // return Api.postDefault('/customer', payload)
}
