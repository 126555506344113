import React, { useEffect, useRef } from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { useField } from '@unform/core'
import styled from 'styled-components'

import { propertyWarning } from '~/components/UnForm/debug'

const Helper = styled.span`
  color: ${({ theme }) => theme.colors.errors};
  font-size: 10px;
  padding: 0;
  display: block;
  position: relative;
  margin: -5px;
`

export type InputTextProps = TextFieldProps & {
  id?: string
  name: string
}

export const InputText: React.FC<InputTextProps> = ({ name, type = 'text', id, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null)

  if (!name) propertyWarning('InputText', 'name')

  const { defaultValue, fieldName, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref,
      getValue(ref) {
        return ref?.current?.value
      }
    })
  }, [registerField, fieldName])

  return (
    <TextField
      type={type}
      inputProps={{ id }}
      name={name}
      defaultValue={defaultValue}
      inputRef={ref}
      helperText={<Helper>{error}</Helper>}
      {...rest}
    />
  )
}
