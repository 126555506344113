import type { IShopStock } from '~/services/Api/shops/stock'

import type { IColumnTable } from '~/components/CustomTable'

import { ProductCell } from './ProductCell'
import { StockCell } from './StockCell'
import { StockCentralCell } from './StockCentralCell'

export const columns: IColumnTable<IShopStock>[] = [
  { name: 'name', label: 'Produto', align: 'left', Cell: ProductCell },
  { label: 'Loja', align: 'center', width: 100, Cell: StockCell },
  { label: 'Central', align: 'center', width: 100, Cell: StockCentralCell },

  { label: '', width: 110 }
]
