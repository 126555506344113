import React, { useCallback, useMemo, useState } from 'react'

import { createContext, useContextSelector, useContext } from 'use-context-selector'
import { v4 as uuidV4 } from 'uuid'

import { IBudgetData, IBudgetItem } from '~/store/reducers/budget'

export type { IBudgetData, IBudgetItem }

type ContextDispatch<T> = React.Dispatch<React.SetStateAction<T>>
export interface IBudgetContext {
  currentProduct?: number
  setCurrentProduct?: ContextDispatch<number>
  //
  budget?: IBudgetData
  setBudget?: ContextDispatch<IBudgetData>
  updateBudget?: (data: Partial<IBudgetData>) => void
}

export const BudgetContext = createContext({} as IBudgetContext)

type Props = {
  initialData?: IBudgetData
}
export const BudgetProvider: React.FC<Props> = ({ children, initialData }) => {
  const [currentProduct, setCurrentProduct] = useState(0)
  const [budget, setBudget] = useState<IBudgetData>(initialData)

  const updateBudget = useCallback(
    (data: Partial<IBudgetData>) => {
      setBudget(old => ({ ...old, ...data }))
    },
    [setBudget]
  )

  return <BudgetContext.Provider value={{ currentProduct, setCurrentProduct, budget, setBudget, updateBudget }}>{children}</BudgetContext.Provider>
}

export function useBudget() {
  return useContext(BudgetContext)
}

type RemomeParams = {
  id?: string | number
  productId?: number
}
export function useBudgetItems() {
  const items = useContextSelector(BudgetContext, ({ budget }) => budget?.items || [])
  const updateBudget = useContextSelector(BudgetContext, context => context.updateBudget)

  const addItem = useCallback(
    ({ productId, ...data }: IBudgetItem) => {
      const found = items.find(f => f.productId === productId)

      const newItems = found
        ? items.map(item => {
            return item.productId !== productId ? item : { ...found, ...data, deleted: false }
          })
        : ([...items, { id: uuidV4(), productId, ...data }] as IBudgetItem[])

      updateBudget({ items: newItems })
    },
    [updateBudget, items]
  )

  const removeItem = useCallback(
    ({ id, productId }: RemomeParams) => {
      const newItems = items
        .map(item => {
          if (id && item?.id === id) return typeof id === 'number' ? { ...item, deleted: true } : null
          if (productId && item.productId === productId) return null
          return item
        })
        .filter(f => !!f)

      updateBudget({ items: newItems })
    },
    [updateBudget, items]
  )

  const updateItem = useCallback(
    (id: string | number, data: Partial<IBudgetItem>) => {
      const newItems = items.map(item => {
        return id && item?.id === id ? { ...item, ...data } : item
      })
      updateBudget({ items: newItems })
    },
    [updateBudget, items]
  )

  const totalItems = useMemo(() => {
    return items.reduce((total, { price, quantity }) => {
      total += price * quantity
      return total
    }, 0)
  }, [items])

  return { items, addItem, removeItem, totalItems, updateItem }
}

export function useBudgetCustomer() {
  const customerId = useContextSelector(BudgetContext, ({ budget }) => budget?.customerId)
  const updateBudget = useContextSelector(BudgetContext, context => context.updateBudget)

  const setBudgetCustomer = useCallback(
    (id?: number) => {
      updateBudget({ customerId: id })
    },
    [updateBudget]
  )

  return { customerId, setBudgetCustomer }
}
