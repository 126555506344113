import styled from 'styled-components'

import { VariantColorsTypes } from '~/components/AppTheme/types'
import { TextAlign } from '~/styles/commonProps'

export const PageContainer = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.xl}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  hr {
    margin: 0 auto;
    height: 1px;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.spacing.xl}px;
  }
`

export const Text = styled.p<{ align?: TextAlign; color?: VariantColorsTypes; size?: number; height?: number }>`
  padding: 0;
  margin: 0;
  text-align: ${({ align = 'left' }) => align};
  color: ${({ theme, color = 'text' }) => theme.colors[color]};
  font-size: ${({ size = 'inherit' }) => size}px;
  line-height: ${({ height = 'inherit' }) => height}px;
`

export const Span = styled.span<{ align?: TextAlign; color?: VariantColorsTypes; size?: number; height?: number }>`
  padding: 0;
  margin: 0;
  text-align: ${({ align = 'left' }) => align};
  color: ${({ theme, color = 'text' }) => theme.colors[color]};
  font-size: ${({ size = 'inherit' }) => size}px;
  line-height: ${({ height = 'inherit' }) => height}px;
`

export const SpacedContainer = styled.div`
  position: relative;
  display: block;
  padding: ${({ theme }) => theme.spacing.l}px;
  min-height: 72px;
`
