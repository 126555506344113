import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from '~/store'
import { setMenuOpen, AppState } from '~/store/reducers/app'

export function useAppMenu() {
  const menuOpened = useSelector<ApplicationState, AppState['menuOpened']>(state => state.app.menuOpened)
  const dispatch = useDispatch()

  const setMenuOpened = useCallback(
    (payload?: boolean) => {
      dispatch(setMenuOpen(!!payload))
    },
    [dispatch]
  )

  const toogleMenu = useCallback(() => {
    dispatch(setMenuOpen(!menuOpened))
  }, [menuOpened, dispatch])
  return { menuOpened, setMenuOpened, toogleMenu }
}
