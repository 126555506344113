import React, { useCallback, useMemo, useState } from 'react'

import { euroPrice } from '~/helpers'
import { rangeArray } from '~/helpers/array'

import type { IProdutoDto } from '~/services/Api/products'

import { useBudget } from '~/components/_features/budgets/BudgetProvider'
import { SelectSimple, SimpleOption, HandleSimpleSelected } from '~/components/SelectSimple'
import { Span, Text } from '~/components/styled'

import { TissueSample, ITissueOption, HandleColorClick } from '@feat/products/TissueSample'

import { FlexContainer, FlexItem } from '../styles'

const mockInstallments: SimpleOption[] = rangeArray(1, 24).map(num => {
  return {
    id: num,
    label: `${num}x`
  }
})

type Props = {
  productId: number
  measureId: number
  tissueId?: number
  price?: number
  relatedList?: IProdutoDto[]
}
export const ProductRelated: React.FC<Props> = ({ productId, measureId, price = 0, relatedList = [] }) => {
  const { setCurrentProduct } = useBudget()
  const [intsallmentSelected, setInstallmentSelected] = useState(1)

  const total = useMemo(() => {
    const feeMock = (i: number) => (i > 12 ? 0.07 : 0)
    const fee = price * feeMock(intsallmentSelected)
    return { installment: (price + fee) / intsallmentSelected, fee }
  }, [price, intsallmentSelected])

  const handleMeasureChange: HandleSimpleSelected = useCallback(
    selectedId => {
      if (selectedId && selectedId !== productId) setCurrentProduct(selectedId)
    },
    [setCurrentProduct, productId]
  )

  const handleColorChange: HandleColorClick = useCallback(
    tissueSelected => {
      if (tissueSelected && tissueSelected?.productId !== productId) setCurrentProduct(tissueSelected.productId)
    },
    [setCurrentProduct, productId]
  )

  const handleInstallmentChange: HandleSimpleSelected = useCallback(selectedId => {
    setInstallmentSelected(selectedId)
  }, [])

  const measures = useMemo(() => {
    return relatedList.reduce((m, { id, measure }) => {
      const isDuplicated = (arr: SimpleOption[], l: string) => !!arr.find(f => f.label === l)
      if (measure) {
        const label = isDuplicated(m, measure?.name) ? `${measure?.name} ${id}` : measure?.name
        m.push({ id, label })
      }
      return m
    }, [] as SimpleOption[])
  }, [relatedList])

  const tissues = useMemo(() => {
    return relatedList.reduce((m, { id, tissue, ...product }) => {
      const isDuplicated = (arr: ITissueOption[], c: number) => !!arr.find(f => f.id === c)
      if (tissue && measureId && product?.measureId === measureId) {
        if (!isDuplicated(m, id)) m.push({ id, color: tissue?.color, name: tissue?.name, productId: id })
      }
      return m
    }, [] as ITissueOption[])
  }, [relatedList, measureId])

  return (
    <FlexContainer align="center">
      <FlexItem>
        <FlexItem>
          <Span size={12} color="primary">
            medidas:
          </Span>
          <SelectSimple name="measures" options={measures} defaultValue={productId} onSelect={handleMeasureChange} />
        </FlexItem>
        <FlexItem>
          <Span size={12} color="primary">
            tecidos disponíveis:
            <TissueSample key={`sample-${productId}`} options={tissues} onSelect={handleColorChange} />
          </Span>
        </FlexItem>
      </FlexItem>
      <FlexItem>
        <Text color="primary">{euroPrice(price)}</Text>
        <Span>parcelas:</Span>
        <SelectSimple name="installments" options={mockInstallments} defaultValue={1} onSelect={handleInstallmentChange} />

        <Text align="center" size={12}>
          {intsallmentSelected > 1 ? (
            <>
              {intsallmentSelected}x de{' '}
              <Span size={14} color="primary">
                {euroPrice(total?.installment)}
              </Span>
            </>
          ) : (
            ' '
          )}
        </Text>
      </FlexItem>
    </FlexContainer>
  )
}
