import React from 'react'

import { useAppMenu } from '~/hooks/useAppMenu'

import { MainLayoutContent } from './styles'

type Props = {
  sidebarWidth?: number
  headerHeight?: number
  footerHeight?: number
}
export const Content: React.FC<Props> = ({ children, sidebarWidth, headerHeight }) => {
  const { menuOpened } = useAppMenu()
  const left = menuOpened ? sidebarWidth || 0 : 0

  return (
    <MainLayoutContent leftMargin={left} topMargin={headerHeight}>
      {children}
    </MainLayoutContent>
  )
}
