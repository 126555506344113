import React from 'react'

import { adminGroups } from '~/components/MenuList/items/admin'
import { inventoryGroups } from '~/components/MenuList/items/inventory'
import { reportGroups } from '~/components/MenuList/items/report'
import { sellerGroups } from '~/components/MenuList/items/seller'
import NavGroup from '~/components/MenuList/NavGroup'

export const MenuList: React.FC = () => {
  return (
    <>
      {reportGroups.map(item => {
        return <NavGroup key={item.id} {...item} />
      })}

      {sellerGroups.map(item => {
        return <NavGroup key={item.id} {...item} />
      })}

      {inventoryGroups.map(item => {
        return <NavGroup key={item.id} {...item} />
      })}

      {adminGroups.map(item => {
        return <NavGroup key={item.id} {...item} />
      })}
    </>
  )
}
