import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Card, Grid, CardContent, CardHeader } from '@mui/material'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import { FormProduct } from '@feat/products/FormProduct'

interface PageProductParams {
  productId?: string
}

export const PageProductStore: React.FC = () => {
  const { productId } = useParams<PageProductParams>()
  const { goBack } = useHistory()
  const id = parseInt(productId, 10) || 0

  const pageTitle = id ? 'Alterar' : 'Incluir'

  const handleFormBack = () => {
    goBack()
  }

  return (
    <PageContainer>
      <PageTitle back spotlight={pageTitle} title="produto" />
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Informações do produto" />
            <CardContent>
              <FormProduct id={id} onBack={handleFormBack} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
