import React, { useEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import styled from 'styled-components'

const PanelContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.l}px;
`

interface TabPanelProps {
  index: number
  value: number
  onResize?: (w?: number, h?: number) => void
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, onResize, ...other }) => {
  const { ref, height, width } = useResizeDetector({ handleHeight: true, handleWidth: true, refreshMode: 'debounce', refreshRate: 250 })

  useEffect(() => {
    if (onResize) onResize(height, width)
  }, [height, width, onResize])

  return (
    <PanelContainer ref={ref} role="tabpanel" hidden={value !== index} {...other}>
      {children}
    </PanelContainer>
  )
}
