import Api from '../index'
import type { IResponseDistritos, IResponseConcelhos, IResponsePostalAdresses, IDistritoDto, IConcelhoDto, IEnderecoPostalDto } from './ctt.dto'

export async function getAllDistricts(): Promise<IResponseDistritos> {
  const response = await Api.getDefault<IDistritoDto[]>('/ctt/distritos')
  return {
    success: !!response?.success,
    data: (response && response?.data) || [],
    message: response?.error?.message
  }
}

export async function getAllCities(codigoDistrito: string): Promise<IResponseConcelhos> {
  const response = await Api.getDefault<IConcelhoDto[]>(`/ctt/distritos/${codigoDistrito}/concelhos/`)
  return {
    success: true,
    data: (response && response?.data) || [],
    message: response?.error?.message
  }
}

export async function findPostal(codigoPostal: string): Promise<IResponsePostalAdresses> {
  const response = await Api.getDefault<IEnderecoPostalDto[]>(`/ctt/codigoPostal/${codigoPostal}`)
  return {
    success: true,
    data: (response && response?.data) || [],
    message: response?.error?.message
  }
}
