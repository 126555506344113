import { useCallback, useState } from 'react'

import { useIsMounted } from '~/hooks/useIsMounted'

import { findPostal } from '~/services/Api/ctt'
import { IEnderecoPostalDto } from '~/services/Api/ctt/ctt.dto'

export interface IFoundAddrList {
  searchText?: string
  data: IEnderecoPostalDto[]
}

export function useCttSearch() {
  const isMounted = useIsMounted()
  const [foundAdresses, setFoundAdresses] = useState<IFoundAddrList>({ data: [] })
  const [searching, setSearching] = useState(false)

  const updateFoundList = useCallback((searchText, data) => {
    setFoundAdresses({ searchText, data })
  }, [])

  const searchPostalCode = useCallback(
    async (searchText?: string) => {
      if (searchText) {
        setSearching(true)
        const response = await findPostal(searchText)
        if (isMounted.current) {
          setSearching(false)
          if (response?.success) {
            updateFoundList(searchText, response?.data || [])
            return { searchText, data: response?.data || [] } as IFoundAddrList
          }
        }
      }
      return null
    },
    [updateFoundList, isMounted]
  )

  const clearSearchPostal = useCallback(() => {
    updateFoundList('', [])
  }, [updateFoundList])

  return { searching, foundAdresses, searchPostalCode, clearSearchPostal }
}
