import { PageBudgetStore } from '~/pages/budgets/PageBudgetStore'
import { PageCustomers } from '~/pages/customers/PageCustomers'
import { PageInventory } from '~/pages/inventory/PageInventory'
import { PageAccount } from '~/pages/PageAccount'
import { PageHome } from '~/pages/PageHome'
import { PageProducts } from '~/pages/products/PageProducts'
import { PageProductStore } from '~/pages/products/PageProductStore'
import { PageBudge } from '~/pages/purchases/PageBudge'
import { PageEditPurchase } from '~/pages/purchases/PageEditPurchase'
import { PagePayment } from '~/pages/purchases/PagePayment'
import { PagePurchases } from '~/pages/purchases/PagePurchases'
import { PressBudge } from '~/pages/purchases/PressBudge'
import { PageShops } from '~/pages/shops/PageShops'
import { PageStockShop } from '~/pages/shops/PageStockShop'
import { PageSuppliers } from '~/pages/suppliers/PageSuppliers'
import { PageUsers } from '~/pages/users/PageUsers'
import { PageUserStore } from '~/pages/users/PageUserStore'

import type { IAppRoute } from './types'

const privateRouteList: Partial<IAppRoute>[] = [
  { path: '/', Page: PageHome, label: 'Sumário' },
  { path: '/home', Page: PageHome, label: 'Sumário' },
  { path: '/armazem', Page: PageInventory, label: 'Armazem' },
  { path: '/criar-orcamento', Page: PageBudge, label: 'Criar Orçamento' },
  { path: '/imprimir-orcamento', Page: PressBudge, label: 'Imprimir Orçamento' },
  { path: '/pedidos', Page: PagePurchases, label: 'Pedidos' },
  { path: '/orcamentos/:budgetId', Page: PageBudgetStore, label: 'Pedidos' },
  { path: '/criar-orcamento/pagamento', Page: PagePayment, label: 'Pagamento' },
  { path: '/pedidos/edit', Page: PageEditPurchase, label: 'Editar Pedido' },
  { path: '/clientes', Page: PageCustomers, label: 'Clientes' },
  { path: '/lojas', Page: PageShops, label: 'Lojas' },
  { path: '/loja/estoque', Page: PageStockShop, label: 'Estoque da Loja' },
  { path: '/fornecedores', Page: PageSuppliers, label: 'Fornecedores' },
  { path: '/produtos', Page: PageProducts, label: 'Produtos' },
  { path: '/produtos/:productId', Page: PageProductStore, label: 'Produtos' },
  { path: '/usuarios', Page: PageUsers, label: 'Usuários' },
  { path: '/usuarios/:userId', Page: PageUserStore, label: 'Usuários' },
  { path: '/account', Page: PageAccount, label: 'Minhas informações' }
]

export const privateRoutes: IAppRoute[] = privateRouteList.map(route => {
  return { ...route, exact: true } as IAppRoute
})
