import React from 'react'

import { Home } from '@mui/icons-material'
import { Chip, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import styled from 'styled-components'

const PermissionsContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0px;
  gap: ${({ theme }) => theme.spacing.l}px;
  p {
    font-size: 14px;
  }
`

export type ShopItemType = {
  id: number
  name: string
  roles?: string[]
}

type Props = {
  onClick?: () => void
  shop: ShopItemType
}

export const ShopUserItem: React.FC<Props> = ({ onClick, shop }) => {
  const renderRoles = (shopItem: ShopItemType) => {
    return (
      <PermissionsContainer>
        {shopItem.roles.map(role => {
          return <Chip component={'span'} key={`${role}`} label={role} size="small" />
        })}
        <span>Trocar de loja</span>
      </PermissionsContainer>
    )
  }

  return (
    <ListItemButton divider onClick={onClick}>
      <ListItemIcon>
        <Home color="primary" />
      </ListItemIcon>

      <ListItemText primary={shop?.name} secondary={renderRoles(shop)} />
    </ListItemButton>
  )
}
