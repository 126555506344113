import styled from 'styled-components'

export const MinimalBox = styled.div`
  max-width: 100%;
  width: 380px;
  border: 0;
`

export const MinimalContainer = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 0;
`
