import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { tostifyErros } from '~/helpers/toastfy'
import { useAppAuth } from '~/hooks/useAppAuth'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer, Text } from '~/components/styled'

export const PageLogout: React.FC = () => {
  const { replace: navigate } = useHistory()
  const ref = useRef<HTMLDivElement>(null)
  const [message, setMessage] = useState('')
  const { updateAuth, error, logoutUser } = useAppAuth()

  useEffect(() => {
    if (ref?.current) {
      if (error?.message) {
        setMessage(error.message)
        updateAuth({ error: null })
        tostifyErros(error.message)
      }
      setTimeout(() => {
        navigate('/login')
        // logoutUser({ localOnly: true })
        logoutUser()
      }, 3000)
    }
  }, [error, updateAuth, navigate, logoutUser])

  return (
    <PageContainer ref={ref}>
      <PageTitle spotlight="Usuário" title="não autorizado" center />
      {message ? (
        <>
          <hr />
          <Text align="center" color="errors">
            {message}
          </Text>
        </>
      ) : null}
    </PageContainer>
  )
}
