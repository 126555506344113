import React from 'react'

import { AddressManagerProvider, IAddressManagerContext } from './Provider'
import { Steps } from './Steps'

export type AddressManagerProps = {
  initialList?: IAddressManagerContext['list']
  onSave?: IAddressManagerContext['onSave']
  onUndo?: IAddressManagerContext['onUndo']
  height?: IAddressManagerContext['height']
}
export const AddressManager: React.FC<AddressManagerProps> = ({ initialList, onSave, onUndo, height }) => {
  return (
    <AddressManagerProvider key={`${initialList.length}`} initialList={initialList} onSave={onSave} onUndo={onUndo} height={height}>
      <Steps />
    </AddressManagerProvider>
  )
}
