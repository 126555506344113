import React from 'react'

import { MinimalContainer, MinimalBox } from './styles'

export const MinimalLayout: React.FC = ({ children }) => {
  return (
    <MinimalContainer>
      <MinimalBox>{children}</MinimalBox>
    </MinimalContainer>
  )
}
