import React, { useCallback } from 'react'

import { ModalForm } from '~/components/ModalForm'

import { FormShop } from './FormShop'
import { ViewShop } from './ViewShop'

export type ModalShopProps = {
  type?: 'form' | 'view'
  shopId?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const ModalShop: React.FC<ModalShopProps> = ({ type = 'view', shopId, onCancel, onSuccess }) => {
  const renderTitle = useCallback(() => {
    if (type === 'view') return 'Visualizar loja'
    return `${shopId ? 'Alterar' : 'Incluir'} loja`
  }, [type, shopId])

  return (
    <ModalForm key={`${shopId || 0}`} title={renderTitle()}>
      {type === 'form' ? <FormShop onCancel={onCancel} onSuccess={onSuccess} id={shopId} /> : null}
      {type === 'view' ? <ViewShop onCancel={onCancel} onSuccess={onSuccess} id={shopId} /> : null}
    </ModalForm>
  )
}
