import React, { useEffect, useState } from 'react'

import { Inbox, DateRange, Remove, Add, AddShoppingCart, LocalGroceryStore, Delete, Person, MonetizationOn, ArrowForward } from '@mui/icons-material'
import {
  Paper,
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Alert
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { v4 as uuidv4 } from 'uuid'

import { getProducts } from '~/services/Api/products'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

import styles from './styles/PageBudge'

export const PageBudge: React.FC = () => {
  const classes = styles()
  const [nome, setNome] = useState('')
  const [item, setItem] = useState([])
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [medidas, setMedidas] = useState([])
  const [novaMedida, setNovaMedida] = useState('')
  const [parcelas, setParcelas] = useState('1')
  const [quantidade, setQuantidade] = useState('1')
  const [valorFinal, setValorFinal] = useState('')
  const [valorParceladoItem, setValorParceladoItem] = useState('')
  const [produtos, setProdutos] = useState([])
  const [total, setTotal] = useState(0)
  const [parcelasTotal, setParcelasTotal] = useState(0)
  const [qtdeParcelasTotal, setQtdeParcelasTotal] = useState('')
  const [atualizaQtdeCarrinho, setAtualizaQtdeCarrinho] = useState(false)
  const [erroPrecoMenor, setErroPrecoMenor] = useState(false)
  const loading = open && options.length === 0
  const entrega = ['Loja', 'Central']

  useEffect(() => {
    if (!loading) {
      return undefined
    }

    if (nome.length > 1) {
      ;(async () => {
        const data = (await getProducts(nome)) as any[]
        setOptions(data || [])
      })()
    }
  }, [nome, loading])

  useEffect(() => {
    setValorFinal(String(Number(item['precoVenda']) * Number(quantidade)))
    setValorParceladoItem(String(((item['precoVenda'] / Number(parcelas)) * Number(quantidade)).toFixed(2)))
  }, [quantidade, item, parcelas])

  useEffect(() => {
    // ;(async () => {
    //   const data = await getProduct(item['id']))
    //   await data.map(dataInsert => {
    //     if (dataInsert['medida'] === novaMedida) {
    //       setItem(dataInsert)
    //       setValorFinal(String(Number(dataInsert['precoVenda']) * Number(quantidade)))
    //       setValorParceladoItem(String(((dataInsert['precoVenda'] / Number(parcelas)) * Number(quantidade)).toFixed(2)))
    //       return
    //     }
    //   })
    // })()
  }, [novaMedida, item, parcelas, quantidade])

  useEffect(() => {
    if (atualizaQtdeCarrinho) {
      setAtualizaQtdeCarrinho(false)
    }
  }, [atualizaQtdeCarrinho])

  const handleSelecionaItem = async option => {
    setNovaMedida(option['medida'])
    const data = (await getProducts(option['nomeComercial'])) as any[]
    var insereMedida = []
    await data.map(medida => {
      insereMedida.push(medida['medida'])
    })
    setValorParceladoItem(String(option['precoVenda'] * Number(quantidade)))
    setValorFinal(option['precoVenda'])
    setMedidas(insereMedida)
    setItem(option)
    setNome('')
    setOptions([])
    setOpen(false)
  }

  const handleSelectMedida = async (event: SelectChangeEvent) => {
    setNovaMedida(event.target.value as string)
  }

  const handleSelectParcelas = (event: SelectChangeEvent) => {
    const valor = Number(event.target.value as string)
    setParcelas(event.target.value as string)
    setValorFinal(String(Number(item['precoVenda']) * Number(quantidade)))
    setValorParceladoItem(String(((item['precoVenda'] / valor) * Number(quantidade)).toFixed(2)))
  }

  const handleAdicionar = () => {
    setQuantidade(String(Number(quantidade) + 1))
  }

  const handleRemove = () => {
    if (Number(quantidade) > 1) {
      setQuantidade(String(Number(quantidade) - 1))
    }
  }

  const adicionarCarrinho = async () => {
    var meusProdutos = produtos
    meusProdutos.push({
      id: uuidv4(),
      produto: item,
      quantidade: quantidade
    })
    setProdutos(meusProdutos)
    var totalAdicionar = total + Number(item['precoVenda']) * Number(quantidade)
    setTotal(totalAdicionar)
    await setQtdeParcelasTotal(parcelas)
    const valorParcelado = (totalAdicionar / Number(parcelas)).toFixed(2)
    setParcelasTotal(Number(valorParcelado))
    setItem([])
    setMedidas([])
    setNovaMedida('')
    setParcelas('1')
    setQuantidade('1')
    setValorFinal('')
    setValorParceladoItem('')
  }

  const handleRemoverItem = id => {
    var produtosNovos = produtos.filter(item => item.id !== id)
    setProdutos(produtosNovos)
    var totalProdutos = 0
    produtosNovos.map(produto => {
      totalProdutos = totalProdutos + Number(produto.produto['precoVenda']) * Number(produto.quantidade)
    })
    setTotal(totalProdutos)
    const valorParcelado = (totalProdutos / Number(parcelas)).toFixed(2)
    setParcelasTotal(Number(valorParcelado))
  }

  const handleParcelasTotal = async (event: SelectChangeEvent) => {
    const valor = Number(event.target.value as string)
    setQtdeParcelasTotal(String(valor))
    setParcelasTotal(Number((Number(total) / Number(valor)).toFixed(2)))
  }

  const handleRemoveTabela = id => {
    var indexAlterado = produtos.findIndex(item => item.id === id)
    if (Number(produtos[indexAlterado].quantidade) > 1) {
      var novaQuantidae = String(Number(produtos[indexAlterado].quantidade) - 1)
      produtos[indexAlterado].quantidade = novaQuantidae
      var valorTotal = total - Number(produtos[indexAlterado].produto['precoVenda'])
      const valorParcelado = (valorTotal / Number(qtdeParcelasTotal)).toFixed(2)
      setParcelasTotal(Number(valorParcelado))
      setTotal(Number(valorTotal))
      setProdutos(produtos)
      setAtualizaQtdeCarrinho(true)
    }
  }

  const handleAdicionaTabela = id => {
    var indexAlterado = produtos.findIndex(item => item.id === id)
    var novaQuantidae = String(Number(produtos[indexAlterado].quantidade) + 1)
    produtos[indexAlterado].quantidade = novaQuantidae
    var valorTotal = total + Number(produtos[indexAlterado].produto['precoVenda'])
    const valorParcelado = (valorTotal / Number(qtdeParcelasTotal)).toFixed(2)
    setParcelasTotal(Number(valorParcelado))
    setTotal(Number(valorTotal))
    setProdutos(produtos)
    setAtualizaQtdeCarrinho(true)
  }

  const setNewValue = (valor, id) => {
    var indexAlterado = produtos.findIndex(item => item.id === id)
    var valorOld = erroPrecoMenor === false ? produtos[indexAlterado].produto['precoVenda'] : valorOld
    produtos[indexAlterado].produto['precoVenda'] = valor
    setProdutos(produtos)
    setAtualizaQtdeCarrinho(true)
    if ((Number(valorOld) - Number(valorOld) * 0.1) * 0.1 < Number(valor)) {
      setErroPrecoMenor(true)
      setAtualizaQtdeCarrinho(true)
    } else {
      setErroPrecoMenor(false)
      setAtualizaQtdeCarrinho(true)
    }
  }

  const letForPayment = () => {}

  return (
    <PageContainer>
      <PageTitle spotlight="Orçamento" title="criar" />
      <Box className={classes.boxTopArea}>
        <Autocomplete
          id="buscaProduto"
          className={classes.searchItem}
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          isOptionEqualToValue={(option, value) => option.nomeComercial === value.nomeComercial}
          getOptionLabel={option => option.nomeComercial}
          options={options}
          loading={loading}
          renderOption={(props, option) => (
            <Box
              value={option}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              component="li"
              {...props}
              key={Math.random()}
              onClick={() => handleSelecionaItem(option)}
            >
              <img src={'https://via.placeholder.com/50X50'} />
              <p>{option.nomeComercial}</p>
              <p>{option.medida}m</p>
              <p>&euro; {option.precoVenda}</p>
              <p>{true === true ? <Inbox fontSize={'large'} /> : <DateRange fontSize={'large'} />}</p>
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Digite o nome do produto"
              value={nome}
              onChange={t => {
                setNome(t.target.value)
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
        <Box className={classes.itemsBox}>
          {item?.length !== 0 ? (
            <Paper variant="outlined" elevation={0} className={classes.paperProdutosArea}>
              <Box className={classes.topItemsArea}>
                <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700} color={'#1b0047'}>
                  DETALHES
                </Typography>
              </Box>
              <Box className={classes.imagesItemArea}>
                <Typography>imagens</Typography>
              </Box>
              <Box className={classes.itemsArea}>
                <Box>
                  <Typography variant="body2" gutterBottom fontWeight={700} color={'#1b0047'} className={classes.marginTopFive}>
                    {item['nomeComercial']}
                  </Typography>
                  <FormControl size="small">
                    <Select onChange={handleSelectMedida} value={novaMedida}>
                      {medidas?.map((medida, index) => {
                        return (
                          <MenuItem key={index} value={medida} selected={medida === item['medida'] ? true : false}>
                            {medida}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <Typography variant="caption" display="block" gutterBottom color={'#1b0047'} className={classes.marginTopFive}>
                    Em estoque na loja: x
                  </Typography>
                </Box>
                <Box>
                  <Box className={classes.displayRow}>
                    <Typography variant="body2" gutterBottom>
                      De:&nbsp;
                    </Typography>
                    <Typography variant="body2" gutterBottom fontWeight={700} color={'#1b0047'} className={classes.marginTopFive}>
                      &euro; {valorFinal}
                    </Typography>
                  </Box>
                  <Box className={classes.displayRow}>
                    <Typography variant="body2" gutterBottom>
                      Por:&nbsp;
                    </Typography>
                    <Typography variant="body2" gutterBottom fontWeight={700} color={'#1b0047'} className={classes.marginTopFive}>
                      &euro; {valorFinal}
                    </Typography>
                  </Box>
                  <Box className={classes.displayRow}>
                    <Typography variant="body2" gutterBottom>
                      ou em:&nbsp;
                    </Typography>
                    <FormControl size="small">
                      <Select onChange={handleSelectParcelas} value={String(parcelas)} className={classes.selectForm}>
                        {Array.from({ length: 10 }, (valor, key) => key + 1).map((valor, index) => {
                          return (
                            <MenuItem key={index} value={valor}>
                              {valor}x
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <Typography variant="body2" gutterBottom>
                      &nbsp;de
                    </Typography>
                  </Box>
                  <Box className={classes.alignCenterBox}>
                    <Typography variant="body2" gutterBottom fontWeight={700} color={'#6c9454'} className={classes.marginTopFive}>
                      &euro; {valorParceladoItem}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.displayRow}>
                <Typography variant="body2" gutterBottom>
                  Quantidade:
                </Typography>
                <IconButton color="primary" component="span" onClick={handleRemove}>
                  <Remove />
                </IconButton>
                <Typography variant="body1" gutterBottom>
                  {quantidade}
                </Typography>
                <IconButton color="primary" component="span" onClick={handleAdicionar}>
                  <Add />
                </IconButton>
                <Button onClick={adicionarCarrinho} variant="contained" startIcon={<AddShoppingCart />} style={{ marginLeft: 15 }}>
                  Adicionar
                </Button>
              </Box>
            </Paper>
          ) : null}
        </Box>
      </Box>
      {produtos?.length > 0 ? (
        <Box>
          {erroPrecoMenor === true ? <Alert severity="error">Atenção! Valor informado menor que o valor permitido do desconto!</Alert> : null}
          <Box>
            <Paper className={classes.paperTableProdutos} variant="outlined" elevation={0}>
              <Box className={classes.titlePaperProdutosArea}>
                <LocalGroceryStore />
                <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700} className={classes.titlePaperProdutos}>
                  Produtos no Pedido
                </Typography>
              </Box>
              <TableContainer>
                <Table stickyHeader aria-label="table">
                  <TableHead>
                    <TableRow className={classes.tableTopArea}>
                      {['Produto', 'Tamanho', 'Quantidade', 'Valor Unitário', 'Entrega', 'Ações'].map((column, index) => (
                        <TableCell key={index}>
                          <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700}>
                            {column}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {produtos?.map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          <TableCell>{row.produto['nomeComercial']}</TableCell>
                          <TableCell>{row.produto['medida']}</TableCell>
                          <TableCell>
                            <Box className={classes.quantidadeTableArea}>
                              <IconButton color="primary" component="span" onClick={() => handleRemoveTabela(row.id)} size="small">
                                <Remove />
                              </IconButton>
                              <Typography variant="body1" gutterBottom>
                                {row.quantidade}
                              </Typography>
                              <IconButton color="primary" component="span" onClick={() => handleAdicionaTabela(row.id)} size="small">
                                <Add />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              id="valor"
                              value={row.produto['precoVenda']}
                              variant="outlined"
                              size="small"
                              sx={{ width: 100 }}
                              inputProps={{ inputMode: 'numeric' }}
                              onChange={t => {
                                setNewValue(t.target.value, row.id)
                              }}
                              error={erroPrecoMenor}
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl size="small">
                              <Select value={entrega[0]} style={{ width: 120 }}>
                                {entrega.map((local, index) => {
                                  return (
                                    <MenuItem key={index} value={local}>
                                      {local}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleRemoverItem(row.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          <Box className={classes.finalArea}>
            <Paper className={classes.paparFinalArea} variant="outlined" elevation={0}>
              <Box className={classes.titlePaperProdutosArea}>
                <Person />
                <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700} className={classes.titlePaperProdutos}>
                  Cliente
                </Typography>
              </Box>
            </Paper>
            <Paper className={classes.paparFinalArea} variant="outlined" elevation={0}>
              <Box className={classes.titlePaperProdutosArea}>
                <MonetizationOn />
                <Typography variant="subtitle1" gutterBottom component="div" fontWeight={700} className={classes.titlePaperProdutos}>
                  Total Parcial do Pedido
                </Typography>
              </Box>
              <Box className={classes.totalArea}>
                <Box className={classes.totalContent}>
                  <Typography variant="h4" gutterBottom component="div" fontWeight={700} color={'#6c9454'} className={classes.marginTopFive}>
                    &euro; {parcelasTotal}
                  </Typography>
                  <FormControl size="small">
                    <Select onChange={handleParcelasTotal} value={qtdeParcelasTotal} style={{ width: 75 }}>
                      {Array.from({ length: 10 }, (valor, key) => key + 1).map((valor, index) => {
                        return (
                          <MenuItem key={index} value={valor}>
                            {valor}x
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.totalContent}>
                  <Typography variant="h4" gutterBottom component="div" fontWeight={700} color={'#6c9454'} className={classes.marginTopFive}>
                    &euro; {total}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Total
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box className={classes.buttonFotterArea}>
            <Button onClick={letForPayment} className={classes.buttonFotter} variant="contained" endIcon={<ArrowForward />} size="large">
              Ir Para o Pagamento
            </Button>
          </Box>
        </Box>
      ) : null}
    </PageContainer>
  )
}
