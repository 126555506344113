import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import Api from '~/services/Api'

export const PageHome: React.FC = () => {
  const { logout } = useAuth0()
  const handleClick = async () => {
    await Api.Api.get('/')
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <button onClick={handleClick}>TESTE</button>
      <br />
      <button onClick={() => logout()}>LOGOUT</button>
    </div>
  )
}
