import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { tostifyErros } from '~/helpers/toastfy'
import { useIsMounted } from '~/hooks/useIsMounted'

import { getUser, storeUser } from '~/services/Api/users'
import { IUsuarioDto } from '~/services/Api/users/user.dto'

export type FormDataType = Partial<IUsuarioDto>

type Props = {
  initialData?: FormDataType
}

export function useUserFormData(props: Props = {}) {
  const { initialData = {} } = props
  const isMounted = useIsMounted()
  const [formData, setFormData] = useState<FormDataType>(initialData)
  const [loading, setLoading] = useState(false)

  const request = useCallback(
    async (id: number) => {
      setLoading(true)
      const response = await getUser(id)
      if (isMounted.current) {
        setLoading(false)
        if (response.success) {
          setFormData({ ...response.data })
        }
      }
    },
    [isMounted]
  )

  const submitData = useCallback(
    async (data?: FormDataType) => {
      const response = await storeUser({ ...formData, ...data })
      if (isMounted.current) {
        if (response.success) {
          setFormData({ id: response.userId })
          toast.success('Usuário registrado com sucesso!')
        } else {
          tostifyErros(response.message || 'Houve um erro')
        }
        return !!response.success
      }
      return null
    },
    [isMounted, formData, setFormData]
  )

  return { formData, loading, request, setFormData, submitData }
}
