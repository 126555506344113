import Api from '~/services/Api'
import type { IResponseUserPermissoes } from '~/services/Api/users/user.dto'

import type { IResponsePermissions, IUserPermissaoDto } from './permission.dto'

export async function getPermissions(): Promise<IResponsePermissions> {
  const response = await Api.getDefault(`/usuarios/permissoes`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

export async function getUserPermissions(userId: number): Promise<IResponseUserPermissoes> {
  const response = await Api.getDefault(`/usuarios/${userId}/permissoes`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

export async function deletePermission(userId: number, roleId: number): Promise<IResponseUserPermissoes> {
  const response = await Api.deleteDefault(`/usuarios/${userId}/permissoes/${roleId}`)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}

export async function createPermission(userId: number, data: IUserPermissaoDto): Promise<IResponseUserPermissoes> {
  const response = await Api.postDefault(`/usuarios/${userId}/permissoes`, data)
  return {
    success: !!response?.success,
    data: response?.data,
    message: response?.error?.message
  }
}
