import React, { memo } from 'react'

import styled from 'styled-components'

import { IShopStock } from '~/services/Api/shops/stock'

import type { ICustomCellProps } from '~/components/CustomTable'

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  flex-direction: column;
  justify-content: center;
  border: 0;
  span {
    font-size: 12px;
  }
`

type Props = ICustomCellProps<IShopStock> & {}

export const Cell: React.FC<Props> = ({ record }) => {
  const { tissue, measure } = record

  const rendeSecondary = () => {
    let text = ''
    if (measure) text += `(${measure.name})`
    if (tissue) text += ` ${tissue.name}`
    return text.trim()
  }
  return (
    <Content>
      {record?.displayName || record?.name}
      {tissue || measure ? (
        <>
          <br />
          <span>{rendeSecondary()}</span>
        </>
      ) : null}
    </Content>
  )
}

export const ProductCell = memo(Cell) as typeof Cell
