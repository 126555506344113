import React from 'react'

import { PageTitle } from '~/components/PageTitle'
import { PageContainer } from '~/components/styled'

export const PagePurchases: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle spotlight="Pedidos" title="cadastrados" />
    </PageContainer>
  )
}
