import styled from 'styled-components'

export const MainLayoutContent = styled.main<{ leftMargin?: number; topMargin?: number }>`
  flex: 1;
  border: 0;
  margin: 0;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing.l}px;
  padding-left: ${({ leftMargin = 0, theme }) => leftMargin + theme.spacing.l}px;
  margin-top: ${({ topMargin = 0 }) => topMargin}px;

  transition: all ease-in-out 0.2s;
  @media (max-width: 600px) {
    margin-left: 0;
    padding-left: ${({ theme }) => theme.spacing.l}px;
  }
`
