import type { IFornecedorDto } from '~/services/Api/suppliers/supplier.dto'

import type { IColumnTable } from '~/components/CustomTable'

import { ActionsCellMemo } from './ActionsCell'
import { ContactCellMemo } from './ContactCell'

export const columns: IColumnTable<IFornecedorDto>[] = [
  { name: 'nome', label: 'Nome' },
  { name: 'email', label: 'Contacto', Cell: ContactCellMemo },
  { label: '', Cell: ActionsCellMemo, width: 110 }
]
