import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import { Delete } from '@mui/icons-material'
import { Button, Divider, Grid, Stack, List, Chip } from '@mui/material'
import { v4 as uuid } from 'uuid'

import { tostifyErros } from '~/helpers/toastfy'
import { useIsMounted } from '~/hooks/useIsMounted'
import { useUserFormData } from '~/hooks/userHooks'

import { deletePermission, getUserPermissions, createPermission } from '~/services/Api/users/permissions'

import { AddRole, PermissionType } from '~/components/_features/users/FormUserRoles/AddRole'

import { ItemPermissionContainer, ItemShopName, ItemChips } from './styles'

type ItemRole = {
  shopName: string
  roles: { id?: number; role: string; deleted?: boolean }[]
}
type Props = {
  id?: number
  onCancel?: () => void
  onSuccess?: () => void
}

export const FormUserRoles: React.FC<Props> = ({ onCancel, onSuccess, id = 0 }) => {
  const isMounted = useIsMounted()
  const [roleList, setRoleList] = useState<PermissionType[]>([])
  const { formData } = useUserFormData()

  const updatePermission = useCallback((id: string | number, data: Partial<PermissionType>) => {
    setRoleList(old => old.map(f => (f.id === id ? { ...f, ...data } : f)))
  }, [])

  const handleDelete = async (roleId: string | number) => {
    const removeItem = () => {
      if (isMounted.current) setRoleList(old => old.filter(f => f.id !== roleId))
    }

    if (typeof roleId === 'string') removeItem()
    if (typeof roleId === 'number') {
      updatePermission(roleId, { deleted: true })
      const deleted = await deletePermission(id, roleId)
      if (deleted?.success) {
        removeItem()
      }
    }
  }

  const sendPermission = useCallback(
    async ({ role, shopId, ...data }: PermissionType) => {
      const response = await createPermission(id, { userId: id, role, shopId })
      if (response?.success) {
        updatePermission(data.id, { id: response?.permissionId })
      } else {
        tostifyErros(response.message || 'Houve um erro')
        updatePermission(data.id, { deleted: true })
      }
    },
    [id, updatePermission]
  )

  const handleAdd = useCallback(
    (data: PermissionType) => {
      if (data?.role && data?.shopId) {
        const found = roleList.find(r => r.role === data.role && r.shopId === data.shopId)
        if (!found) {
          const uid = uuid()
          setRoleList(old => [...old, { id: uid, userId: id, shopId: data.shopId, role: data.role, shop: { ...data.shop } }])
          sendPermission(data)
        }
      }
    },
    [roleList, id, sendPermission]
  )

  const fetchData = useCallback(async () => {
    if (id) {
      const response = await getUserPermissions(id)
      if (isMounted.current) {
        if (response?.success) {
          setRoleList(response.data)
        }
      }
    }
  }, [id, isMounted])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const permissions: ItemRole[] = useMemo(() => {
    return roleList.reduce((acc, item) => {
      const found = acc.find(f => f.shopName === item.shop.name)
      if (found) found.roles.push({ id: item?.id, role: item.role, deleted: !!item?.deleted })
      else acc.push({ shopName: item.shop?.name, roles: [{ id: item?.id, role: item.role }] })
      return acc
    }, [] as ItemRole[])
  }, [roleList])

  const renderRoles = ({ shopName, roles }: ItemRole) => {
    return (
      <>
        {roles.map(role => {
          return (
            <Chip key={`${shopName}-${role.role}`} label={role.role} size="small" deleteIcon={<Delete />} onDelete={() => handleDelete(role.id)} />
          )
        })}
      </>
    )
  }

  const hasButtons = !!(onCancel || onSuccess)

  return (
    <Fragment key={`form-user-permissions-${formData?.id}`}>
      <AddRole onAdd={handleAdd} />
      <br />
      {permissions.length ? (
        <List dense>
          {permissions.map(({ shopName, roles }) => {
            return (
              <Fragment key={shopName}>
                <ItemPermissionContainer>
                  <ItemShopName>{shopName || '--'}</ItemShopName>
                  <ItemChips>{renderRoles({ shopName, roles })}</ItemChips>
                </ItemPermissionContainer>
                <Divider />
              </Fragment>
            )
          })}
        </List>
      ) : (
        <>
          <p>Usuário não posui permissões</p>
        </>
      )}

      {hasButtons && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider />
            <br />
            <Stack spacing={2} direction="row">
              {onCancel ? (
                <Button variant="outlined" onClick={onCancel}>
                  Cancelar
                </Button>
              ) : null}
              {onSuccess ? (
                <Button
                  variant="contained"
                  //onClick={handleSubmit}
                  // disabled={!!loading}
                >
                  Salvar
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}
